import React from "react"
import { withRouter } from "react-router-dom"
import Button from "@meridian/components/button"
import PropTypes from "prop-types"


/*
Wrapper for Merdian button component to not refresh page for links to the same app.
It will just push the url on the history and react router will navigate to the link.
*/
export function LinkButton(props) {
  const { history } = props
  const onClick = event => {
    history.push(props.href)
  }

  return <Button {...props} onClick={onClick} />
}

LinkButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string
}

export default withRouter(LinkButton)