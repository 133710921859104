import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentText from './DocumentText';
import DropdownMetadataFields from './DropdownMetadataFields';
import { highlightAnnotationHandler, updateAnnotationClick } from './HighlightAnnotation';
import * as actionTypes from '../../store/actions/actions';
import Box from '@meridian/components/box';
import { IMPORTED_ANNOTATIONS_NOT_EDITABLE_WARNING_MESSAGE, UNSAVED_DOCUMENT_ANNOTATIONS } from '../../constants/Strings';
import { Prompt } from 'react-router-dom';
import { getProjectState, PROJECT_STATES_ORDER } from '../../constants/Project';
import { DATA_SOURCE } from '../../constants/DataSource';
import { isLabelTrainable } from '../../utils/LabelsFilter'

const ANNOTATION_DELIMITER = '_';
const LABEL_EMPTY = 'LableNotSelected';
const DOCUMENT_BOX_DIV_CLASS_NAME = 'documentBox';

class Document extends Component {
  state = {
    metadataTag: '',
    selectedTextId: '',
    text: '',
  };

  setSelectedTextHandler = (selectedTextId, text) => {
    this.setState({ selectedTextId: selectedTextId, text: text });
  };

  checkForUnsavedAnnotations = () => {
    // Todo: Enable prompt for verification phase.
    if (getProjectState(this.props.projectState) >= PROJECT_STATES_ORDER.TRAINING) {
      return true;
    }
    for (const annotation of this.props.annotations) {
      if (!annotation.isImported && annotation.annotationAction) {
        return UNSAVED_DOCUMENT_ANNOTATIONS;
      }
    }
    return true;
  };

  resetSelectedTextHandler = () => {
    if (this.state.selectedTextId.trim() !== '' || this.state.text.trim() !== '') {
      this.setSelectedTextHandler('', '');
    }
  };

  attachOnMouseLeaveToDocumentBox = () => {
    const documentBoxElements = document.getElementsByClassName(DOCUMENT_BOX_DIV_CLASS_NAME);
    const resetSelectedTextHandler = this.resetSelectedTextHandler;
    if (documentBoxElements && documentBoxElements.length === 1) {
      documentBoxElements[0].addEventListener('mouseleave', function(e) {
        resetSelectedTextHandler();
      });
    } else {
      console.error('Expected exactly one element for documentBox Class');
    }
  };
  componentDidUpdate = (prevProps) => {
    /*
     * Whenever documentId changes, remove all highlighted annotations so that it can render itself
     * and then highlights render when the this.props.annotation changes
     */
    if (prevProps.documentId !== this.props.documentId) {
      this.removeAllHighlights(prevProps.annotations);
    }
    /*
     * Renders the highlights when annotation changes
     */
    if (prevProps.annotations !== this.props.annotations) {
      this.removeAllHighlights(prevProps.annotations);
      this.highlightingExistingAnnotationsHandler();
    }
    this.attachOnMouseLeaveToDocumentBox();
  };

  componentWillUnmount = () => {
    this.props.clearDocumentData();
  };

  getSelectionTextHandler = (selText) => {
    if (selText === null) {
      this.setState({ selectedTextId: '', text: '' });
      return;
    }
    this.setState({ selectedTextId: selText.annotationId, text: selText.text });
  };

  removeAllHighlights = (annotations) => {
    // TODO: see if we can remove this and do it in more react way
    annotations.forEach((annotation) => {
      const id = annotation.start + '_' + annotation.end;
      this.removeHighlightAnnotationHandler(id);
    });
  };

  removeHighlightAnnotationHandler = (id) => {
    let el = document.getElementById(id);
    while (el !== null) {
      const newNode = document.createDocumentFragment();
      while (el.firstChild) {
        const child = el.removeChild(el.firstChild);
        newNode.appendChild(child);
      }
      el.parentNode.replaceChild(newNode, el);
      el = document.getElementById(id);
    }
  };

  getDropdownValueHandler = (event, selectedTextId) => {
    const metadataExtracted = Object.create(Object.prototype);
    const splitSelectedTextId = this.state.selectedTextId.split('_');
    if (event === null) {
      this.props.onDeleteMetadataExtracted(this.state.selectedTextId);
      const id = splitSelectedTextId[1] + '_' + splitSelectedTextId[2];
      this.removeHighlightAnnotationHandler(id);
      this.setState({ selectedTextId: '', text: '' });
      return;
    }
    this.setState({ metadataTag: event.value });
    const labelSelected = this.props.labels.filter((label) => event.value === label.labelId)[0];
    metadataExtracted['tag'] = labelSelected.name;
    metadataExtracted['start'] = splitSelectedTextId[1];
    metadataExtracted['end'] = splitSelectedTextId[2];
    metadataExtracted['value'] = this.state.text;
    metadataExtracted['labelId'] = labelSelected.labelId;
    metadataExtracted['projectId'] = this.props.projectId;
    metadataExtracted['documentId'] = this.props.documentId;
    metadataExtracted['source'] = DATA_SOURCE.USER;
    metadataExtracted['annotationId'] = labelSelected.labelId + ANNOTATION_DELIMITER + metadataExtracted['start'] + ANNOTATION_DELIMITER + metadataExtracted['end'];
    if (splitSelectedTextId[0] !== LABEL_EMPTY) {
      metadataExtracted['oldId'] = this.state.selectedTextId;
    }

    // this.setState({ selectedTextId: metadataExtracted['annotationId'], text: metadataExtracted['value'] });
    const selectedTextInfo = { annotationId: metadataExtracted['annotationId'], text: metadataExtracted['value'] };
    updateAnnotationClick(metadataExtracted['start'] + '_' + metadataExtracted['end'], this.getSelectionTextHandler, selectedTextInfo);
    this.props.onAddToMetdataExtracted(metadataExtracted);
  };

  renderDropdownMetadata = () => {
    let isMetadataEditable = true;
    let currentTag;

    for (const annotationsIndex in this.props.annotations) {
      if (Object.prototype.hasOwnProperty.call(this.props.annotations, annotationsIndex) && this.props.annotations[annotationsIndex].annotationId === this.state.selectedTextId) {
        currentTag = this.props.annotations[annotationsIndex].labelId;
        isMetadataEditable = isLabelTrainable(this.props.annotations[annotationsIndex]);
        break;
      }
    }
    let dropdownMetadata;
    if (
      this.state.selectedTextId.split(ANNOTATION_DELIMITER)[0] !== undefined &&
      this.state.selectedTextId.split(ANNOTATION_DELIMITER)[0].trim() !== '' &&
      this.state.text.trim() !== ''
    ) {
      if (isMetadataEditable) {
        dropdownMetadata = (
          <DropdownMetadataFields
            selectedTextId={this.state.selectedTextId}
            text={this.state.text}
            getSelectionTextHandler={this.getSelectionTextHandler}
            currentTag={currentTag}
            isMetadataEditable={isMetadataEditable}
            selectedTag={this.getDropdownValueHandler}
            isDisabled={getProjectState(this.props.projectState) === PROJECT_STATES_ORDER.LAUNCHED ? true : false}
          />
        );
      } else {
        this.props.showNotification(IMPORTED_ANNOTATIONS_NOT_EDITABLE_WARNING_MESSAGE, 'warning');
      }
    }
    return dropdownMetadata;
  };

  highlightingExistingAnnotationsHandler = () => {
    const el = document.getElementById('documentContent');
    if (el) {
      this.props.annotations.forEach((annotation) => {
        highlightAnnotationHandler(annotation, this.getSelectionTextHandler);
        this.props.markAnnotationAsHighlighted(annotation);
      });
    }
  };

  render() {
    this.highlightingExistingAnnotationsHandler();
    const dropdownMetadata = this.renderDropdownMetadata();
    return (
      <Box type="outline" className={DOCUMENT_BOX_DIV_CLASS_NAME} spacingInset="medium">
        <Prompt message={this.checkForUnsavedAnnotations()} />
        <DocumentText
          getSelectionTextHandler={this.getSelectionTextHandler}
          projectId={this.props.match.params.projectId}
          documentId={decodeURIComponent(this.props.match.params.documentId)}
        />
        {dropdownMetadata}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectName: state.project.projectName,
    projectId: state.project.projectId,
    projectState: state.project.state,
    annotations: state.currDocument.displayedMetadata,
    documentState: state.currDocument.documentState,
    annotationSource: state.currDocument.dataSource,
    labels: state.project.projectLabels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    markAnnotationAsHighlighted: (annotation) => dispatch({ type: actionTypes.MARK_ANNOTATION_AS_HIGHLIGHTED, annotation: annotation }),
    onAddToMetdataExtracted: (metadataExtracted) => dispatch({ type: actionTypes.ADD_TO_METDATA_EXTRACTED, metadataExtracted: metadataExtracted }),
    onDeleteMetadataExtracted: (annotationId) => dispatch({ type: actionTypes.DELETE_METDATA_EXTRACTED, annotationId: annotationId }),
    clearDocumentData: () => dispatch({ type: actionTypes.CLEAR_DOCUMENT_DATA }),
    showNotification: (message, messageType) => dispatch({ type: actionTypes.SHOW_NOTIFICATION, message: message, messageType: messageType }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
