import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../utils/LinkButton';
import Table, { TableRow, TableCell } from '@meridian/components/table';
import Link from '@meridian/components/link';
import '../../assets/css/Home.css';
import ExportProjectMetadata from './ExportProjectMetadata';
import { getProjectsForUser } from '../../requests/ProjectRequests';
import { MetricPageLatencyTimer } from '../../utils/MetricPageLatencyTimer';
import { CLIENT_METRICS_METHOD_NAMES } from '../../utils/Constants';
import * as actionTypes from '../../store/actions/actions';
import { PHONE_TOOL_LINK } from '../../constants/Strings';

const DATALENS_BROADCAST_VIDEO_LINK = 'https://broadcast.amazon.com/embed/210751';
const getDataLensIntroVideoIFrame = () => {
  return { __html: '<iframe sandbox src="' + DATALENS_BROADCAST_VIDEO_LINK + '" width="682" height="384" allowfullscreen></iframe>' };
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.metricPageLatencyTimer = new MetricPageLatencyTimer();
    this.state = {
      projectList: undefined,
    };

    if (this.props.user.username !== '') {
      getProjectsForUser(this.setProjectsListHandler, this.props.user.username);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.username !== this.props.user.username) {
      getProjectsForUser(this.setProjectsListHandler, this.props.user.username);
    }
    if (this.props.dataLoadedForGetProjectsForUser) {
      this.metricPageLatencyTimer.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.HOMEPAGE);
    }
  }

  setProjectsListHandler = (projects) => {
    const projectsMapped = projects.map((project) => {
      return { name: project.Name, projectId: project.ProjectId, description: project.Description, owner: project.Owner, state: project.State };
    });

    this.setState({ projectList: projectsMapped });
    this.props.setDataLoadedForGetProjectsForUser(true);
  };

  getProjectHandler = (project) => {
    this.setState({ showComponent: true, projectName: project.name });
    this.props.history.push('verify/' + project.projectId);
  };

  getViewForNoProjects = () => {
    return (
      <div>
        <h2 id="welcome" align="center">
          Welcome to DataLens
        </h2>
        <div dangerouslySetInnerHTML={getDataLensIntroVideoIFrame()} />
      </div>
    );
  };

  render() {
    if (this.state.projectList === undefined) {
      return '';
    }
    const listOfProjects = this.state.projectList
      .sort((x, y) => x.name.localeCompare(y.name))
      .map((project) => (
        <TableRow onClick={() => this.getProjectHandler(project)} highlightOnHover={true} key={project.projectId}>
          <TableCell>{project.name}</TableCell>
          <TableCell>{project.description}</TableCell>
          <TableCell>
            <Link href={PHONE_TOOL_LINK + project.owner} target="_blank" ref="noopener noreferrer">
              {project.owner}
            </Link>
          </TableCell>
          <TableCell>{project.state}</TableCell>
          <TableCell>
            <ExportProjectMetadata projName={project.projectId} projState={project.state} />
          </TableCell>
        </TableRow>
      ));

    return (
      <div className="home" align="center">
        <div id="create_project">
          <Button type="tertiary" disabled={false} href="/create">
            + Create new project
          </Button>
        </div>

        {listOfProjects.length !== 0 ? (
          <div>
            <h2 id="welcome" align="center">
              Pick a project
            </h2>

            <div id="home_content">
              <Table spacing="medium" headerRows={1} showDividers={true} onSort={() => {}}>
                <TableRow key="projectsTableHeader">
                  <TableCell>Project Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Export Data</TableCell>
                </TableRow>
                {listOfProjects}
              </Table>
            </div>
          </div>
        ) : (
          this.getViewForNoProjects()
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    dataLoadedForGetProjectsForUser: state.apiDataLoaded.dataLoadedForGetProjectsForUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDataLoadedForGetProjectsForUser: (status) => dispatch({ type: actionTypes.SET_DATA_LOADED_FOR_GET_PROJECTS_FOR_USER, status: status }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
