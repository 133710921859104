import { MetricsPublisher } from './ClientMetrics';

export class MetricPageLatencyTimer {

    constructor() {
        this._hasPublished = false;
        this._startTime = this.getCurrentTime();
        this.METRIC_PUBLISHER_TIMEOUT = 3000;
    }

    publishPageLatency = (methodName) => {
        if (!this._hasPublished) {
            // Flip the flag designating if we have published so that we don't publish more than once
            this._hasPublished = true;

            // Calculate page latency since this was created
            const pageLatency = this.getTimeSinceStart();

            // Publish the metric
            setTimeout(() => {
                MetricsPublisher.publishMetricTimer({
                    methodName: methodName,
                    metricValue: pageLatency,
                    metricName: "PageLatency",
                })
            }, this.METRIC_PUBLISHER_TIMEOUT);
        }
    }

    getTimeSinceStart = () => {
        return this.getCurrentTime() - this._startTime;
    }

    getCurrentTime = () => {
        return performance.now();
    }
}
