import * as actionTypes from '../actions/actions'

const initialState = {
    given_name: '',
    username: '',
    user_type: '',
    accessToken: '',
    tokenExpiry: ''
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_USER:
            return {
                ...state,
                ...action.user
                }
        case actionTypes.UPDATE_USER_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken,
                tokenExpiry: action.tokenExpiry
            }
        default:
            return state;
    }
};

export default user;