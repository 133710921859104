import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table, { TableRow, TableCell } from "@meridian/components/table"
import Text from "@meridian/components/text"
import Alert from "@meridian/components/alert"
import UserModal from '../../Modal/UserModal'
import { startTraining } from '../../../requests/ProjectRequests'
import { INSUFFICIENT_SAMPLES_WARNING_TITLE, INSUFFICIENT_SAMPLES_TEXT, INSUFFICIENT_SAMPLES_WARNING_MESSAGE } from '../../../constants/Strings'
import {getUserCreatedLabels} from '../../../utils/LabelsFilter'

class ProjectAnnotationModal extends Component {
    
    startProjectTraining = () => {
      this.props.startTraining(this.props.projectId)
    }

    clickedNo = () => {
        this.props.setTrainingButtonClickedHandler(false)
        this.props.changeModalState(false)
    }

    getListofLabels = () => {
        let userCreatedLabels = getUserCreatedLabels(this.props.labels)
        const listOfLabels = userCreatedLabels
            .map((label) => (
                <TableRow key={label.name}>
                    <TableCell>
                        {label.name}
                    </TableCell>
                    <TableCell>
                        {label.samples}
                    </TableCell>
                    <TableCell>
                        {label.recommended}
                    </TableCell>
                </TableRow>));

        return listOfLabels
    }

    getModalMessage = (id) => {
      const modalMessage =
            <div id = { id }>
              <Text>
                { INSUFFICIENT_SAMPLES_TEXT }
              </Text>
              <Table
                spacing="medium"
                headerRows={1}
                showDividers={true}
                onSort={() => {}}
              >
                <TableRow>
                  <TableCell>
                      Label Name
                  </TableCell>
                  <TableCell>
                      Number of samples
                  </TableCell>
                  <TableCell>
                      Recommended number of samples
                  </TableCell>
                </TableRow>
                { this.getListofLabels() }
              </Table>
              <Alert>
                { INSUFFICIENT_SAMPLES_WARNING_MESSAGE }
              </Alert>
            </div>
      
      return modalMessage
    }

    render() {
      const title = INSUFFICIENT_SAMPLES_WARNING_TITLE
      const divId = "insufficient_samples_warning_modal"
      
      return ( 
        <UserModal
          divId = { divId }
          title = { title }
          modalOpen = { this.props.modalOpen }
          modalMessage = { this.getModalMessage(title) }
          yesFunctionHandler = { this.startProjectTraining }
          noFunctionHandler = { this.clickedNo }
        />
      )
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.projectId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startTraining: (projectId) => dispatch(startTraining(projectId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectAnnotationModal);