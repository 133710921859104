import React, { Component } from 'react';
import Box from '@meridian/components/box';
import { AnnotationMetadata } from './utils/AnnotationMetadata'
import { AnnotationRelationshipActionTag } from './utils/AnnotationRelationshipActionTag'
import {LabelStatus} from "../../requests/ProjectRequests";
import Tag from "@meridian/components/tag";
import {groupBy} from "../../utils/ArrayUtils";

const STARDARDIZATION_UNITS_PER_TEXT_TYPE = {
  'Date': 'mm/dd/yyyy'
}

export default class MetadataListView extends Component {

  getAnnotationMetadata = (annotation) => {
    return <AnnotationMetadata annotation={annotation} />
  }

  getAnnotationRelationshipActionTag = (annotation) => {
    return <AnnotationRelationshipActionTag relationshipTermAction={annotation.relationshipTermAction} />
  }

  getLabelStandardFormat = (label) => {
    if (!label.textType || !(label.textType in STARDARDIZATION_UNITS_PER_TEXT_TYPE)) {
      return null
    }

    return "(" + STARDARDIZATION_UNITS_PER_TEXT_TYPE[label.textType] + ")"
  }

  renderMetadataItem = (orderedMetadata, key) => {
    return (
      <Box spacingInset="none small none small">
        <h4>{key} {this.getLabelStandardFormat(orderedMetadata[key][0])}</h4>
        <ul>
          {orderedMetadata[key].map((annotation) => (
            <li
              key={annotation.id}
              onClick={() => {
                document.getElementById(annotation.id).scrollIntoView();
              }}
            >
              {annotation.text}
              &nbsp;
              {this.getAnnotationMetadata(annotation)}
              &nbsp;
              {this.getAnnotationRelationshipActionTag(annotation)}
            </li>
          ))}
        </ul>
      </Box>
    );
  }

  renderLabelWithNoMetadata = (label) => {
    switch (label.status) {
      case LabelStatus.CREATION_IN_PROGRESS:
        return (
            <Box spacingInset="none small small small">
              <h4>{label.name} <Tag type="warning">Importing</Tag></h4>
            </Box>
        );
      case LabelStatus.AVAILABLE:
        return (
            <Box spacingInset="none small small small">
              <h4>{label.name} <Tag type="error">Not Found</Tag></h4>
            </Box>
        );
      default:
        return null;
    }
  }

  renderLabelsWithNoMetadata = (labels, metadata) => {
    if (!labels) {
      return null;
    }
    const metadataByLabelId = groupBy(Object.values(metadata).flat(), 'labelId')
    const labelsWithNoMetadata = labels.filter((label) => !metadataByLabelId[label.labelId])
    let labelsUIList = [];
    labelsWithNoMetadata.forEach(label => {
      labelsUIList.push(this.renderLabelWithNoMetadata(label));
    })
    return labelsUIList;
  }

  render() {
    const metadata = this.props.metadata;
    const orderedMetadata = {};
    Object.keys(metadata)
      .sort()
      .forEach(function(key) {
        orderedMetadata[key] = metadata[key];
      });

    const metadataExtracted = [];
    for (const key in orderedMetadata) {
      metadataExtracted.push(this.renderMetadataItem(orderedMetadata, key));
    }

    return (
      <Box className="MetaContentBox" type="fill" maxHeight="100%">
        {metadataExtracted}
        {this.renderLabelsWithNoMetadata(this.props.labels, this.props.metadata)}
      </Box>
    );
  }
}
