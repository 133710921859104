import {
    HOSTNAME,
    FEDERATE_URL_LOCAL, FEDERATE_URL_BETA, FEDERATE_URL_GAMMA, FEDERATE_URL_PROD,
    API_URL_LOCAL, API_URL_BETA, API_URL_GAMMA, API_URL_PROD,
    API_KEY_LOCAL, API_KEY_BETA, API_KEY_GAMMA, API_KEY_PROD,
    IAM_ROLE_ARN_LOCAL, IAM_ROLE_ARN_BETA, IAM_ROLE_ARN_GAMMA, IAM_ROLE_ARN_PROD,
    IDENTITY_POOL_ID_LOCAL, IDENTITY_POOL_ID_BETA, IDENTITY_POOL_ID_GAMMA, IDENTITY_POOL_ID_PROD,
    USER_POOL_ID_LOCAL, USER_POOL_ID_BETA, USER_POOL_ID_GAMMA, USER_POOL_ID_PROD,
    COGNITO_CLIENT_ID_LOCAL, COGNITO_CLIENT_ID_BETA, COGNITO_CLIENT_ID_GAMMA, COGNITO_CLIENT_ID_PROD,
    APP_WEB_DOMAIN_LOCAL, APP_WEB_DOMAIN_BETA, APP_WEB_DOMAIN_GAMMA, APP_WEB_DOMAIN_PROD,
    PINPOINT_APPLICATION_ID_LOCAL, PINPOINT_APPLICATION_ID_BETA, PINPOINT_APPLICATION_ID_GAMMA, PINPOINT_APPLICATION_ID_PROD
} from '../constants/StageConstants'


export const Stage =  {
    LOCAL: "LOCAL",
    BETA: "BETA",
    GAMMA: "GAMMA",
    PROD: "PROD"
}

export const getStage = () => {
    switch(window.location.hostname) {
        default:
            return Stage.LOCAL
        case HOSTNAME.BETA:
            return Stage.BETA
        case HOSTNAME.GAMMA:
            return Stage.GAMMA
        case HOSTNAME.PROD:
            return Stage.PROD
    }
}

export const getAPIURL = () => {
    switch (getStage()) {
        case Stage.LOCAL:
                return API_URL_LOCAL
        case Stage.BETA:
                return API_URL_BETA
        case Stage.GAMMA:
                return API_URL_GAMMA
        case Stage.PROD:
                return API_URL_PROD
    }
}

export const getAPIKey = () => {
    switch (getStage()) {
        case Stage.LOCAL:
                return API_KEY_LOCAL
        case Stage.BETA:
                return API_KEY_BETA
        case Stage.GAMMA:
                return API_KEY_GAMMA
        case Stage.PROD:
                return API_KEY_PROD
    }
}

export const getIamRoleArn = () => {
    switch(getStage()) {
        case Stage.LOCAL:
            return IAM_ROLE_ARN_LOCAL
        case Stage.BETA:
            return IAM_ROLE_ARN_BETA
        case Stage.GAMMA:
            return IAM_ROLE_ARN_GAMMA
        case Stage.PROD:
            return IAM_ROLE_ARN_PROD
    }
}

export const getIdentityPoolId = () => {
    switch(getStage()) {
        case Stage.LOCAL:
            return IDENTITY_POOL_ID_LOCAL
        case Stage.BETA:
            return IDENTITY_POOL_ID_BETA
        case Stage.GAMMA:
            return IDENTITY_POOL_ID_GAMMA
        case Stage.PROD:
            return IDENTITY_POOL_ID_PROD
    }
}

export const getUserPoolId = () => {
    switch(getStage()) {
        case Stage.LOCAL:
            return USER_POOL_ID_LOCAL
        case Stage.BETA:
            return USER_POOL_ID_BETA
        case Stage.GAMMA:
            return USER_POOL_ID_GAMMA
        case Stage.PROD:
            return USER_POOL_ID_PROD
    }
}

export const getCognitoClientId = () => {
    switch(getStage()) {
        case Stage.LOCAL:
            return COGNITO_CLIENT_ID_LOCAL
        case Stage.BETA:
            return COGNITO_CLIENT_ID_BETA
        case Stage.GAMMA:
            return COGNITO_CLIENT_ID_GAMMA
        case Stage.PROD:
            return COGNITO_CLIENT_ID_PROD
    }
}

export const getAppWebDomain = () => {
    switch(getStage()) {
        case Stage.LOCAL:
            return APP_WEB_DOMAIN_LOCAL
        case Stage.BETA:
            return APP_WEB_DOMAIN_BETA
        case Stage.GAMMA:
            return APP_WEB_DOMAIN_GAMMA
        case Stage.PROD:
            return APP_WEB_DOMAIN_PROD
    }
}

export const getPinpointApplicationId = () => {
    switch(getStage()) {
        case Stage.LOCAL:
            return PINPOINT_APPLICATION_ID_LOCAL
        case Stage.BETA:
            return PINPOINT_APPLICATION_ID_BETA
        case Stage.GAMMA:
            return PINPOINT_APPLICATION_ID_GAMMA
        case Stage.PROD:
            return PINPOINT_APPLICATION_ID_PROD
    }
}

export const getDatalensRedirectUri = () => {
    return `https://${HOSTNAME[getStage()]}/`
}
