import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/css/Metadata.css';
import SaveMetadata from '../Metadata/SaveMetadata';
import MetadataListView from './MetadataListView';
import Row from '@meridian/components/row';
import Box from '@meridian/components/box';
import Popover from '@meridian/components/popover';
import Column from '@meridian/components/column';
import Text from '@meridian/components/text';
import { getDocumentPredictions, getDocumentAnnotations } from '../../requests/AnnotationRequests';
import Loader from '@meridian/components/loader';
import Select, { SelectOption } from '@meridian/components/select';
import helpTokens from '@meridian/tokens/base/icon/help';
import Icon from '@meridian/components/icon';
import { groupBy } from '../../utils/ArrayUtils';
import Tag from '@meridian/components/tag';
import {doesLabelQualifyForAutoAnnotation, isLabelImported} from '../../utils/LabelsFilter';
import { getMetadataListForAutoAnnotatedSection, getMetadataListForModelPredictionSection, getMetadataListForUserAnnotationSection } from './utils/MetadataFilters';
import * as actionTypes from '../../store/actions/actions';
import Button from '@meridian/components/button';
import { IMPORT_PREDICTIONS_ENABLED_TOOLTIP, IMPORT_PREDICTIONS_DISABLED_TOOLTIP, NO_MODEL_PREDICTIONS_MESSAGE } from '../../constants/Strings';
import Tooltip from '@meridian/components/tooltip';
import _ from 'lodash';
import { getProjectState, PROJECT_STATES_ORDER } from '../../constants/Project';
import { DATA_SOURCE } from '../../constants/DataSource';

const SELECT_OPTION_FOR_MODEL_PREDICTIONS = 'Model Predictions';
const SELECT_OPTION_FOR_USER_ANNOTATIONS = 'User Annotations';

class Metadata extends Component {
  state = {
    isLoading: false,
    popupOpen: false,
  };
  iconRef = React.createRef();

  setIsLoading = (val) => {
    this.setState({ isLoading: val });
  };

  setPopupOpen = (val) => {
    this.setState({ popupOpen: val });
  };

  setMetadataSource = (value) => {
    this.props.refreshDataSource(value);
  };

  componentDidMount = () => {
    if (getProjectState(this.props.projectState) >= PROJECT_STATES_ORDER.VERIFICATION) {
      this.setMetadataSource(DATA_SOURCE.MODEL);
    }
    if (this.props.parsedFilePath !== '' && this.props.documentContent !== '') {
      this.fetchAnnotationsPredictionsAndUpdateState();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const hasDocumentChanged = prevProps.documentId !== this.props.documentId || prevProps.documentContent !== this.props.documentContent;
    if (hasDocumentChanged) {
      /**
       * First thing to do is clear annotations and predictions when document changes.
       * This is required to avoid showing old annotations and predictions on the new
       * document
       */
      this.props.refreshAnnotationsForDocument([], false);
      this.props.refreshPredictionsForDocument([]);
      this.setState({ predictionsImported: false });
      if (this.props.parsedFilePath !== '' && this.props.documentContent !== '') {
        this.fetchAnnotationsPredictionsAndUpdateState();
      }
    }
    if (prevProps.projectState !== this.props.projectState) {
      if (getProjectState(this.props.projectState) >= PROJECT_STATES_ORDER.VERIFICATION) {
        // Always show model predictions when document is opened.
        this.setMetadataSource(DATA_SOURCE.MODEL);
      }
    }
    if (prevProps.dataSource !== this.props.dataSource || prevProps.annotations !== this.props.annotations || prevProps.predictions !== this.props.predictions) {
      this.calculateDisplayedMetadata();
    }
  };

  fetchAnnotationsPredictionsAndUpdateState = () => {
    this.setIsLoading(true);
    Promise.all([
      this.props.getDocumentAnnotations(this.props.match.params.documentId, this.props.documentContent),
      this.props.getDocumentPredictions(this.props.match.params.projectId, this.props.parsedFilePath, this.props.documentId, this.props.documentContent),
    ]).then((response) => {
      this.calculateDisplayedMetadata();
      this.setIsLoading(false);
    });
  };

  calculateDisplayedMetadata = () => {
    const labelsMapById = Object.assign({}, ...this.props.labels.map((label) => ({ [label.labelId]: label })));
    const autoAnnotatedMetadata = getMetadataListForAutoAnnotatedSection(labelsMapById, this.props.predictions);
    const userAnnotatedMetadata = getMetadataListForUserAnnotationSection(labelsMapById, this.props.annotations);
    const predictionsAnnotatedMetadata = getMetadataListForModelPredictionSection(labelsMapById, this.props.annotations, this.props.predictions);
    let displayedMetadata = [];
    if (this.props.dataSource === DATA_SOURCE.USER) {
      displayedMetadata = autoAnnotatedMetadata.concat(userAnnotatedMetadata);
    } else {
      displayedMetadata = autoAnnotatedMetadata.concat(predictionsAnnotatedMetadata);
    }
    this.props.refreshDisplayedMetadata(displayedMetadata);
  };

  getMetadataToSave = (displayedMetadata) => {
    /**
     * Metadata which are inserted/updated and deleted will be sent for saving.
     *
     * As per the code in here (https://code.amazon.com/packages/DataLensWebApp/blobs/b8fba97959a2b61faf72a8dbcd2b121ce4299348/--/src/store/reducers/currDocumentReducer.js#L53-L58),
     * we set annotationAction=INSERT for all prediction so that it is considered during saving.
     * The displayedMetadata also contains the annotations which have been marked annotationAction=DELETE
     */
    return displayedMetadata.filter(({ annotationAction, isImported }) => annotationAction && !isImported);
  };

  onMetadataSave = () => {
    this.props.getDocumentAnnotations(this.props.match.params.documentId, this.props.documentContent).then((response) => {
      this.setMetadataSource(DATA_SOURCE.USER);
    });
  };

  convertMetadataListForDisplay = (labelsMapById, metadataList) => {
    const displayableMetadataList = metadataList.map(function(metadata) {
      return {
        id: metadata.start + '_' + metadata.end,
        labelName: labelsMapById[metadata.labelId]['name'],
        labelId: metadata.labelId,
        text: metadata.text,
        metadata: metadata.metadata,
        textType: metadata.textType,
        relationshipTermAction: metadata.relationshipTermAction
      };
    });
    return groupBy(displayableMetadataList, 'labelName');
  };

  importPredictions = () => {
    const modelPredictions = _.cloneDeep(this.props.predictions.filter((prediction) => !prediction.isImported));
    if (modelPredictions.length === 0) {
      this.props.showNotification(NO_MODEL_PREDICTIONS_MESSAGE, 'warning');
    } else {
      this.props.refreshAnnotationsForDocument(modelPredictions, false);
    }
  };

  /** ************************** Render Methods ******************************/

  getAutoAnnotatedHelpPopOver = () => {
    return (
      <Popover anchorNode={this.iconRef.current} open={this.state.popupOpen} onClose={() => this.setPopupOpen(false)} position="bottom">
        {/**
                    # TODO: Change this to use max width when avialable and move string to constansts and
                     remove the <br>.
                **/}
        <Text>
          The annotations listed in this section were made by <br />
          the pretrained labels you selected on the “Manage Project” <br />
          screen. Note that you will not be able to modify this metadata <br />
          Please see{' '}
          <a target="_blank" href="https://w.amazon.com/bin/view/Legal/LegalLearningSystems/DataLens/UserGuides/PreTrained_Models" rel="noreferrer">
            this
          </a>{' '}
          page for more information about <br />
          pretrained labels.
        </Text>
      </Popover>
    );
  };

  getAutoAnnotatedSection = (autoAnnotatedMetadata, autoAnnotatedSectionLabels, metadata) => {
    return (
        <Column maxHeight={Object.keys(metadata).length !== 0 ? "50%" : "100%"} spacing="xsmall">
          <Row alignmentHorizontal="right" maxWidth="calc(100% - 16px)" className="ImportedLabelText">
            <h4>
              {' '}
              Auto Annotated
              {this.getAutoAnnotatedHelpPopOver()}
            </h4>
            <div className="ImportLabelHelpIcon" onClick={() => this.setPopupOpen(true)} ref={this.iconRef}>
              <Icon className="MetadataPopoverHelp" tokens={helpTokens}></Icon>
            </div>
          </Row>
          <Box className="AutoAnnotatedSection">
            <MetadataListView metadata={autoAnnotatedMetadata} labels={autoAnnotatedSectionLabels}/>
          </Box>
        </Column>
    )
  }

  getMetadata = () => {
    const labelsMapById = Object.assign({}, ...this.props.labels.map((label) => ({ [label.labelId]: label })));
    const autoAnnotatedSectionLabels = this.props.labels.filter((label) => {
      return label && doesLabelQualifyForAutoAnnotation(label);
    });
    const autoAnnotatedSectionMetadata = this.props.displayedMetadata.filter((metadata) => {
      return labelsMapById[metadata.labelId] && doesLabelQualifyForAutoAnnotation(labelsMapById[metadata.labelId]);
    });
    const autoAnnotatedMetadata = this.convertMetadataListForDisplay(labelsMapById, autoAnnotatedSectionMetadata);
    const userAnnotatedOrModelPredictionSectionMetadataToDisplay = this.props.displayedMetadata.filter((metadata) => {
      return labelsMapById[metadata.labelId] && !doesLabelQualifyForAutoAnnotation(labelsMapById[metadata.labelId]) && metadata.annotationAction !== 'DELETE';
    });
    const metadata = this.convertMetadataListForDisplay(labelsMapById, userAnnotatedOrModelPredictionSectionMetadataToDisplay);

    return (
      <Column heights={['fit', 'fit', 'fill']} spacing="xsmall" height="100%">
        {autoAnnotatedSectionLabels && autoAnnotatedSectionLabels.length !== 0 ?
            this.getAutoAnnotatedSection(autoAnnotatedMetadata, autoAnnotatedSectionLabels, metadata) : null}
        {Object.keys(metadata).length !== 0 ? (
          <Row alignmentHorizontal="right" maxWidth="calc(100% - 16px)">
            <h4> {this.props.dataSource === DATA_SOURCE.USER ? 'User Annotated' : 'Model Predictions'} </h4>
          </Row>
        ) : (
          <div></div>
        )}
        {Object.keys(metadata).length !== 0 ? <MetadataListView metadata={metadata} /> : null}
      </Column>
    );
  };

  render() {
    const metadata = this.getMetadata();
    const metadataToSave = this.getMetadataToSave(this.props.displayedMetadata);
    const projectState = getProjectState(this.props.projectState);
    return (
      <Box minWidth={250} type="outline" className="metadataBox" spacingInset="small">
        <Column heights={['fit', 'fill', 'fit']} spacing="xsmall" maxHeight="100%" className="Metadata right_panel">
          <Row className="MetadataHeader">
            <h1>Metadata</h1>
            {this.props.projectState === 'COLLECTION' && this.props.successfulTrainingIterations.length !== 0 ? (
              <div className="DataSourceSelect">
                <Tooltip position="right" title={this.props.annotations.length !== 0 ? IMPORT_PREDICTIONS_DISABLED_TOOLTIP : IMPORT_PREDICTIONS_ENABLED_TOOLTIP}>
                  <div>
                    <Button size="small" type="tertiary" onClick={() => this.importPredictions()} disabled={this.props.annotations.length !== 0}>
                      Import predictions
                    </Button>
                  </div>
                </Tooltip>
              </div>
            ) : null}
            {projectState === PROJECT_STATES_ORDER.VERIFICATION ? (
              <div className="DataSourceSelect">
                <Select
                  placeholder="Data Source"
                  value={this.props.dataSource}
                  size="small"
                  onChange={(value) => {
                    this.setMetadataSource(value);
                  }}
                >
                  <SelectOption value={DATA_SOURCE.MODEL} label={SELECT_OPTION_FOR_MODEL_PREDICTIONS} />
                  <SelectOption value={DATA_SOURCE.USER} label={SELECT_OPTION_FOR_USER_ANNOTATIONS} />
                </Select>
              </div>
            ) : null}
            {projectState === PROJECT_STATES_ORDER.LAUNCHED ? (
              <div className="DataSourceSelect">
                <Tag type="success"> {SELECT_OPTION_FOR_MODEL_PREDICTIONS} </Tag>
              </div>
            ) : null}
          </Row>
          <Box className="Meta_contents" height="100%">
            {this.state.isLoading ? (
              <div className="Loader">
                <Loader type="circular" size="large" />
              </div>
            ) : (
              metadata
            )}
          </Box>
          {projectState <= PROJECT_STATES_ORDER.VERIFICATION ? (
            <Row wrap="down" alignment="bottom center" spacing="xsmall" className="OptionContainer">
              <Box>
                <SaveMetadata
                  metadataToSave={metadataToSave}
                  isDisabled={projectState === PROJECT_STATES_ORDER.TRAINING || metadataToSave.length === 0}
                  onSave={() => this.onMetadataSave()}
                />
              </Box>
            </Row>
          ) : null}
        </Column>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documentName: state.currDocument.documentName,
    parsedFilePath: state.currDocument.parsedFilePath,
    annotations: state.currDocument.annotations,
    predictions: state.currDocument.predictions,
    displayedMetadata: state.currDocument.displayedMetadata,
    dataSource: state.currDocument.dataSource,
    labels: state.project.projectLabels,
    projectState: state.project.state,
    documentId: state.currDocument.documentId,
    documentContent: state.currDocument.documentContent,
    successfulTrainingIterations: state.project.successfulTrainingIterations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshAnnotationsForDocument: (annotations, isInsertAnnotations) => dispatch(actionTypes.refreshAnnotationsForDocument(annotations, isInsertAnnotations)),
    refreshPredictionsForDocument: (predictions) => dispatch(actionTypes.refreshPredictionsForDocument(predictions)),
    refreshDisplayedMetadata: (displayedMetadata) => dispatch(actionTypes.refreshDisplayedMetadata(displayedMetadata)),
    refreshDataSource: (dataSource) => dispatch(actionTypes.refreshDataSource(dataSource)),
    getDocumentPredictions: (projectId, parsedFilePath, documentId, documentContent) => dispatch(getDocumentPredictions(projectId, parsedFilePath, documentId, documentContent)),
    getDocumentAnnotations: (documentId, documentContent) => dispatch(getDocumentAnnotations(documentId, documentContent)),
    showNotification: (message, messageType) => dispatch({ type: actionTypes.SHOW_NOTIFICATION, message: message, messageType: messageType }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Metadata);
