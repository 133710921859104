import AWS from 'aws-sdk'
import { getStage } from './EnvironmentConfig'

export const s3Config = {
    buckets: {
    'originalDocuments': `datalens-${getStage()}-document-uploads`.toLowerCase(),
    'parsedDocuments' : `datalens-${getStage()}-parsed-documents`.toLowerCase(),
    'projects' : `datalens-${getStage()}-training`.toLowerCase()
    },
    defaultBucket: 'originalDocuments'
  };

var params = {region: "us-west-2",
              params: {
                Bucket: s3Config.buckets[s3Config.defaultBucket]
                }
              }

var s3 = new AWS.S3(params)

export default function updateS3Config(bucket = s3Config.defaultBucket){
    params.params.Bucket = bucket
    s3 = new AWS.S3(params)
}

export function getS3Object(){
    return s3
}
