import React from 'react';
import '../../assets/css/CreateProject.css';
import { connect } from 'react-redux';
import Row from "@meridian/components/row"
import Box from "@meridian/components/box"
import Input from "@meridian/components/input"
import Button from "@meridian/components/button"
import Select, { SelectOption } from "@meridian/components/select"
import Heading from "@meridian/components/heading"
import Divider from "@meridian/components/divider"
import Alert from "@meridian/components/alert"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import Tooltip from "@meridian/components/tooltip"
import helpTokens from "@meridian/tokens/base/icon/help"
import Icon from "@meridian/components/icon"
import { ADD_USER_TOOLTIP } from "../../constants/Strings"
import { getProjectUsers, putProjectUser } from '../../requests/ProjectRequests';
import Column from "@meridian/components/column";

export class ProjectUsers extends React.Component{

    state = {
        projectId: this.props.projectId,
        u_name: '',
        u_type: 'owner',
        u_name_error: false
    }

    componentDidMount = () => {
        this.props.getProjectUsers(this.state.projectId);
    }

    formChange = (field, value) => {
        this.setState({[field]: value})
    }

    submitNewUser = (e) => {
        e.preventDefault()

        if (!this.state.u_name.trim()){
          this.formChange('u_name_error', true)
        }

        if (this.state.u_name.trim()){
            this.props.putProjectUser({project_id: this.state.projectId, user_name: this.state.u_name, user_type: this.state.u_type})
            this.setState({u_name: ''})
        }
    }

    render(){
        return (
            <Column
                type="outline"
                spacingInset="small"
                minWidth="40%"
              >
                <Box>
                    <Heading level={6} alignment='left' className='box-header'> Add user </Heading>
                    <form onSubmit={this.submitNewUser}>
                        <Row
                          wrap="down"
                          alignment="top left"
                          spacing='small'
                          spacingInset='small'
                          minHeight={80}
                        >
                            <Box>
                                <Tooltip position="bottom" title={ADD_USER_TOOLTIP}>
                                  <Input
                                    value={this.state.u_name}
                                    size="small"
                                    width={250}
                                    type="text"
                                    prefix="Amazon Alias"
                                    placeholder="alias"
                                    error={this.state.u_name_error}
                                    onChange={(value) => {this.formChange('u_name', value)}}
                                    onBlur={() =>
                                      this.state.u_name.trim() ? this.formChange('u_name_error', false) : this.formChange('u_name_error', true)
                                    }
                                  />
                                </Tooltip>
                                <div>
                                  {this.state.u_name_error ? (
                                    <Alert type="error" size="small">
                                      User alias is required.
                                    </Alert>
                                  ) : null}
                                </div>

                            </Box>
                            { // <Box>
                                 // <Select
                                //   value={this.state.u_type}
                                //   placeholder="Pick Option..."
                                //   prefix="Type"
                                //   width={200}
                                //   size="small"
                                //   disabled={true}
                                //   onChange={(value) => {this.formChange('u_type', value)}}
                                // >
                                //     <SelectOption value="view" label="View" />
                                //     <SelectOption value="edit" label="Edit" />
                                //     <SelectOption value="owner" label="Owner" />
                                // </Select>

                            // </Box>
                            }
                            <Box>
                                <Button
                                  size="small"
                                  type="primary"
                                  submit={true}
                                  disabled={this.state.u_name_error || !this.state.u_name}
                                >
                                    Add user
                                </Button>
                            </Box>
                        </Row>
                    </form>
                    <Heading level={6} alignment='left' className='box-header'> Existing users </Heading>
                    {this.props.users.length === 0 ? "Project has no users yet." :
                        <Table
                            spacing="small"
                            headerRows={1}
                            showStripes={true}
                            showDividers={false}
                        >
                            <TableRow>
                                <TableCell> User </TableCell>
                            </TableRow>
                            {this.props.users.map((user) => (
                              <TableRow key={user.alias}>
                                <TableCell> {user.alias} </TableCell>
                              </TableRow>
                            ))}
                        </Table>
                    }
                </Box>
            </Column>

        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.project.users,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjectUsers: (project_id) => dispatch(getProjectUsers(project_id)),
        putProjectUser: (user_info) => dispatch(putProjectUser(user_info)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUsers);
