import updateS3Config, { getS3Object, s3Config } from '../utils/S3';
import AWS from 'aws-sdk';
import * as actionTypes from '../store/actions/actions';
import apiCall from '../utils/ApiCall';
import { DOCUMENT_STATES } from '../utils/Constants';
import { ERROR_OPENING_DOCUMENT, UNSUPPORTED_DOCUMENT_TYPE, DOCUMENT_STILL_BEING_PARSED, DOCUMENT_FAIL_UPLOAD, DOCUMENT_FAIL_PARSE } from '../constants/Strings'
import store from "../store/store"

export const putDocumentHandler = async (projectId, doc, status, document_id) => {
    try {
        let document_id_res = await store.dispatch(putDocumentDynamoHandler(projectId, doc.name, status, document_id))
        await store.dispatch(uploadDocumentHandler(projectId, doc, document_id_res))
    } catch (e) {
        console.log(`Failed to upload document ${doc.name}. Error: `, e)
    }
}

export const uploadDocumentHandler = (projectId, doc, document_id) => {
    var params = {
        Key: projectId + '/' + document_id + '_' + doc.name,
        Body: doc,
        ContentType: 'text/plain'
    };

    let documentName = doc.name
    updateS3Config(s3Config.buckets[s3Config.defaultBucket])
    var s3 = getS3Object()

    return (dispatch) => new Promise ((resolve, reject) => {
        s3.upload(params).promise().then((data) => {
            dispatch({type: actionTypes.UPLOADED_FILE, documentName});
            resolve(documentName)
        })
        .catch((err) => {
            dispatch({type: actionTypes.FAIL_UPLOADED_FILE, documentName});
            // TODO: delete document once delete api is implemented.
            dispatch(putDocumentDynamoHandler(projectId, documentName, DOCUMENT_STATES.FAIL_UPLOAD, document_id));
            console.log(err);
            reject(err)
        });
    })
}

export const getFileContentsHandler = (projectId, documentName, documentParsedPath, documentId) => {
    var params = {
        Key: projectId + '/' + documentParsedPath,
    };

    updateS3Config(s3Config.buckets['parsedDocuments'])
    var s3 = getS3Object()

    return (dispatch, getState) => {
        if(documentName.toLowerCase()) {
            s3.getObject(params).promise().then((data) => {
                dispatch(actionTypes.getFileContents(documentName, data.Body.toString('utf-8').normalize('NFC')));
                dispatch({type: actionTypes.SET_DATA_LOADED_FOR_DOCUMENT_TEXT, status: true});
            })
            .catch((err) => {
                dispatch(actionTypes.getFileContents(documentName, ERROR_OPENING_DOCUMENT + err));
                dispatch(actionTypes.showNotification(documentName + ' : ' + err.message, 'error'))
                console.log(err);
            })
        } else {
            dispatch(actionTypes.getFileContents(UNSUPPORTED_DOCUMENT_TYPE));
            dispatch(actionTypes.showNotification(documentName + ' : ' + err.message, 'error'))
        }
    }
}

export const putDocumentDynamoHandler = (projectId, documentName, status, documentId) => {
    let param = {
        document_name: documentName,
        document_id: documentId,
        state: status,
    }

    return (dispatch) => new Promise ((resolve, reject) => {
        apiCall('/project/{projectId}/documents', {projectId: projectId}, 'POST', param).then((res) => {
            let doc = {filePath: documentName, state: status, documentId: res.document_id, documentName: documentName};
            // Todo: Split this piece into two functions for upload and complete
            if(status === DOCUMENT_STATES.REGISTERED) {
                dispatch({type: actionTypes.ADD_UPLOADED_FILE, doc});
            } else {
                dispatch({type: actionTypes.UPDATE_FILE_STATUS, doc});
            }
            resolve(res.document_id)
        })
        .catch((err) => {
            dispatch({type: actionTypes.FAIL_UPLOADED_FILE, documentName});
            console.log(err);
            reject(err)
        });
    })
}

export const getDocumentMetaHandler = (projectId, documentId) => {
    let param = {
        project_id: projectId,
    }

    return (dispatch, getState) => {
        apiCall('/document/{documentId}', {documentId: documentId}, 'GET', '', {}, {project_id: projectId}).then((res) => {
            if (res == null){
                throw "Document was not found."
            }
            dispatch(actionTypes.addDocumentMeta(res))

            if (res.State == DOCUMENT_STATES.UPLOADED){
                dispatch(actionTypes.showNotification(DOCUMENT_STILL_BEING_PARSED, 'warning'))
            } else if (res.State == DOCUMENT_STATES.REGISTERED){
                dispatch(actionTypes.showNotification(DOCUMENT_STILL_BEING_PARSED, 'warning'))
            } else if (res.State == DOCUMENT_STATES.FAIL_PARSED){
                dispatch(actionTypes.showNotification(DOCUMENT_FAIL_PARSE, 'error'))
            } else if (res.State == DOCUMENT_STATES.FAIL_UPLOAD){
                dispatch(actionTypes.showNotification(DOCUMENT_FAIL_UPLOAD, 'error'))
            } else {
                dispatch(getFileContentsHandler(projectId, res.DocumentName, res.ParsedFilePath, documentId));
            }

        })
        .catch((err) => {
            dispatch(actionTypes.showNotification(err, 'error'))
            console.log(err);
            dispatch({type: actionTypes.SET_DATA_LOADED_FOR_DOCUMENT_TEXT, status: true});
        });
    }
}

export const getRelatedDocumentsHandler = (projectId, documentId) => {
    return (dispatch) => {
        apiCall('/document/{documentId}/relationships', { documentId: documentId }, 'GET', '', {}, { project_id: projectId }).then((res) => {
            let documentsRelations = []
            res.forEach(function (item) {
                const document = {};
                document.documentId = item.DocumentId;
                document.docSequenceNumber = item.DocumentNumInSequence;
                documentsRelations.push(document);
              });
            dispatch(actionTypes.addDocumentRelationships(documentsRelations))
        })
        .catch((err) => {
            dispatch(actionTypes.showNotification(err, 'error'))
            console.log(err);
        });
    }
}