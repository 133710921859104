import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProjectAnnotationSummary } from '../../requests/ProjectRequests'
import Box from "@meridian/components/box"
import Heading from "@meridian/components/heading"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import Tooltip from "@meridian/components/tooltip"
import helpTokens from "@meridian/tokens/base/icon/help"
import Icon from "@meridian/components/icon"
import '../../assets/css/Project.css'
import * as actionTypes from '../../store/actions/actions'
import {getUserCreatedLabels} from '../../utils/LabelsFilter'
import { ANNOTATION_SUMMARY_SAMPLES_TOOLTIP, ANNOTATION_SUMMARY_RECOMMENDED_SAMPLES_TOOLTIP, PROJECT_HAS_NO_LABELS } from '../../constants/Strings'
import {THRESHOLD_FOR_NUM_DOCS_PER_LABEL_TYPE} from "../../constants/AnnotationSummaryConstants";


class AnnotationSummary extends Component {
    state = {
        labelList: [],
    }

    componentDidMount = () => {
        if (this.props.projectId) {
            this.props.getProjectAnnotationSummary(this.setProjectAnnotationSummaryHandler, this.props.projectId);
        }
    }

    componentDidUpdate (prevProps, prevState) {
        if ((this.props.projectId) && (this.props.projectId != prevProps.projectId)) {
            this.props.getProjectAnnotationSummary(this.setProjectAnnotationSummaryHandler, this.props.projectId);
        }
    }

    setProjectAnnotationSummaryHandler = (labels) => {
        let userCreatedLabels = getUserCreatedLabels(labels["annotationSummary"])
        this.setState({labelList: userCreatedLabels})
        this.props.setAreNumDocsAboveThresholdPerLabelTypeHandler(this.isDocCountAboveThresholdPerLabelType(labels))
        this.props.setDataLoadedForAnnotationSummary(true)
    }

    isDocCountAboveThresholdPerLabelType = (res) => {
        const isAboveThreshold = (value) => value >= THRESHOLD_FOR_NUM_DOCS_PER_LABEL_TYPE
        return Object.keys(res["numDocsPerLabelType"]).length > 0 && Object.values(res["numDocsPerLabelType"]).every(isAboveThreshold)
    }

    render() {
        if (this.props.projectId == ""){
            return null
        }

        const listOfLabels = this.state.labelList
            .map((label) => (
                <TableRow key={label.name}>
                    <TableCell>
                        { label.name }
                    </TableCell>
                    <TableCell>
                        { label.samples}
                    </TableCell>
                    <TableCell>
                        { label.recommended}
                    </TableCell>
                </TableRow>));

        return (
            <Box className="AnnotationSummaryBox"
              type="outline"
              spacingInset="small"
              minHeight="30%"
              maxHeight="50%"
              width="100%"
            >
                <Heading level={4} type="h500" alignment='left' className='box-title'> Annotation summary </Heading>
                {this.state.labelList.length === 0 ? PROJECT_HAS_NO_LABELS :
                    <div>
                        <Table
                          spacing="medium"
                          headerRows={1}
                          showStripes={true}
                          showDividers={false}
                        >
                            <TableRow key='AnnotationSummaryTableHeader'>
                                <TableCell>
                                    Label name
                                </TableCell>
                                <TableCell>
                                    Number of samples
                                    <Tooltip position="bottom" title={ANNOTATION_SUMMARY_SAMPLES_TOOLTIP}>
                                      <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    Recommended number of samples
                                    <Tooltip position="bottom" title={ANNOTATION_SUMMARY_RECOMMENDED_SAMPLES_TOOLTIP}>
                                      <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            { listOfLabels }
                        </Table>
                    </div>
                }
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.projectId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showNotification: (message, messageType) => dispatch({type: actionTypes.SHOW_NOTIFICATION, message: message, messageType: messageType}),
        getProjectAnnotationSummary: (labelsHandler, projectId) => dispatch(getProjectAnnotationSummary(labelsHandler, projectId)),
        setDataLoadedForAnnotationSummary: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_ANNOTATION_SUMMARY, status: status})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationSummary);

