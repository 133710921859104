import React from 'react'
import '../../assets/css/CreateProject.css'
import { Redirect } from 'react-router-dom'
import Column from "@meridian/components/column"
import Box from "@meridian/components/box"
import Alert from "@meridian/components/alert"
import Input from "@meridian/components/input"
import Textarea from "@meridian/components/textarea"
import Text from "@meridian/components/text"
import Button from "@meridian/components/button"
import Heading from "@meridian/components/heading"
import Divider from "@meridian/components/divider"
import { putNewProjectMeta } from '../../requests/ProjectRequests'
import { MetricPageLatencyTimer } from "../../utils/MetricPageLatencyTimer"
import { CLIENT_METRICS_METHOD_NAMES } from "../../utils/Constants"
import { PROJECT_DEFINITION } from "../../constants/Strings"

export default class CreateProject extends React.Component{

    metricPageLatencyTimer = new MetricPageLatencyTimer()

    state = {
        project_name: '',
        project_desc: '',
        project_id: '',
        created: false,
        project_name_error: false,
        submit: false
    }

    setProjectIdHandler = (id) => {
        this.setState({
            created: true,
            project_id: id,
        })
    }

    formChange = (field, value) => {
        this.setState({ [field]: value})
    }

    verifyName = (e) => {
        e.preventDefault()
        this.setState({submit: true})

        if (!this.state.project_name.trim()){
          this.formChange('project_name_error', true)
        }

        if (this.state.project_name.trim()){
          putNewProjectMeta(this.setProjectIdHandler, {name: this.state.project_name, desc: this.state.project_desc})
        } else {
          this.setState({submit: false})
        }
    }

    componentDidMount = () => {
      this.metricPageLatencyTimer.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.CREATEPROJECTPAGE);
    }

    render(){
        if (this.state.created === true){
            return (
                <Redirect push to={{ pathname: "/manage/" + this.state.project_id, state: { fromCreateProjectPage: true } }} />
            )
        }
        return (
            <Column alignmentHorizontal="center" spacingInset="large">
              <form onSubmit={this.verifyName}>
                <Box
                  type="outline"
                  maxWidth={500}
                >
                  <Column
                    heights={"fit"}
                    alignmentHorizontal="center"
                    alignmentVertical="center"
                    spacing="medium"
                    spacingInset="small"
                    minWidth={500}
                  >

                    <Heading level={4} alignment='center'> Create a new project </Heading>
                    <Divider />
                    <Text> {PROJECT_DEFINITION} </Text>
                    <Divider />
                    <Input
                      value={this.state.project_name}
                      label="Project Name"
                      size="small"
                      width={250}
                      type="text"
                      error={this.state.project_name_error}
                      onChange={(value) => {this.formChange('project_name', value)}}
                      onBlur={() =>
                                this.state.project_name.trim() ? this.formChange('project_name_error', false) : this.formChange('project_name_error', true)
                              }
                    />
                    {this.state.project_name_error ? (
                      <Alert type="error" size="small">
                        Name is required.
                      </Alert>
                    ) : null}

                    <Textarea
                      value={this.state.project_desc}
                      label="Description"
                      width={250}
                      rows={1}
                      onChange={(value) => {this.formChange('project_desc', value)}}
                    />

                    <Button
                        size="medium"
                        type="primary"
                        submit={true}
                        disabled={this.state.submit}
                    >
                        Create
                    </Button>
                  </Column>
                </Box>
              </form>
            </Column>
        )
    }
}
