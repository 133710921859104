import AWS from 'aws-sdk';
import store from "../store/store"
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { AUTH_DATA, IDENTITY_POOL_ID, COGNITO_PROVIDER } from '../config/cognito';
import updateS3Config from './S3'
import { REGION } from './AWSConfig'
import { getIamRoleArn } from './EnvironmentConfig'
import { COGNITO_AUTH_FAILURE } from '../constants/Strings'
import { showNotification } from '../store/actions/actions'
import { recordUserLogIn } from './Pinpoint'

export function getAccessToken() {
    return store.getState().user.accessToken
}

export function getTokenExpiry() {
    return store.getState().user.tokenExpiry
}

export function isTokenExpired() {
    let currentTime = Math.floor(new Date().getTime()/1000.0)

    if (currentTime >= getTokenExpiry()){
        return true
    }
    return false
}

async function setupAWSCredentials(auth) {
    const session = auth.getSignInUserSession()
    const token = session.getIdToken().getJwtToken()

    const params = {
        IdentityPoolId : IDENTITY_POOL_ID,
        Logins : {
            [COGNITO_PROVIDER] : token
        },
        RoleArn : getIamRoleArn(),
        RoleSessionName : 'webAppUser-' + session.idToken.payload.preferred_username,
        LoginId : session.idToken.payload.preferred_username
    }

    var credentials = new AWS.CognitoIdentityCredentials(params);
    await credentials.getPromise()

    AWS.config.credentials = credentials
    AWS.config.region = REGION
    updateS3Config()
}

function getAuth(successCallback) {
    const auth = new CognitoAuth(AUTH_DATA);
    auth.useCodeGrantFlow();

    return auth
}

function removeQueryFromLocation() {
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?')[0];
        window.history.pushState(undefined, 'DataLens', newHref)
    }
}

export default function isAuthenticated() {
    return new Promise((resolve, reject) => {
        const auth = getAuth()
        auth.userhandler = {
            onFailure: (err) => {
                removeQueryFromLocation()
                localStorage.clear()
                store.dispatch(showNotification(COGNITO_AUTH_FAILURE, 'error'))
                reject(auth)
            },
            onSuccess: (result) => {
                removeQueryFromLocation()

                setupAWSCredentials(auth).then(() => {
                    recordUserLogIn(auth.signInUserSession.idToken.payload.preferred_username)
                    resolve(auth)
                })
            },
        }

        const href = window.location.href
        let session = auth.getSignInUserSession()

        if (session.isValid()) {
            removeQueryFromLocation()
            setupAWSCredentials(auth).then(() => {
                recordUserLogIn(auth.signInUserSession.idToken.payload.preferred_username)
                resolve(auth)
            })
        } else if (href.indexOf('?') > 0) {
            auth.parseCognitoWebResponse(href)

        } else {
            session = auth.getSession()
        }
    })
}