import Alert from "@meridian/components/alert";
import React from "react";

export const DOCUMENT_STILL_BEING_PARSED = 'The document is still being parsed. Please try again after a few minutes.'
export const ERROR_OPENING_DOCUMENT = 'An error occured while trying to open the document: '
export const UNSUPPORTED_DOCUMENT_TYPE = 'Unsupported document type. Please choose a text document.'
export const COGNITO_AUTH_FAILURE = 'Error fetching user credentials. Please refresh page.'
export const DOCUMENT_PREDICTIONS_FAILURE = 'Error fetching document predictions.'
export const USER_ANNOTATIONS_FAILURE = 'Error fetching annotations. Please refresh page.'
export const TRAINING_IN_PROGRESS_MESSAGE = 'Training in progress. You can still upload documents to use for verification.'
export const LAUNCHING_IN_PROGRESS_MESSAGE = 'Your project is being launched. You will be notified via an email when the launch process is complete.'
export const INSUFFICIENT_SAMPLES_WARNING_TITLE = 'Insufficient samples warning'
export const INSUFFICIENT_SAMPLES_TEXT = 'The following labels have less than recommended number of samples:'
export const INSUFFICIENT_SAMPLES_WARNING_MESSAGE = 'The model performance will be affected for these labels.'
export const TEST_METRICS_FAILURE = 'Failed to get test metrics.'
export const START_TRAINING_SUCCESS = 'Successfully started training. You will receive an email after training is completed.'
export const START_TRAINING_FAILURE = 'Failed to initiate training. Please contact DataLens team.'
export const GET_ANNOTATION_SUMMARY_FAILURE = 'Failed to get annotation summary.'
export const DOCUMENT_FAIL_UPLOAD = 'Failed to upload document.'
export const DOCUMENT_FAIL_PARSE = 'Failed to parse document into text.'
export const GET_PROJECT_LABELS_FAILURE = 'Failed to get project labels.'
export const GET_PROJECT_DOCUMENTS_FAILURE = 'Failed to get project documents.'
export const GET_PROJECT_DETAILS_FAILURE = 'Failed to get project information.'
export const GET_PROJECT_USERS_FAILURE = 'Failed to get project users.'
export const PUT_PROJECT_USER_FAILURE = 'Failed to add user to project.'
export const PUT_PROJECT_LABEL_FAILURE = 'Failed to add label to project.'
export const UNSAVED_DOCUMENT_ANNOTATIONS = 'There are unsaved annotations in this document. If you leave without saving, changes will be lost. Do you still want to proceed?'
export const FILES_THAT_WERE_NOT_UPLOADED = 'Following files were not uploaded. They did NOT meet the criteria.'
export const FILES_ARE_UPLOADED = 'The uploaded files will now go through text parsing. You can see the progress in the left navigation. You might have to wait a few minutes while they are being converted to text format.'
export const ALL_FILES_SUCCESSFULLY_UPLOADED = 'Upload successful'
export const SOME_FILES_FAILED_UPLOADED = 'Some of the files failed to upload, see below.'
export const FILES_ACCEPTED_CRITERIA = 'Only .txt, .doc, .docx, .pdf and file size of under 10mb will be accepted'
export const FILES_UPLOAD_MESSAGE = 'Drag and drop files here, or click to select files'
export const FILES_FAILED_TO_UPLOAD = 'Following files failed to upload for unknown reasons. Please try uploading them again.'
export const GET_PRE_TRAINED_LABELS_BUNDLES_FAILURE_MESSAGE = 'Failed to get pre trained models'
export const IMPORT_LABELS_SUCCESSFUL_MESSAGE = 'Started import process successfully.'
export const IMPORT_LABELS_FAILURE_MESSAGE = 'Failed to Import labels to the project'
export const IMPORT_LABELS_CONFIRMATION_MESSAGE ="Please ensure you need these labels before importing them. The links above provide more information about how these labels work and what use cases they cover. Note: you cannot easily remove or modify the predictions of these labels."
export const IMPORTED_ANNOTATIONS_NOT_EDITABLE_WARNING_MESSAGE ="Imported Annotations are not editable"
export const EXPORT_PROJECT_MESSAGE = "Export excel will be emailed to you. Please contact us if you don’t receive it."
export const EXPORT_PROJECT_FAILURE = "Failed to export. Please try again."
export const EXPORT_ANNOTATION_SOURCE_REQUEST = "Which type of annotations would you like to export?"
export const MINIMUM_DOCUMENTS_REQUIRED_FOR_TRAINING = "Need atleast 2 documents with annotations to start training."
export const ATLEAST_TWO_DOCS_REQUIRED_PER_LABEL_TYPE_FOR_TRAINING = "Need annotations in at least two distinct docs per label type to start training."
export const PROJECT_DEFINITION = "A project is a set of documents with samples of information to be captured for the machine to understand what needs to be extracted or inferred."
export const ADD_USER_TOOLTIP = "We recommend copying the user alias from Phone Tool."
export const ANNOTATION_SUMMARY_SAMPLES_TOOLTIP = "Number of samples added by users for this project."
export const ANNOTATION_SUMMARY_RECOMMENDED_SAMPLES_TOOLTIP = "Recommended number of samples to start training a project. Based on model performance, users can choose to add more in future iterations."
export const METRICS_PRECISION_TOOLTIP = "Measures the quality of extracted metadata."
export const METRICS_RECALL_TOOLTIP = "Measures the coverage of extracted metadata."
export const METRICS_LABEL_PERFORMANCE_TOOLTIP = "Represents the harmonic mean of precision and recall."
export const ADD_LABEL_NAME_TOOLTIP = "Name: Label represents the text to be extracted from the documents."
export const ADD_LABEL_TYPE_EXTRACT_DEFINITION_TOOLTIP = "DataLens will extract keywords using context so the value can vary. For example, you could have a 30 day payment term in one document vs a 60 day payment term in another document but DataLens will pull each of those values because it recognizes it’s a payment term base on the context."
export const ADD_LABEL_TYPE_EXTRACT_DEFINITION = "Type - Extract: Locate keywords mentioned in the documents."
export const ADD_LABEL_TYPE_INFERENCE_DEFINITION_TOOLTIP = "Type - Inference: Pull information from documents based on context."
export const ADD_LABEL_TYPE_REGEX_DEFINITION_TOOLTIP = "DataLens will look for a match to the word or sentence you type and extract that specific information.  For example, if you type “AWS CloudFront” DataLens will search and return “AWS CloudFront”."
export const ADD_LABEL_TYPE_REGEX_DEFINITION = "Type - Extract (Find Text): Locate exact keyword regardless of context mentioned in the documents."
export const PROJECT_HAS_NO_LABELS = "Project has no labels yet. Please go to \"Labels\" section under \"Manage Project\" to start adding labels."
export const PRE_TRAIN_IMPORT_MODEL_DEFINITION = "Pre-trained labels are pre-existing, optimized models trained by the DataLens team or shared with you by another DataLens user. Users can import these and use them off-the-shelf without spending any time on training."
export const BACK_TO_COLLECTION_TITLE = 'Back to collection'
export const BACK_TO_COLLECTION_TEXT = 'This will take you back to the collection phase. You can import predictions from this round of training to use as annotations for the next round of training. Predictions can only be imported for documents without user annotations.'
export const GET_PROJECT_TRAININGS_FAILURE = 'Failed to get project trainings'
export const IMPORT_PREDICTIONS_ENABLED_TOOLTIP = 'Import predictions from the previously trained model to use as annotations for training.'
export const IMPORT_PREDICTIONS_DISABLED_TOOLTIP = 'Predictions can be imported only if the document has no user annotations. Remove all user annotations and hit Save to enable this button.'
export const NO_MODEL_PREDICTIONS_MESSAGE = 'Model did not return any predictions for this document.'
export const GET_EXTERNAL_CLIENTS_FAILURE = "Failed to get clients list."
export const LAUNCH_PROJECT_MODEL_FAILURE = "Failed to launch project model."
export const LAUNCH_PROJECT_SUCCESS_MESSAGE = "Successfully started the launch process."
export const GET_PROJECT_MODEL_TOKEN_FAILURE = "Failed to get project model token."
export const SHARE_MODEL_MESSAGE = "You can share your trained labels to other users with a similar use case. Trained labels with a performance of at least 80% are eligible for sharing and will be shown below. Select the labels you want to share and click Next."
export const SHARE_MODEL_ALL_LABELS_SHARED = "All labels have already been shared."
export const SHARE_MODEL_STARTED_SUCCESSFULLY = "A new model will be created.  The process to share labels can take awhile. Please monitor status on the right hand side."
export const SHARE_MODEL_FORM_HEADER = "Share your trained labels"
export const SHARE_MODEL_PERFORMANCE_NOT_AVAILABLE = "Will be available after label creation is completed."
export const EXISTING_SHARED_MODELS_HEADER = "Existing shared labels"
export const NO_SHARED_MODELS_FOR_PROJECT = "No labels have been shared yet."
export const ADD_ACTOR_TO_BUNDLE_SUCCESS_MESSSAGE = "Successfully shared label to user."
export const ADD_ACTOR_TO_BUNDLE_FAILURE_MESSSAGE = "Failed to add user to label."
export const CREATE_BUNDLE_FAILURE_MESSAGE = "Failed to create a label bundle."
export const CREATE_PRETRAIN_MODEL_FAILURE_MESSAGE = "Failed to start label creation."
export const GET_BUNDLES_FOR_PROJECT_FAILURE_MESSAGE = "Failed to get shared labels for the project."
export const GET_USERS_FOR_BUNDLE_FAILURE_MESSAGE = "Failed to get users for the shared label."
export const ADD_USERS_TO_SHARED_BUNDLE = "Add users with whom you want to share the labels."
export const FAILED_TO_CREATE_SHARED_MODEL_MESSAGE = "DataLens team is working on this failure."
export const ADD_CLIENT_TO_PROJECT_FAILURE = "Failed to add client to project."
export const GET_CLIENTS_FOR_PROJECT_FAILURE = "Failed to get clients for project."
export const ADD_CLIENT_TO_PROJECT_MESSAGE = "Add an application to share your model predictions through the DataLens API. After adding the application in DataLens you'll need to complete configuration steps within the selected application."
export const TOOLTIP_FOR_CONFIGURE_IN_APP = "Click the link below to go to the application and finish the ingeration steps."
export const PHONE_TOOL_LINK = 'https://phonetool.amazon.com/users/'
export const ADMIN = "admin"
export const CUSTOM_USER_TYPE = "custom:user_type"
export const USER_TYPE = "user_type"
export const LOCAL_STORAGE_ADMIN_TOGGLE_KEY = "currentAdminToggleState"
export const REGEX_LABEL_TYPE = "Regex"