import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/css/Metadata.css';
import '../../assets/css/SaveMetadata.css';
import Button from "@meridian/components/button"
import { putDocumentAnnotations } from '../../requests/AnnotationRequests';
import Tooltip from "@meridian/components/tooltip";

class SaveMetadata extends Component {
    render() {
        if (this.props.isDisabled) {
            return (
                <div className = "SaveMetadata">
                    <Tooltip position="left"
                      title="Annotation edits disabled while in training."
                    >
                        <Button size="medium" disabled={true}>
                            Save
                        </Button>
                    </Tooltip>
                </div>)
        }
        return (
            <div className = "SaveMetadata">
                <Button
                    size="medium"
                    className = ""
                    onClick = {() => this.props.putDocumentAnnotations(this.props.documentId, this.props.metadataToSave, this.props.onSave)}>
                    Save
                </Button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        documentId: state.currDocument.documentId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        putDocumentAnnotations: (documentId, metadataToSave, onSave) => dispatch(putDocumentAnnotations(documentId, metadataToSave, onSave)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveMetadata);