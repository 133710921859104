import React from 'react';
import '../../assets/css/CreateProject.css';
import { connect } from 'react-redux';
import Box from "@meridian/components/box"
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import Input from "@meridian/components/input"
import Button from "@meridian/components/button"
import Link from "@meridian/components/link"
import Select, { SelectOption } from "@meridian/components/select"
import Textarea from "@meridian/components/textarea"
import Alert from "@meridian/components/alert"
import Heading from "@meridian/components/heading"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import {putProjectLabel } from '../../requests/ProjectRequests';
import { LABEL_TYPE_MAPPING } from '../../utils/Constants'
import { ADD_LABEL_NAME_TOOLTIP,
         ADD_LABEL_TYPE_EXTRACT_DEFINITION_TOOLTIP, ADD_LABEL_TYPE_EXTRACT_DEFINITION,
         ADD_LABEL_TYPE_INFERENCE_DEFINITION_TOOLTIP,
         ADD_LABEL_TYPE_REGEX_DEFINITION_TOOLTIP, ADD_LABEL_TYPE_REGEX_DEFINITION,
         REGEX_LABEL_TYPE } from '../../constants/Strings'
import {TT_LINK_TO_DELETE_USER_LABELS} from '../../constants/TicketQuickLinks'
import Icon from "@meridian/components/icon"
import Text from "@meridian/components/text"
import closeKnockoutTokens from "@meridian/tokens/base/icon/close-knockout";
import helpTokens from '@meridian/tokens/base/icon/help'
import Tooltip from "@meridian/components/tooltip"


class ProjectUserLabels extends React.Component{

    state = {
        projectId: this.props.projectId,
        label_name: '',
        label_type: '',
        label_desc: '',
        label_name_error: false,
        label_type_error: false,
        regex_error: false,
        is_regex_label: false,
        regex: null
    }

    formChange = (field, value) => {
        this.setState({ [field]: value })
    }

    submitNewLabel = (e) => {
        e.preventDefault()
        if (!this.state.label_name.trim()){
          this.formChange('label_name_error', true)
        }
        if (!this.state.label_type.trim()){
          this.formChange('label_type_error', true)
        }
        
        let db_label_type;
        this.state.is_regex_label ? db_label_type = REGEX_LABEL_TYPE : db_label_type = this.state.label_type;

        if (this.state.label_type.trim() && this.state.label_name.trim()){
          this.props.putProjectLabel({project_id: this.state.projectId, label_name: this.state.label_name, label_type: db_label_type, label_desc: this.state.label_desc, regex: this.state.regex})
          this.setState({label_name: '', label_type: '', label_desc: '', is_regex_label: false, regex: null})
        }
    }

    isValidRegex = (regex) => {
        let valid_reg = new RegExp(/^[a-z0-9 ]+$/i);
        if (!regex.trim() || !valid_reg.test(regex)) {
            return false
        }
        return true
    }

    render(){
        return (
            <Column spacingInset="medium" type="outline" height = "100%">
                <Heading level={6} alignment='left' className='box-header'> Add label </Heading>

                <Box>
                  <Text type="b200"> {ADD_LABEL_NAME_TOOLTIP} </Text>
                  <Text type="b200"> {ADD_LABEL_TYPE_EXTRACT_DEFINITION}
                    <Tooltip position="bottom" title={ADD_LABEL_TYPE_EXTRACT_DEFINITION_TOOLTIP}>
                        <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                    </Tooltip>
                  </Text>
                  { this.props.allowRegex ?
                    <Text type="b200"> {ADD_LABEL_TYPE_REGEX_DEFINITION}
                        <Tooltip position="bottom" title={ADD_LABEL_TYPE_REGEX_DEFINITION_TOOLTIP}>
                            <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                        </Tooltip>
                    </Text>
                    : null
                  }

                  <Text type="b200"> {ADD_LABEL_TYPE_INFERENCE_DEFINITION_TOOLTIP} </Text>
                </Box>
                <form onSubmit={this.submitNewLabel}>
                    <Row
                        wrap="down"
                        alignment="top left"
                        minHeight={80}
                        spacing='small'
                        spacingInset='small'
                    >
                        <Box>
                            <Input
                                value={this.state.label_name}
                                size="small"
                                width={250}
                                type="text"
                                prefix="Name*"
                                placeholder="..."
                                error={this.state.label_name_error}
                                onChange={(value) => {this.formChange('label_name', value)}}
                                onBlur={() =>
                                    this.state.label_name.trim() ? this.formChange('label_name_error', false) : this.formChange('label_name_error', true)
                                }
                            />
                            <Box>
                                {this.state.label_name_error ? (
                                    <Alert type="error" size="small">
                                        Name is required.
                                    </Alert>
                                ) : null}
                            </Box>
                        </Box>
                        <Box>
                            <Select
                                value={this.state.label_type}
                                placeholder="Pick Option..."
                                prefix="Type*"
                                width={200}
                                size="small"
                                error={this.state.label_type_error}
                                onChange={(value) => {
                                    this.formChange('label_type', value)
                                    this.formChange('label_type_error', false)
                                }}
                                onBlur={() =>
                                    this.state.label_type.trim() ? this.formChange('label_type_error', false) : this.formChange('label_type_error', true)
                                }
                            >
                                <SelectOption value="Classification" label={LABEL_TYPE_MAPPING['Classification']} />
                                <SelectOption value="Entity Extraction" label={LABEL_TYPE_MAPPING['Entity Extraction']} />
                            </Select>
                            <Box>
                                {this.state.label_type_error ? (
                                    <Alert type="error" size="small">
                                        Type is required.
                                    </Alert>
                                ) : null}
                            </Box>
                            <Box
                                className="AddPatternLink">
                                {this.state.label_type == "Entity Extraction" && this.props.allowRegex ?
                                    <Link onClick={() => this.formChange('is_regex_label', true)}>
                                        Add text to find
                                    </Link>
                                : null}
                            </Box>
                        </Box>
                        {this.state.is_regex_label ?
                            <Box>
                                <Box>
                                    <Input 
                                        value={this.state.regex}
                                        size="small"
                                        width={250}
                                        type="text"
                                        prefix="Text to find*"
                                        placeholder="..."
                                        error={this.state.regex_error}
                                        onChange={(value) => {this.formChange('regex', value)}}
                                        onBlur={() => 
                                            this.isValidRegex(this.state.regex) ? this.formChange('regex_error', false) : this.formChange('regex_error', true)
                                        }
                                    />
                                </Box>
                                <Box>
                                    {this.state.regex_error ? (
                                        <Alert type="error" size="small">
                                            Valid regex must only inlcude alphanumeric characters and spaces.
                                        </Alert>
                                    ) : null}
                                </Box>
                            </Box>
                        : null}
                        <Box>
                            <Textarea
                                value={this.state.label_desc}
                                placeholder="Description..."
                                width={350}
                                resize="none"
                                rows={1}
                                onChange={(value) => {this.formChange('label_desc', value)}}
                            />
                        </Box>
                        <Box>
                            <Button
                                size="small"
                                type="primary"
                                submit={true}
                                disabled={this.state.label_name_error || this.state.label_type_error || this.state.regex_error || 
                                    (!this.state.regex && this.state.is_regex_label) || (!this.state.label_name || !this.state.label_type)}
                            >
                                Add label
                            </Button>
                        </Box>
                    </Row>
                    * Required
                </form>
                <Heading level={6} alignment='left' className='box-header'> Existing labels </Heading>
                {this.props.userLabels.length === 0 ? "No labels have been added for training." :
                    <Table
                        spacing="small"
                        headerRows={1}
                        showStripes={true}
                        showDividers={false}
                    >
                        <TableRow>
                            <TableCell> Name </TableCell>
                            <TableCell> Type </TableCell>
                            <TableCell> Description </TableCell>
                            {this.props.allowRegex ?
                                <TableCell> Text to find </TableCell> : null
                            }
                            <TableCell> </TableCell>
                        </TableRow>
                        {this.props.userLabels.map((label) => (
                            <TableRow key={label.name}>
                                <TableCell> {label.name} </TableCell>
                                <TableCell> {LABEL_TYPE_MAPPING[label.type]} </TableCell>
                                <TableCell> {label.description} </TableCell>
                                {this.props.allowRegex ?
                                    <TableCell> {label.regex ? label.regex : '-'} </TableCell>
                                    : null
                                }
                                <TableCell>
                                    <Button type="icon" href={TT_LINK_TO_DELETE_USER_LABELS} target="_blank">
                                        <Icon tokens={closeKnockoutTokens}/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                }
            </Column>
        )
    }
}

const mapStateToProps = state => {
    return {
        allowRegex: state.project.allowRegex
    };
};

const mapDispatchToProps = dispatch => {
    return {
        putProjectLabel: (label) => dispatch(putProjectLabel(label)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUserLabels);