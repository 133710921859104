import { HOSTNAME } from '../constants/StageConstants.js';

const LogRocket = require('logrocket/setup')({
  sdkServer: 'https://dashboard.logging.legal.amazon.dev',
  ingestServer: 'https://ingest.logging.legal.amazon.dev',
});
  
const getAppId = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    default:
      return 'amazon-legal-prod/dl-beta';
    case HOSTNAME.GAMMA:
      return 'amazon-legal-prod/dl-gamma';
    case HOSTNAME.PROD:
      return 'amazon-legal-prod/dl-prod';
  }
};
  
const appId = getAppId();

console.log(`Initializing LogRocket with appId: ${appId}`);

LogRocket.init(appId, {
  dom: {
    inputSanitizer: true,
    textSanitizer: false,
  },
  network: {
    requestSanitizer: (request) => {
      request.headers = {}; // allow no security headers or tokens
      request.credentials = null; // allow no credentials to be logged
      return request;
    },
    responseSanitizer: (response) => {
      response.headers = {}; // allow no security headers or tokens
      return response;
    },
  },
});

LogRocket.identifyUser = (employeeData) => {
  if (!employeeData) {
    return;
  }
  // For GDPR we need to anonymize EU employees, and DL does not inject user's country info,
  // so use timezone to exclude id for GMT+0 and eastward: EU, DE, GDPR, etc.
  if (new Date().getTimezoneOffset() > 0) {
    // exclude id for non-en-US
    if (navigator.language === 'en-US') {
      const { uid } = employeeData;
      console.info(`Creating LogRocket session for user: ${uid}`);
      LogRocket.identify(uid, {
        uid: uid,
      });
    }
  }
};

export default LogRocket;