import * as preTrainedActionTypes from '../store/actions/pretrainedLabelsActions';
import * as sharedBundleActionTypes from '../store/actions/sharedBundlesActions';
import * as actionTypes from '../store/actions/actions';
import apiCall from '../utils/ApiCall';
import {
    GET_PRE_TRAINED_LABELS_BUNDLES_FAILURE_MESSAGE,
    IMPORT_LABELS_FAILURE_MESSAGE,
    IMPORT_LABELS_SUCCESSFUL_MESSAGE,
    ADD_ACTOR_TO_BUNDLE_SUCCESS_MESSSAGE,
    ADD_ACTOR_TO_BUNDLE_FAILURE_MESSSAGE,
    CREATE_BUNDLE_FAILURE_MESSAGE,
    CREATE_PRETRAIN_MODEL_FAILURE_MESSAGE,
    GET_BUNDLES_FOR_PROJECT_FAILURE_MESSAGE,
} from "../constants/Strings";
import { BUNDLE_ROLES } from '../constants/BundleRoles'
import {getProjectLabelsHandler} from "./ProjectRequests";

export const getPreTrainedLabelsBundlesForUser = () => {
    let labelsBundles = [];
    return (dispatch) => {
        apiCall('/pretrained/modelsbundles', {}, 'GET').then((res) => {
            res.GetPreTrainedModelsBundles.forEach(function (item) {
                if (item) {
                    let bundle = {};
                    bundle.bundleId = item.BundleId
                    bundle.bundleName = item.BundleName
                    bundle.owner = item.CreatedBy
                    bundle.labelModels = []
                    if (item.PreTrainedModels) {
                        item.PreTrainedModels.forEach(function (labelModel) {
                            bundle.labelModels.push({
                                'modelId': labelModel.PreTrainedModelId,
                                'labelId': labelModel.LabelId,
                                'type': labelModel.LabelType,
                                'name': labelModel.LabelName,
                                'description': labelModel.LabelDescription,
                                'moreInformationLink': labelModel.MoreInformationLink,
                            })
                        });
                    }
                    labelsBundles.push(bundle)
                }
            });
            dispatch(preTrainedActionTypes.addPreTrainedLabelsBundles(labelsBundles))
        })
        .catch((err) => {
            console.log(err);
            dispatch(preTrainedActionTypes.addPreTrainedLabelsBundles([]))
            dispatch(actionTypes.showNotification(GET_PRE_TRAINED_LABELS_BUNDLES_FAILURE_MESSAGE, 'error'))
        })
    }
}


export const importPreTrainedLabelModels = (projectId, modelIds, onSuccessHandler) => {
    return (dispatch) => {
        apiCall('/project/{project_id}/import/models', {project_id: projectId}, 'POST', {modelIds: modelIds}).then((res) => {
            dispatch(getProjectLabelsHandler(projectId))
            dispatch(actionTypes.showNotification(IMPORT_LABELS_SUCCESSFUL_MESSAGE, 'success'))
            onSuccessHandler()
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(IMPORT_LABELS_FAILURE_MESSAGE, 'error'))
        })
    }
}

export const addActorToPretrainedModelsBundle = (projectId, bundleId, alias, role) => {
    return (dispatch) => {
        let data = {BundleId: bundleId, ProjectId: projectId, Actors: alias, Role: role}
        apiCall('/pretrained/modelsbundle/actor/', {}, 'POST', data).then((res) => {
            dispatch(actionTypes.showNotification(ADD_ACTOR_TO_BUNDLE_SUCCESS_MESSSAGE, 'success'))
            let actor = {'role': role, 'alias': alias}
            dispatch(sharedBundleActionTypes.addActorToBundle(bundleId, actor))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(ADD_ACTOR_TO_BUNDLE_FAILURE_MESSSAGE, 'error'))
        })
    }
}

export const createPretrainedModelsBundle = (projectId, projectName, label, users) => {
    return (dispatch) => new Promise ((resolve, reject) => {
        let data = {BundleName: projectName, ProjectId: projectId, RequestEpoch: Date.now()}
        apiCall('/pretrained/modelsbundle/', {}, 'POST', data).then(async (res) => {
            let bundleId = res.BundleId

            await dispatch(createPretrainedModels(projectId, bundleId, [label]))
            for (let user of users) {
                dispatch(addActorToPretrainedModelsBundle(projectId, bundleId, user, BUNDLE_ROLES.IMPORTER))
            }
            resolve(bundleId)
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(CREATE_BUNDLE_FAILURE_MESSAGE, 'error'))
            reject(err)
        })
    })
}

export const createPretrainedModels = (projectId, bundleId, labelsToCreate) => {
    let labels = labelsToCreate.map((label) => ({LabelId: label.labelId, LabelName: label.name}))

    return (dispatch) => new Promise ((resolve, reject) => {
        let data = {BundleId: bundleId, ProjectId: projectId, PreTrainedLabelsToCreate: labels, RequestEpoch: Date.now()}
        apiCall('/pretrained/models/', {}, 'POST', data).then((res) => {
            resolve(true)
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(CREATE_PRETRAIN_MODEL_FAILURE_MESSAGE, 'error'))
            reject(err)
        })
    })
}

export const getPreTrainedModelsBundlesForProject = (projectId, onSuccessHandler) => {
    let sharedBundles = [];
    return  (dispatch) => {
        apiCall('/project/{project_id}/pretrained/modelsbundles/', {project_id: projectId}, 'GET').then((res) => {
            for (let item of res.PreTrainedModelsBundles){
                if (item) {
                    let bundle = {}
                    bundle.bundleId = item.BundleId
                    bundle.bundleName = item.BundleName
                    bundle.status = item.Status
                    bundle.labelModels = []
                    bundle.users = []
                    if (item.PreTrainedModels) {
                        item.PreTrainedModels.forEach(function (labelModel) {
                            let model = {
                                'modelId': labelModel.PreTrainedModelId,
                                'labelId': labelModel.LabelId,
                                'type': labelModel.LabelType,
                                'name': labelModel.LabelName,
                                'performance': labelModel.LabelPerformance,
                                'description': labelModel.LabelDescription,
                                'moreInformationLink': labelModel.MoreInformationLink,
                                'status': labelModel.Status
                            }
                            model['performance'] = (labelModel.LabelPerformance) ? labelModel.LabelPerformance.f1Percentage: ''
                            bundle.labelModels.push(model)
                        });
                    }
                    else{
                        continue
                    }

                    sharedBundles.push(bundle)
                }
            }
            dispatch(sharedBundleActionTypes.addSharedBundles(sharedBundles))
            onSuccessHandler()
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_BUNDLES_FOR_PROJECT_FAILURE_MESSAGE, 'error'))
        })
        .finally( () => {
            dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PRETRAINED_MODELS_BUNDLES, status: true});
        })
    }
}

export const getPretrainedModelsBundleUsers = (bundleId) => {
    return (dispatch) => {
        apiCall('/pretrained/modelsbundle/{bundle_id}/actors/', {bundle_id: bundleId}, 'GET').then((res) => {
            let users = []
            res.Actors.forEach((user) => {
                users.push({
                    'alias': user.ActorId,
                    'role': user.Role
                })
            })
            dispatch(sharedBundleActionTypes.addBundleUsers(bundleId, users))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(err, 'error'))
        })
    }
}
