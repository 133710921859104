import * as actionTypes from '../actions/actions'

const initialState = {
    message: '',
    messageType: ''
}

const notification = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_NOTIFICATION:
            return {
                message: action.message,
                messageType: action.messageType
            }
        case actionTypes.CLEAR_NOTIFICATION:
            return {
                message: '',
                messageType: ''
            }
        default:
            return state;
    }
};

export default notification;