import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../utils/Auth';
import { loginUser } from '../../store/actions/actions';
import Popover from "@meridian/components/popover";
import Text from "@meridian/components/text";
import Row from "@meridian/components/row";
import Toggle from "@meridian/components/toggle";
import { isAdminAndToggleOn, setAdminToggle} from '../../utils/AdminAuth';
import {ADMIN, CUSTOM_USER_TYPE} from "../../constants/Strings";

class User extends Component {

    state = {
        popoverMenuOpen: false,
        toggleState: isAdminAndToggleOn(this.props.auth.idToken.payload)
    }

    constructor(props) {
        super(props);
        this.setUserHandler(this.props.auth)
    }

    setUserHandler = (user) => {
        const user_info = {
            given_name : user.idToken.payload.given_name,
            username : user.idToken.payload.preferred_username,
            // Using a square bracket to access custom:user_type because of the
            // special character ":" present after "custom".
            user_type: ('custom:user_type' in user.idToken.payload) ? user.idToken.payload['custom:user_type'] : 'user',
            accessToken: user.accessToken.jwtToken,
            tokenExpiry: user.accessToken.payload.exp
        }
        this.props.loginUser(user_info)
    }

    changeToggleState = (val) => {
        this.setState({toggleState: val})
        setAdminToggle(val)
        this.props.setIsAdminToggleHandler(val)
    }

    shouldToggleBeVisibile = () => {
        if (CUSTOM_USER_TYPE in this.props.auth.idToken.payload) {
            return this.props.auth.idToken.payload['custom:user_type'] === ADMIN;
        }
        return false
    }

    render(){
        let user_image_url = "https://internal-cdn.amazon.com/badgephotos.amazon.com/phone-image.pl?uid="+this.props.user.username
        if (this.shouldToggleBeVisibile()) {
            return (
                <div>
                    <img className="UserImageAdmin" src={user_image_url} alt={this.props.user.given_name} ref={node => this.imageRef = node} onClick={() => this.setState({popoverMenuOpen: true})} />
                    <Popover
                        anchorNode={this.imageRef}
                        open={this.state.popoverMenuOpen}
                        position="bottom"
                        onClose={() => this.setState({ popoverMenuOpen: false })}
                        minWidth="anchor"
                        indicatorArrow={false}
                    >
                    <Row>
                        <Text>Admin </Text>
                        <Toggle checked={this.state.toggleState} onChange={(val) => this.changeToggleState(val)} disabled={false} />
                    </Row>
                    </Popover>
                </div>
            )
        }
        return (
            <div>
                <img className="UserImage" src={user_image_url} alt={this.props.user.given_name} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);