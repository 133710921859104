import React from 'react';
import Button from "@meridian/components/button"
import Table, { TableRow, TableCell, TableActionBar } from "@meridian/components/table"
import Checkbox from "@meridian/components/checkbox"
import minusIconTokens from "@meridian/tokens/base/icon/minus"
import checkIconTokens from "@meridian/tokens/base/icon/check-large"

const LABEL_PERFORMANCE_THRESHOLD = 80

export default class ShareModelTable extends React.Component {
  state = { labels: this.props.labels ? this.props.labels : [] }

  areAllSelected = () => this.state.labels.every(label => label.selected)

  areSomeSelected = () => this.state.labels.some(label => label.selected)

  getHeaderCheckboxIconTokens = () =>
    this.areSomeSelected() && !this.areAllSelected()
      ? minusIconTokens
      : checkIconTokens

  toggleAll = () => {
    const selected = this.areAllSelected() ? false : true
    this.setState({
      labels: this.state.labels.map(label => ({ ...label, selected })),
    })
  }

  toggleOne = labelId => selected => {
    this.setState({
      labels: this.state.labels.map(label =>
        label.labelId === labelId ? { ...label, selected: selected } : label
      ),
    })
  }

  nextStepHandler = () => {
    this.props.setLabels(this.state.labels)
    this.props.nextButtonHandler()
  }

  render() {
    return (
      <Table headerRows={1} showDividers={true} fixHeaderRows={true}>
        <TableActionBar alignmentHorizontal='right'>
          <Button onClick={this.nextStepHandler} disabled={!this.areSomeSelected()}>Next</Button>
        </TableActionBar>
        <TableRow>
          <TableCell>
            <Checkbox
              checked={this.areSomeSelected()}
              iconTokens={this.getHeaderCheckboxIconTokens()}
              onChange={this.toggleAll}
            />
          </TableCell>
          <TableCell>Name</TableCell>
          <TableCell width="10%">Label Performance</TableCell>
        </TableRow>
        {this.state.labels
            .filter(label => (label.f1 >= LABEL_PERFORMANCE_THRESHOLD))
            .map(label => (
          <TableRow key={label.labelId}>
            <TableCell>
              <Checkbox
                checked={label.selected}
                onChange={this.toggleOne(label.labelId)}
              />
            </TableCell>
            <TableCell>{label.name}</TableCell>
            <TableCell>{label.f1}</TableCell>
          </TableRow>
        ))}
      </Table>
    )
  }
}
