import * as actionTypes from '../store/actions/actions';
import apiCall from '../utils/ApiCall';
import { DOCUMENT_PREDICTIONS_FAILURE, USER_ANNOTATIONS_FAILURE } from '../constants/Strings';
import { DATA_SOURCE } from '../constants/DataSource';

export const getDocumentAnnotations = (documentId, documentContent) => {
  const annotations = [];

  return (dispatch) => {
    return apiCall('/document/{documentId}/annotations', { documentId: documentId }, 'GET')
      .then((res) => {
        res.forEach(function(item) {
          const annotation = {};
          annotation.annotationId = item.AnnotationId;
          annotation.labelId = item.LabelId;
          annotation.projectId = item.ProjectId;
          annotation.documentId = item.DocumentId;
          annotation.start = item.StartIndex;
          annotation.end = item.EndIndex;
          annotation.text = documentContent.substring(annotation.start, annotation.end);
          annotation.source = DATA_SOURCE.USER;
          annotations.push(annotation);
        });
      })
      .catch((err) => {
        dispatch(actionTypes.showNotification(USER_ANNOTATIONS_FAILURE + ' Error: ' + err, 'error'));
      })
      .finally(() => {
        dispatch(actionTypes.refreshAnnotationsForDocument(annotations, false));
        dispatch({ type: actionTypes.SET_DATA_LOADED_FOR_METADATA, status: true });
      });
  };
};

export const putDocumentAnnotations = (documentId, annotations, saveStatusHandler) => {
  return (dispatch) => {
    return apiCall('/document/{documentId}/annotations', { documentId: documentId }, 'POST', annotations)
      .then((res) => {
        dispatch(actionTypes.showNotification('Successfully Saved', 'success'));
        saveStatusHandler();
      })
      .catch((err) => {
        dispatch(actionTypes.showNotification('Failed to save annotations', 'error'));
      });
  };
};

export const getDocumentPredictions = (projectId, parsedFilePath, documentId, documentContent) => {
  const param = {
    endpoint_stage: 'test', // For MVP this will always be test. When we have retraining clients can call launched model or test model.
    document_id: documentId,
    document_path: projectId + '/' + parsedFilePath,
  };
  const predictions = [];
  return (dispatch) => {
    return apiCall('/project/{projectId}/predictions/', { projectId: projectId }, 'GET', '', {}, param)
      .then((res) => {
        res.forEach(function (item) {
          const prediction = {};
          prediction.annotationId = item.PredictionId;
          prediction.labelId = item.LabelId;
          prediction.projectId = projectId;
          prediction.start = item.StartIndex;
          prediction.end = item.EndIndex;
          prediction.text = documentContent.substring(prediction.start, prediction.end);
          prediction.source = DATA_SOURCE.MODEL;
          prediction.metadata = item.TextMetadata;
          prediction.textType = item.TextType;
          prediction.relationshipTermAction = item.RelationshipTermAction;
          predictions.push(prediction);
        });
      })
      .catch((err) => {
        dispatch(actionTypes.showNotification(DOCUMENT_PREDICTIONS_FAILURE + ' Error: ' + err, 'error'));
      })
      .finally(() => {
        dispatch(actionTypes.refreshPredictionsForDocument(predictions));
        dispatch({ type: actionTypes.SET_DATA_LOADED_FOR_METADATA, status: true });
      });
  };
};
