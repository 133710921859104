import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/css/Metadata.css';
import { putDocumentDynamoHandler } from '../../requests/FileRequests';
import Button from "@meridian/components/button";
import { DOCUMENT_STATES } from '../../utils/Constants'

class MarkDocumentCompleted extends Component {

    render() {
        return (
            <div className = "MarkDocumentCompleted">
                <Button
                    size="small"
                    type = "primary"
                    onClick = {() => this.props.putDocumentDynamoHandler(this.props.projectId, this.props.documentName, DOCUMENT_STATES.COMPLETED, this.props.documentId)}
                >
                    Mark document completed
                </Button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        documentId: state.currDocument.documentId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        putDocumentDynamoHandler: (projectId, documentName, status, documentId, statusHandler) => dispatch(putDocumentDynamoHandler(projectId, documentName, status, documentId, statusHandler)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkDocumentCompleted);
