import React from 'react';
import '../../assets/css/CreateProject.css';
import { connect } from 'react-redux';
import Box from "@meridian/components/box"
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import Button from "@meridian/components/button"
import Select, { SelectOption } from "@meridian/components/select"
import Alert from "@meridian/components/alert"
import Heading from "@meridian/components/heading"
import Text from "@meridian/components/text"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import {getPreTrainedLabelsBundlesForUser, importPreTrainedLabelModels} from "../../requests/PreTrainedLabelsRequests";
import Icon from "@meridian/components/icon"
import closeKnockoutTokens from "@meridian/tokens/base/icon/close-knockout"
import {
    IMPORT_LABELS_CONFIRMATION_MESSAGE,
    PRE_TRAIN_IMPORT_MODEL_DEFINITION
} from '../../constants/Strings'
import Link from "@meridian/components/link"
import { LABEL_TYPE_MAPPING } from '../../utils/Constants'
import {TT_LINK_TO_DELETE_IMPORTED_LABELS} from '../../constants/TicketQuickLinks'
import Loader from "@meridian/components/loader";
import {getProjectLabelsHandler, LabelStatus} from "../../requests/ProjectRequests";
import * as actionTypes from "../../store/actions/actions";
import Tag from "@meridian/components/tag";

const SHARED_LABELS_USER_GUIDE_URL = "https://w.amazon.com/bin/view/Legal/LegalLearningSystems/DataLens/UserGuides/Shared_Models/"

class ProjectImportedLabels extends React.Component {
    state = {
        unConfirmedLabelsToImport: [],
        isLabelLoading: false,
    }

    setLabelLoadingStateHandler = (value) => {
        this.setState({'isLabelLoading': value})
    }

    setUnConfirmedLabelsToImportHandler = (value) => {
        this.setState({'unConfirmedLabelsToImport': value})
    }

    componentDidMount = () => {
        this.props.getPreTrainedLabelsBundlesForUser();
    }

    isLabelAlreadyPresentInTheList = (labelsList, labelToBeAdded) => {
        return labelsList.some(label => label.labelId === labelToBeAdded.labelId)
    }

    filterLabelsNotImportedOrBeingImported = (labelsBundle) => {
        return labelsBundle.labelModels.filter(labelModel => {
            return !this.isLabelAlreadyPresentInTheList(this.props.importedLabels, labelModel) &&
                !this.isLabelAlreadyPresentInTheList(this.state.unConfirmedLabelsToImport, labelModel)
        })
    }

    doesBundleHaveModelsToBeImported = (labelsBundle) => {
        let labelsToImport = this.filterLabelsNotImportedOrBeingImported(labelsBundle)
        return labelsToImport.length > 0
    }

    onPreTrainedOptionChange = (newValue) => {
        if (this.props.labelsBundles) {
            let unConfirmedLabelsToImport = this.state.unConfirmedLabelsToImport
            let selectedLabelBundle = this.props.labelsBundles
                .filter((bundle) => {
                    return bundle.bundleId === newValue
                })
                .pop();
            let labelsNotAlreadyAdded = this.filterLabelsNotImportedOrBeingImported(selectedLabelBundle)
            unConfirmedLabelsToImport = unConfirmedLabelsToImport.concat(labelsNotAlreadyAdded)
            this.setUnConfirmedLabelsToImportHandler(unConfirmedLabelsToImport)
            if (labelsNotAlreadyAdded.length < selectedLabelBundle.labelModels.length) {
                this.props.showNotification("Some of the labels are already in your import list", 'warning')
            }
        }
    }

    removeUnconfirmedLabelRow = (importedLabel) => {
        let newUnConfirmedLabelsToImport = this.state.unConfirmedLabelsToImport
        let indexOfLabelToDelete = newUnConfirmedLabelsToImport.indexOf(importedLabel)
        newUnConfirmedLabelsToImport.splice(indexOfLabelToDelete, 1)
        this.setUnConfirmedLabelsToImportHandler(newUnConfirmedLabelsToImport)
    }

    onImportLabelsSuccess = () => {
        this.setLabelLoadingStateHandler(false)
        this.setUnConfirmedLabelsToImportHandler([])
    }

    onClickImportButton = (unConfirmedLabelsToImport) => {
        if (unConfirmedLabelsToImport) {
            this.setLabelLoadingStateHandler(true)
            let modelIds = unConfirmedLabelsToImport.map(labelModel => labelModel.modelId)
            this.props.importPreTrainedLabelModels(this.props.projectId, modelIds, this.onImportLabelsSuccess)
        }
    }

    /************************************** Render Elements ***********************************************/

    getPreTrainedBundlesDropDownElements = () => {
        if (!this.props.labelsBundles || this.props.labelsBundles.length == 0) {
            return []
        }
        return this.props.labelsBundles.filter((labelsBundle) => this.doesBundleHaveModelsToBeImported(labelsBundle))
                                       .map((labelsBundle) => (
                                           <SelectOption key={labelsBundle.bundleId}
                                                         value={labelsBundle.bundleId}
                                                         label={labelsBundle.bundleName + " - " + labelsBundle.labelModels[0].name}/>
                                       ))
    }

    getPreTrainedBundlesDropDown = () => {
        let preTrainedBundlesDropDownElements = this.getPreTrainedBundlesDropDownElements()
        var selectOptionPlaceholderMessage = "Select pretrained models to import"
        if (preTrainedBundlesDropDownElements.length === 0) {
            selectOptionPlaceholderMessage = "No more models available to import"
        }
        return (
            <Column>
                <Heading level={6} alignment='left' className='box-header'> Import pre-trained labels </Heading>
                <Box>
                  <Text type="b200"> {PRE_TRAIN_IMPORT_MODEL_DEFINITION} </Text>
                </Box>
                <Box>
                    <Select
                        onChange={(value) => {
                            this.onPreTrainedOptionChange(value)
                        }}
                        disabled={preTrainedBundlesDropDownElements.length === 0}
                        placeholder={selectOptionPlaceholderMessage}
                        prefix="Pretrained model"
                    >
                        {preTrainedBundlesDropDownElements}
                    </Select>
                </Box>
            </Column>
        )
    }

    getDeleteButtonUIForUnConfirmedLabel = (label) => {
        return (
            <Button type="icon"
                    onClick={(e) => this.removeUnconfirmedLabelRow(label)}>
                <Icon tokens={closeKnockoutTokens}/>
            </Button>
        )
    }

    getDeleteButtonUIForImportedLabel = (label) => {
        return (
            <Button type="icon" href={TT_LINK_TO_DELETE_IMPORTED_LABELS} target="_blank">
                <Icon tokens={closeKnockoutTokens}/>
            </Button>
        )
    }

    getLabelStatusTag = (label) => {
        switch (label.status) {
            case LabelStatus.CREATION_IN_PROGRESS:
                return (<Tag type="warning">Importing</Tag>)
            case LabelStatus.ERROR:
                return (<Tag type="error">Error</Tag>)
            case LabelStatus.AVAILABLE:
                return (<Tag type="success">Imported&nbsp;</Tag>)
        }
    }

    getTableRowsForGivenLabels = (labels, onDeleteButtonUI) => {
        if (!labels || labels.length == 0) {
            return []
        }

        return labels.map((label) => (
            <TableRow key={label.labelId}>
                <TableCell> {label.name} </TableCell>
                <TableCell> {LABEL_TYPE_MAPPING[label.type]} </TableCell>
                <TableCell> {label.description} </TableCell>
                <TableCell>
                    {label.moreInformationLink ?
                        <Link href={label.moreInformationLink} target="_blank">More Info</Link>
                        : <Link href={SHARED_LABELS_USER_GUIDE_URL} target="_blank">More Info</Link>}
                </TableCell>
                <TableCell>
                    {this.getLabelStatusTag(label)}
                    {onDeleteButtonUI(label)}
                </TableCell>
            </TableRow>
        ))
    }

    getUnconfirmedPreTrainedLabelsTable = () => {
        return (
            <Column>
                <Heading level={6} alignment='left' className='box-header'> Confirm labels to import: </Heading>
                <Box>
                    <Table
                        spacing="small"
                        headerRows={1}
                        showStripes={true}
                        showDividers={false}
                    >
                        <TableRow>
                            <TableCell> Name </TableCell>
                            <TableCell> Type </TableCell>
                            <TableCell> Description </TableCell>
                            <TableCell> More Information </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                        {this.getTableRowsForGivenLabels(this.state.unConfirmedLabelsToImport, this.getDeleteButtonUIForUnConfirmedLabel)}
                    </Table>
                </Box>
            </Column>
        )
    }

    getUnConfirmedLabelsSection = () => {
        return (
            <Box>
                <Box>
                    {this.getUnconfirmedPreTrainedLabelsTable()}
                </Box>
                <Box className="ImportWarningAlert">
                    <Alert type="warning" size="small">
                        {IMPORT_LABELS_CONFIRMATION_MESSAGE}
                    </Alert>
                </Box>
                <Row alignmentHorizontal="right" alignmentVertical="bottom" spacingInset="medium">
                    <Button
                        size="small"
                        type="primary"
                        disabled={this.state.isLabelLoading}
                        onClick={() => this.onClickImportButton(this.state.unConfirmedLabelsToImport)}>
                        Confirm and add
                        {
                            this.state.isLabelLoading ?
                                <Loader type="circular" size="small"/>
                                : null
                        }
                    </Button>
                </Row>
            </Box>
        )
    }

    getImportedLabelsTable = () => {
        return (
            <Column>
                <Heading level={6} alignment='left' className='box-header'> Imported pre-trained labels: </Heading>
                <Box>
                    <Table
                        spacing="small"
                        headerRows={1}
                        showStripes={true}
                        showDividers={false}
                    >
                        <TableRow>
                            <TableCell> Name </TableCell>
                            <TableCell> Type </TableCell>
                            <TableCell> Description </TableCell>
                            <TableCell> More Information </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                        {this.getTableRowsForGivenLabels(this.props.importedLabels, this.getDeleteButtonUIForImportedLabel)}
                    </Table>
                </Box>
            </Column>
        )
    }

    getAllElements = () => {
        return (
            <Column spacingInset="medium" type="outline" height = "100%">
                <Box>
                    {this.getPreTrainedBundlesDropDown()}
                </Box>
                {this.state.unConfirmedLabelsToImport && this.state.unConfirmedLabelsToImport.length > 0 ?
                    this.getUnConfirmedLabelsSection() : null
                }
                <Box>
                    {this.getImportedLabelsTable()}
                </Box>
            </Column>
        )
    }
    render () {
        let isPretrainedModelsEnabled = (this.props.labelsBundles && this.props.labelsBundles.length > 0)
        return (
            isPretrainedModelsEnabled ? this.getAllElements(): null
        )
    }
}

const mapStateToProps = state => {
    return {
        labelsBundles: state.preTrainedLabels.labelsBundles,
        projectId: state.project.projectId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        importPreTrainedLabelModels: (projectId, modelIds, onSuccessHandler) => dispatch(importPreTrainedLabelModels(projectId, modelIds, onSuccessHandler)),
        getProjectLabelsHandler: (projectId) => dispatch(getProjectLabelsHandler(projectId)),
        getPreTrainedLabelsBundlesForUser: () => dispatch(getPreTrainedLabelsBundlesForUser()),
        showNotification: (message, messageType) => dispatch({type: actionTypes.SHOW_NOTIFICATION, message: message, messageType: messageType})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectImportedLabels);
