import React from "react"
import { TerminationDateMetadata } from './TerminationDateMetadata'
import { DateTypeMetadata } from './DateMetadata'

export function AnnotationMetadata(props) {
    const annotation = props.annotation
    if (!annotation.metadata || Object.keys(annotation.metadata).length === 0) {
        return null
    }
    let metadata = null
    
    if (annotation.metadata.Type === 'RelativeTerminationDate') {
        metadata = <TerminationDateMetadata metadata={annotation.metadata} />
    } else if (annotation.textType === 'Date') {
        metadata = <DateTypeMetadata metadata={annotation.metadata} />
    }
    
    if (!metadata) {
        return null
    }
    return (
        <span>
            (
                {metadata}
            )
        </span>
    )
}