import React from "react"

export function DateTypeMetadata(props) {
    const metadata = props.metadata

    // TODO: Delete this if clause. This is for backwards compatible.
    if (!metadata.FormattedDate && metadata.Month) {
        return (
            metadata.Month + '/'
            + metadata.Day + '/'
            + metadata.Year
        )
    }
    return (
        metadata.FormattedDate.Month + '/'
        + metadata.FormattedDate.Day + '/'
        + metadata.FormattedDate.Year
    )
}