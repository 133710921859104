export const getImportedLabels = (labels) => {
    //Filters all labels where isImported is present and set to false
    if (labels) {
        return labels.filter((label) => isLabelImported(label));
    }
    return []
}

export const getUserCreatedLabels = (labels) => {
    //Filters all labels where isImported is not present or set to false
    if (labels) {
        return labels.filter((label) => !isLabelImported(label));
    }
    return []
}

export const getTrainableLabels = (labels) => {
    if (labels) {
        return labels.filter((label) => isLabelTrainable(label))
    }
    return []
}

export const isLabelImported = (label) => {
    return label.isImported !== undefined && label.isImported;
}

export const isLabelTrainable = (label) => {
    if (isLabelImported(label)) {
        return false;
    }
    else if (label.isTrainable === undefined || label.isTrainable === null) {
        return true;
    } else {
        return label.isTrainable;
    }
}

export const doesLabelQualifyForAutoAnnotation = (label) => {
    return isLabelImported(label) || !isLabelTrainable(label)
}