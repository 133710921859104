import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getProjectTestMetrics } from '../../requests/ProjectRequests'
import Box from "@meridian/components/box"
import Heading from "@meridian/components/heading"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import '../../assets/css/Project.css'
import Text from "@meridian/components/text"
import * as actionTypes from '../../store/actions/actions'
import { TEST_METRICS_FAILURE } from '../../constants/Strings'
import Button from "@meridian/components/button"
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import helpTokens from '@meridian/tokens/base/icon/help'
import Icon from "@meridian/components/icon"
import Tooltip from "@meridian/components/tooltip"
import { METRICS_PRECISION_TOOLTIP, METRICS_RECALL_TOOLTIP, METRICS_LABEL_PERFORMANCE_TOOLTIP } from '../../constants/Strings'


class ProjectTestMetrics extends Component {
    state = {
        overallPerformanceMetrics: {},
        labelsPerformanceMetrics: [],
        showDetailedSummary: true,
    }

    componentDidMount = () => {
        this.props.getProjectTestMetrics(this.setProjectTestMetricsHandler, this.props.projectId, this.props.currentIterationNumber)
    }

    setOverallPerformanceMetrics = (val) => {
        this.setState({overallPerformanceMetrics : val})
    }

    setLabelsPerformanceMetrics = (val) => {
        this.setState({labelsPerformanceMetrics : val})
    }

    getOverallPerformanceMetrics = (userTestMetrics) => {
        if (Array.isArray(userTestMetrics)) {
            if (userTestMetrics[0].name != "overall"){
                console.log('Overall test metrics not found: ' + err);
                this.props.showNotification(TEST_METRICS_FAILURE, 'warning')
            } else {
                return {
                    precision: userTestMetrics[0]['precision_percentage'],
                    recall: userTestMetrics[0]['recall_percentage'],
                    f1: userTestMetrics[0]['f1_percentage']
                }
            }
        } else {
            return {
                precision: userTestMetrics.overallPerformanceMetrics.precisionPercentage,
                recall: userTestMetrics.overallPerformanceMetrics.recallPercentage,
                f1: userTestMetrics.overallPerformanceMetrics.f1Percentage,
            }
        }
    }

    getLabelsPerformanceMetrics = (userTestMetrics) => {
        if (Array.isArray(userTestMetrics)) {
            return userTestMetrics.slice(1).map(labelMetrics => {
                return {
                    name: labelMetrics['name'],
                    precision: labelMetrics['precision_percentage'],
                    recall: labelMetrics['recall_percentage'],
                    f1: labelMetrics['f1_percentage']
                }
            });

        } else {
            return userTestMetrics.labelsPerformanceMetrics.map(labelMetrics => {
                return {
                    name: labelMetrics.labelName,
                    precision: labelMetrics.performanceMetrics.precisionPercentage,
                    recall: labelMetrics.performanceMetrics.recallPercentage,
                    f1: labelMetrics.performanceMetrics.f1Percentage,
                }
            });
        }
    }

    setProjectTestMetricsHandler = (userTestMetrics) => {
        let overallPerformanceMetrics = this.getOverallPerformanceMetrics(userTestMetrics)
        let labelsPerformanceMetrics = this.getLabelsPerformanceMetrics(userTestMetrics);
        this.setOverallPerformanceMetrics(overallPerformanceMetrics);
        this.setLabelsPerformanceMetrics(labelsPerformanceMetrics);
        this.props.setDataLoadedForTestMetrics(true)
    }

    flipDetailedSummary = () => {
        { this.state.showDetailedSummary ? this.setState({showDetailedSummary: false}) : this.setState({showDetailedSummary: true}) }
    }
    
    createLabelListElement = () => {
        return this.state.labelsPerformanceMetrics
            .map((label) => (
                <TableRow key={label.name}>
                    <TableCell>
                        { label.name }
                    </TableCell>
                    { this.state.showDetailedSummary ?
                        [<TableCell>
                            { label.precision }
                        </TableCell>,
                            <TableCell>
                                { label.recall }
                            </TableCell>]
                        : null }
                    <TableCell>
                        { label.f1 }
                    </TableCell>
                </TableRow>));
    }

    render() {
        const overallTestMetrics = this.state.overallPerformanceMetrics;
        const listOfLabels = this.createLabelListElement();

        if (overallTestMetrics == undefined){
          return null
        }

        return (
            <Box className="MetricsBox"
              type="outline"
              spacingInset="small"
              minHeight="30%"
              width="100%"
            >
              <Heading level={4} type="h500" alignment='left' className='box-title'>
                  <Text>
                      Metrics summary
                      <Button size="small" type="link" rel="noreferrer noopener" target="_blank" href="https://w.amazon.com/bin/view/Legal/LegalLearningSystems/DataLens/UserGuides/Metrics">
                          <Icon tokens={helpTokens} />
                      </Button>
                  </Text>
              </Heading>
              <div>
                <Text alignment='left' className='MetricsText'>
                    Model performance: { overallTestMetrics.f1 } %.
                </Text>
                <Row alignmentHorizontal="right" alignmentVertical="top" spacing="medium" spacingInset="small">
                    <Column heights={"fill"} spacing="none">
                        <Button size="small" type="tertiary" onClick = {() => this.flipDetailedSummary()}>
                            { this.state.showDetailedSummary ? "Hide detailed summary" : "Show detailed summary" }
                        </Button>
                    </Column>
                </Row>
                <Table
                  className="MetricsTable"
                  spacing="medium"
                  headerRows={1}
                  showStripes={true}
                  showDividers={false}
                >
                  <TableRow key='projectMetricsTableHeader'>
                    <TableCell>
                        Label name
                    </TableCell>
                    { this.state.showDetailedSummary ?
                        [<TableCell>
                            Precision (%)
                            <Tooltip position="bottom" title={METRICS_PRECISION_TOOLTIP}>
                              <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                            </Tooltip>
                        </TableCell>,
                        <TableCell>
                            Recall (%)
                            <Tooltip position="bottom" title={METRICS_RECALL_TOOLTIP}>
                              <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                            </Tooltip>
                        </TableCell>]
                    : null }
                    <TableCell>
                        Label performance (%)
                        <Tooltip position="bottom" title={METRICS_LABEL_PERFORMANCE_TOOLTIP}>
                          <Icon className="HelpTooltip" tokens={helpTokens}></Icon>
                        </Tooltip>
                    </TableCell>
                  </TableRow>
                  { listOfLabels }
                </Table>
              </div>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.projectId,
        currentIterationNumber: state.project.currentIterationNumber,
        projectState: state.project.state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showNotification: (message, messageType) => dispatch({type: actionTypes.SHOW_NOTIFICATION, message: message, messageType: messageType}),
        getProjectTestMetrics: (labelsHandler, projectId, currentIterationNumber) => dispatch(getProjectTestMetrics(labelsHandler, projectId, currentIterationNumber)),
        setDataLoadedForTestMetrics: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_TEST_METRICS, status: status})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTestMetrics);

