import apiCall from '../utils/ApiCall';
import * as actionTypes from '../store/actions/actions';
import { EXPORT_PROJECT_MESSAGE, EXPORT_PROJECT_FAILURE } from '../constants/Strings'

export const exportProjectMetadataHandler = (project_id, data_source_request) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/export', {projectId: project_id}, 'GET', '', {}, {data_source: data_source_request}).then((data) => {
            dispatch(actionTypes.showNotification(EXPORT_PROJECT_MESSAGE, 'success'));
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(EXPORT_PROJECT_FAILURE, 'error'))
        });
    }
}
