import * as actionTypes from '../store/actions/actions';
import apiCall from '../utils/ApiCall';
import {
    GET_ANNOTATION_SUMMARY_FAILURE,
    GET_PROJECT_DETAILS_FAILURE,
    GET_PROJECT_DOCUMENTS_FAILURE,
    GET_PROJECT_LABELS_FAILURE,
    GET_PROJECT_MODEL_TOKEN_FAILURE,
    GET_PROJECT_TRAININGS_FAILURE,
    GET_PROJECT_USERS_FAILURE,
    LAUNCH_PROJECT_MODEL_FAILURE,
    LAUNCH_PROJECT_SUCCESS_MESSAGE,
    PUT_PROJECT_LABEL_FAILURE,
    PUT_PROJECT_USER_FAILURE,
    START_TRAINING_FAILURE,
    START_TRAINING_SUCCESS,
    TEST_METRICS_FAILURE
} from '../constants/Strings'

export const LabelStatus = {
    AVAILABLE: "AVAILABLE",
    ERROR: "ERROR",
    CREATION_IN_PROGRESS: "CREATION_IN_PROGRESS",
    DELETION_IN_PROGRESS: "DELETION_IN_PROGRESS"
}

export const getProjectLabelsHandler = (project_id) => {
    var labels = [];

    return (dispatch) => {
        return apiCall('/project/{projectId}/labels', {projectId: project_id}, 'GET').then((res) => {
            res.forEach(function(item) {
                let label = {};
                label.name = item.Name
                label.description = item.Description
                label.type = item.Type
                label.labelId = item.LabelId
                label.isImported = item.IsImported
                label.moreInformationLink = item.MoreInformationLink
                label.status = item.Status
                label.isTrainable = item.IsTrainable
                label.regex = item.Regex
                labels.push(label)
            });
            dispatch(actionTypes.addProjectLabels(labels))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_PROJECT_LABELS_FAILURE, 'error'))
        })
        .finally(() => {
            dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PROJECT_LABELS, status: true});
        })
    }
}

export const getProjectDocumentsHandler = (project_id) => {
    let listOfFiles = [];

    return (dispatch) => {
        apiCall('/project/{projectId}/documents', {projectId: project_id}, 'GET').then((res) => {
            res.forEach(function(item) {
                let file = {};
                file.filePath = item.ParsedFilePath
                file.documentName = item.DocumentName
                file.state = item.State
                file.documentId = item.DocumentId
                listOfFiles.push(file)
            });
            dispatch(actionTypes.addProjectDocuments(listOfFiles))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_PROJECT_DOCUMENTS_FAILURE, 'error'))
        });
    }
}

export const getProjectDetails = (project_id) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/', {projectId: project_id}, 'GET').then((res) => {
            let project = {
                name: res.Name,
                description: res.Description,
                state: res.State,
                owner: res.Owner,
                projectId: res.ProjectId,
                allowRegex: res.AllowRegex,
                allowRelationships: res.AllowRelationships,
                currentIterationNumber: res.CurrentIterationNumber
            }
            dispatch(actionTypes.addProjectDetails(project))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_PROJECT_DETAILS_FAILURE, 'error'))
        });
    }
}

export const putNewProjectMeta = (setProjectIdHandler, projectMeta) => {

    let param = {name: projectMeta.name, description: projectMeta.desc}

    apiCall('/project/', {}, 'POST', param).then((res) => {
        setProjectIdHandler(res.ProjectId)
    })
}

export const getProjectUsers = (project_id) => {
    let users = []

    return (dispatch) => {
        apiCall('/project/{projectId}/users', {projectId: project_id}, 'GET').then((res) => {
            res.forEach(function(item) {
                let user = {};
                user.alias = item.Alias
                user.access = item.access
                users.push(user)
            });
            dispatch(actionTypes.addProjectUsers(users))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_PROJECT_USERS_FAILURE, 'error'))
        })
        .finally(() => {
            dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PROJECT_USERS, status: true});
        })
    }

}

export const putProjectUser = (user_info) => {
    let param = {
        user_id: user_info.user_name,
        access: user_info.user_type
    }

    return (dispatch) => {
        apiCall('/project/{projectId}/users', {projectId: user_info.project_id}, 'POST', param).then((res) => {
            let user = {alias: param.user_id, access: param.access}
            dispatch(actionTypes.addNewUser(user))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(PUT_PROJECT_USER_FAILURE, 'error'))
        });
    }
}

export const putProjectLabel = (label_info) => {
    let param = {
        label_name: label_info.label_name,
        label_type: label_info.label_type,
        description: label_info.label_desc,
        regex: label_info.regex
    }

    return (dispatch) => {
        apiCall('/project/{projectId}/labels',{projectId: label_info.project_id}, 'POST', param).then((res) => {
            let label = {
                name: param.label_name,
                type: param.label_type,
                description: param.description,
                labelId: res.LabelId,
                regex: param.regex
            }
            dispatch(actionTypes.addNewLabel(label))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(PUT_PROJECT_LABEL_FAILURE, 'error'))
        });
    }
}

export const getProjectsForUser = (allProjectsHandler, username) => {
    apiCall('/user/{userId}/projects', {userId: username}, 'GET').then((res) => {
        allProjectsHandler(res)
    })
}

export const getProjectTestMetrics = (allLabelsHandler, project_id, iteration_num) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/test_metrics', {projectId: project_id}, 'GET', '', {}, {iteration_number: iteration_num}).then((res) => {
            allLabelsHandler(res)
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(TEST_METRICS_FAILURE, 'error'))
        });
    }
}

export const startTraining = (project_id) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/start_training_step_function', {projectId: project_id}, 'POST').then((res) => {
            dispatch(actionTypes.showNotification(START_TRAINING_SUCCESS, 'success'))
            setTimeout(()=> window.location.reload(), 3000)
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(START_TRAINING_FAILURE, 'error'))
        });
    }
}

export const backToCollection = (project_id) => {
    let param = {state: "COLLECTION"}

    return (dispatch) => {
        apiCall('/project/{projectId}/state', {projectId: project_id}, 'POST', param).then((res) => {
            setTimeout(()=> window.location.reload(), 500)
        })
        .catch((err) => {
            console.log(err);
        });
    }
}

export const getProjectAnnotationSummary = (allLabelsHandler, project_id) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/get_annotation_summary', {projectId: project_id}, 'GET').then((res) => {
            let resultMap = {}
            resultMap["annotationSummary"] = res["annotationSummary"].map(label => {
                return {
                    labelId: label.labelId,
                    name: label.labelName,
                    isImported: label.isImported,
                    samples: label.numberOfSamples,
                    recommended: label.recommendedNumberOfSamples
                }
            })
            resultMap["numDocsPerLabelType"] = res["numDocsPerLabelType"]

            allLabelsHandler(resultMap)
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_ANNOTATION_SUMMARY_FAILURE, 'error'))
        });
    }
}

export const validateTrainingSampleCounts = (allLabelsHandler, project_id) => {
    return (dispatch) => {
        const generateLabelsWithInsufficientSamplesHandler = (labels) => {
            try {
                if (labels["annotationSummary"] == ""){
                    throw "No labels found for project."
                }
                let labels_mapped = labels["annotationSummary"].filter(label => {
                    return label.samples < label.recommended
                })
                allLabelsHandler(labels_mapped)
            }
            catch(err) {
                console.log(err);
                dispatch(actionTypes.showNotification(START_TRAINING_FAILURE, 'error'))
            }
        }
        dispatch(getProjectAnnotationSummary(generateLabelsWithInsufficientSamplesHandler, project_id))
    }
}

export const getProjectSuccessfulTrainingIterations = (project_id) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/training', {projectId: project_id}, 'GET').then((res) => {
            dispatch(actionTypes.addProjectSuccessfulTrainingIterations(res))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_PROJECT_TRAININGS_FAILURE, 'error'))
        })
    }
}

export const launchProjectModel = (project_id) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/launch', {projectId: project_id}, 'POST').then((res) => {
            console.log('launch', res)
            dispatch(actionTypes.showNotification(LAUNCH_PROJECT_SUCCESS_MESSAGE, 'success'))
            setTimeout(()=> window.location.reload(), 3000)
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(LAUNCH_PROJECT_MODEL_FAILURE, 'error'))
        });
    }
}

export const getProjectModelToken = (project_id, onSuccessHandler) => {
    return (dispatch) => {
        apiCall('/project/{projectId}/projectToken', {projectId: project_id}, 'GET').then((res) => {
            let projectModelToken = res['ProjectToken']
            onSuccessHandler(projectModelToken)
            dispatch({type: actionTypes.ADD_PROJECT_MODEL_TOKEN, projectModelToken});
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_PROJECT_MODEL_TOKEN_FAILURE, 'error'))
        });
    }
}