import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileContent from './FileContent';
import MarkDocumentCompleted from './MarkDocumentCompleted';
import '../../assets/css/Document.css';
import { popupMoveToCursor } from './HighlightAnnotation';
import { DOCUMENT_STATES } from '../../utils/Constants';
import { getProjectState, PROJECT_STATES_ORDER } from '../../constants/Project';

const ANNOTATION_DELIMITER = '_';
const LABEL_EMPTY = 'LableNotSelected';

class DocumentText extends Component {
  state = {
    selectedText: {
      start: 0,
      end: 0,
      text: '',
    },
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.documentId != prevProps.documentId) {
      this.refs.document.scrollIntoView({ behavior: 'auto' });
    }
  };

  trimWhiteSpacesFromSelectionAndGetNewOffset = (selectedText) => {
    const text = selectedText.text;
    let start = selectedText.start;
    let end = selectedText.end;

    start = start + (text.length - text.trimStart().length);
    end = end - (text.length - text.trimEnd().length);
    return { start: start, end: end, text: text.trim() };
  };

  getSelectionCharacterOffsetWithin = (event, element) => {
    // Selection and dropdown capability is added only when the getSelectionTextHandler is passed in
    if (!this.props.getSelectionTextHandler) {
      return;
    }

    if (getProjectState(this.props.projectState) === PROJECT_STATES_ORDER.LAUNCHED) {
      this.props.getSelectionTextHandler(null);
      return;
    }

    const sel = window.getSelection();
    const selectedText = Object.assign({}, this.state.selectedText);
    if (sel.type === 'Range') {
      selectedText.text = sel.toString();
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      selectedText.start = preCaretRange.toString().length;
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      selectedText.end = preCaretRange.toString().length;

      const trimmedOffsets = this.trimWhiteSpacesFromSelectionAndGetNewOffset(selectedText);
      selectedText.start = trimmedOffsets.start;
      selectedText.end = trimmedOffsets.end;
      selectedText.text = trimmedOffsets.text;
      selectedText.annotationId = LABEL_EMPTY + ANNOTATION_DELIMITER + selectedText.start + ANNOTATION_DELIMITER + selectedText.end;

      this.props.getSelectionTextHandler(selectedText);
      popupMoveToCursor(event);
    } else {
      this.props.getSelectionTextHandler(null);
    }
  };

  render() {
    return (
      <div
        readOnly
        className="DocumentText"
        ref="document"
        onMouseUp={(event) => this.getSelectionCharacterOffsetWithin(event, this.refs.document)}
        onKeyUp={(event) => this.getSelectionCharacterOffsetWithin(event, this.refs.document)}
      >
        <FileContent documentId={this.props.documentId} projectId={this.props.projectId} />
        {this.props.documentState === DOCUMENT_STATES.PARSED ? <MarkDocumentCompleted projectId={this.props.projectId} documentId={this.props.documentId} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documentState: state.currDocument.documentState,
    projectState: state.project.state,
  };
};

export default connect(mapStateToProps, null)(DocumentText);
