import React from 'react'
import { connect } from 'react-redux'
import ProjectTestMetrics from './ProjectTestMetrics'
import AnnotationSummary from './AnnotationSummary'
import ProjectAnnotationModal from './Modals/ProjectAnnotationModal'
import BackToCollectionModal from './Modals/BackToCollectionModal'
import Workflow from '../Workflow/Workflow'
import Box from "@meridian/components/box"
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import Button from "@meridian/components/button"
import Text from "@meridian/components/text"
import Tooltip from "@meridian/components/tooltip"
import {getProjectState, PROJECT_STATES_ORDER} from '../../constants/Project'
import {
    TRAINING_IN_PROGRESS_MESSAGE,
    MINIMUM_DOCUMENTS_REQUIRED_FOR_TRAINING,
    LAUNCHING_IN_PROGRESS_MESSAGE,
    ATLEAST_TWO_DOCS_REQUIRED_PER_LABEL_TYPE_FOR_TRAINING
} from '../../constants/Strings'
import { startTraining,  validateTrainingSampleCounts, launchProjectModel } from '../../requests/ProjectRequests'
import Alert from "@meridian/components/alert";
import Heading from "@meridian/components/heading";
import {getExternalClients} from "../../requests/ClientRequests";
import LinkButton from "../../utils/LinkButton";

class ProjectHome extends React.Component{
    constructor (props){
        super(props)
        this.props.getExternalClients()
    }
    state = {
        labelList: [],
        modalOpen: false,
        modalOpenForBackToCollection: false,
        launchButtonClicked: false,
        trainingButtonClicked: false,
        areNumDocsAboveThresholdPerLabelType: false
    }

    setLaunchButtonClicked = (val) => {
        this.setState({launchButtonClicked: val})
    }

    setTrainingButtonClicked = (val) => {
        this.setState({trainingButtonClicked: val})
    }

    changeModalOpen = (val) => {
        this.setState({modalOpen: val})
    }

    changeModalOpenForBackToCollection = (val) => {
        this.setState({modalOpenForBackToCollection: val})
    }

    setAreNumDocsAboveThresholdPerLabelType = (val) => {
        this.setState({areNumDocsAboveThresholdPerLabelType: val})
    }

    checkProjectAnnotationsForInitiateTraining = () => {
        this.setTrainingButtonClicked(true)
        this.props.validateTrainingSampleCounts(this.checkLabelsWithInsufficientSamplesHandler, this.props.projectId)
    }

    launchProjectModel = () => {
        this.setLaunchButtonClicked(true)
        this.props.launchProjectModel(this.props.projectId)
    }

    checkLabelsWithInsufficientSamplesHandler = (labels) => {
        try {
            if (labels.length === 0){
                this.props.startTraining(this.props.projectId)
                return null
            }

            this.setState({labelList: labels})
            this.setState({modalOpen: true})
        }
        catch(err) {
            console.log('Labels with insufficient samples not fetched: ' + err);
        }
    }

    confirmRequestForBackToCollection = () => {
        this.setState({modalOpenForBackToCollection: true})
    }

    showInitiateTraining = () => {
        if (getProjectState(this.props.projectState) !== PROJECT_STATES_ORDER.COLLECTION) {
            return null
        }

        if (this.props.projectDocuments.length < 2) {
            return  (
                <Tooltip position="top" title={MINIMUM_DOCUMENTS_REQUIRED_FOR_TRAINING}>
                    <div>
                        <Button size="medium" type="primary" disabled={true}>
                            Start Training
                        </Button>
                    </div>
                </Tooltip>
            )
        }

        if (!this.state.areNumDocsAboveThresholdPerLabelType) {
            return  (
                <Tooltip position="top" title={ATLEAST_TWO_DOCS_REQUIRED_PER_LABEL_TYPE_FOR_TRAINING}>
                    <div>
                        <Button size="medium" type="primary" disabled={true}>
                            Start Training
                        </Button>
                    </div>
                </Tooltip>
            )
        }

        return  (
            <Button size="medium"
                    type="primary"
                    disabled={this.state.trainingButtonClicked}
                    onClick = {() => this.checkProjectAnnotationsForInitiateTraining()}>
            Start Training
            </Button>
        )
    }

    showBackToCollection = () => {
        if (getProjectState(this.props.projectState) == PROJECT_STATES_ORDER.VERIFICATION){
            return  <Button size="medium"
                            type="primary"
                            onClick = {() => this.confirmRequestForBackToCollection()}
            >
                Back to collection
            </Button>
        }
    }

    showLaunchedModel = () => {
        if (getProjectState(this.props.projectState) == PROJECT_STATES_ORDER.VERIFICATION){
            return  <Button size="medium" type="primary"
                            disabled={this.state.launchButtonClicked}
                            onClick = {() => this.launchProjectModel()}>
                Launch Model
            </Button>
        }
    }

    getActionTileForExternalClients = () => {
        return (
            <Box type="outline" spacingInset="large" height="100%">
                <Column heights={["fit", "fill", "fit"]} height="100%">
                    <Heading level={4} type="h500">
                        Integrate
                    </Heading>
                    <Text type="b300" breakWord={true}>
                        Share your model with other applications.
                    </Text>
                    <LinkButton type="tertiary" disabled={false} href={'/manage/' + this.props.projectId + "#applications"}>
                        Configure
                    </LinkButton>
                </Column>
            </Box>
        )
    }

    getActionTileForRetraining = () => {
        return (
            <Box type="outline" spacingInset="large" height="100%"> 
                <Column heights={["fit", "fill", "fit"]} height="100%">
                    <Heading level={4} type="h500">
                        Retrain
                    </Heading>
                    <Text type="b300" breakWord={true}>
                        Improve your model performance or add new labels.
                    </Text>
                    <Button type="tertiary"
                            onClick={() => this.confirmRequestForBackToCollection()}>
                        Retrain
                    </Button>
                </Column>
            </Box>
        )
    }

    getActionTileForSharingModels = () => {
        return (
            <Box type="outline" spacingInset="large" height="100%">
                <Column heights={["fit", "fill", "fit"]} height="100%">
                    <Heading level={4} type="h500">
                        Share
                    </Heading>
                    <Text type="b300" breakWord={true}>
                        Share your trained label with other DataLens users.
                    </Text>
                    <LinkButton type="tertiary" disabled={false} href={'/manage/' + this.props.projectId + "#share"}>
                        Configure
                    </LinkButton>
                </Column>
            </Box>
        )
    }
    getLaunchedActionTiles = () => {
        return (
            <Row width="100%" widths="fill" wrap="down" alignmentVertical="stretch">
                {this.props.clients.length !== 0 ? this.getActionTileForExternalClients():null}
                {this.getActionTileForRetraining()}
                {this.getActionTileForSharingModels()}
            </Row>
        )
    }

    /**
     * This set of buttons should also live along with other state specific methods that we have created
     */
    getProjectStateButtons = () => {
        return  <Row alignmentHorizontal="right" alignmentVertical="bottom" spacingInset="medium">
            {this.showInitiateTraining()}
            {this.showBackToCollection()}
            {this.showLaunchedModel()}
        </Row>
    }

    getCollectionComponents = () => {
        return (
            <Column height="100%" width="100%">
                {this.state.modalOpen ? <ProjectAnnotationModal labels = {this.state.labelList} modalOpen = {this.state.modalOpen} changeModalState = {this.changeModalOpen} setTrainingButtonClickedHandler = {this.setTrainingButtonClicked} />:null}
                <AnnotationSummary projectId={this.props.projectId} setAreNumDocsAboveThresholdPerLabelTypeHandler = {this.setAreNumDocsAboveThresholdPerLabelType}/>
            </Column>
        )
    }

    getInTrainingComponents = () => {
        return (
            <Column height="100%" width="100%">
                <Alert>
                    <Text type="b300">
                        {TRAINING_IN_PROGRESS_MESSAGE}
                    </Text>
                </Alert>
                <AnnotationSummary projectId={this.props.projectId} setAreNumDocsAboveThresholdPerLabelTypeHandler = {this.setAreNumDocsAboveThresholdPerLabelType}/>
            </Column>
        )
    }

    getVerificationComponents = () => {
        return (
            <Column height="80%" width="100%">
                <AnnotationSummary projectId={this.props.projectId} setAreNumDocsAboveThresholdPerLabelTypeHandler = {this.setAreNumDocsAboveThresholdPerLabelType}/>
                <ProjectTestMetrics {...this.props} />
                {this.state.modalOpenForBackToCollection ? <BackToCollectionModal modalOpen = {this.state.modalOpenForBackToCollection} changeModalState = {this.changeModalOpenForBackToCollection} /> : null}
            </Column>
        )
    }

    getLaunchingComponents = () => {
        return (
            <Column height="80%" width="100%">
                <Alert>
                    <Text type="b300">
                        {LAUNCHING_IN_PROGRESS_MESSAGE}
                    </Text>
                </Alert>
                <ProjectTestMetrics {...this.props} />
            </Column>
        )
    }

    getLaunchedComponents = () => {
        return (
            <Column height="90%" width="100%">
                <ProjectTestMetrics {...this.props} />
                {this.state.modalOpenForBackToCollection ? <BackToCollectionModal modalOpen = {this.state.modalOpenForBackToCollection} changeModalState = {this.changeModalOpenForBackToCollection} /> : null}
                {this.getLaunchedActionTiles()}
            </Column>
        )
    }

    render() {
        let stateSpecificContentMapping = {
            [PROJECT_STATES_ORDER.COLLECTION]: this.getCollectionComponents(),
            [PROJECT_STATES_ORDER.TRAINING]: this.getInTrainingComponents(),
            [PROJECT_STATES_ORDER.VERIFICATION]: this.getVerificationComponents(),
            [PROJECT_STATES_ORDER.LAUNCHING]: this.getLaunchingComponents(),
            [PROJECT_STATES_ORDER.LAUNCHED]: this.getLaunchedComponents()
        };
        let projectState = getProjectState(this.props.projectState)
        return (
            <Box width='100vw'>
                <Row height='calc(90vh - 50px)' widths={"fill"} wrap="down" alignmentHorizontal="center" alignmentVertical="stretch" spacing="small" spacingInset="small" className='projectRow'>
                    <Column
                        height= "100%"
                        heights={"fit"}
                        maxWidth= "90%"
                        alignmentHorizontal= "center"
                        alignmentVertical= "top"
                    >
                        <Workflow projectState= {projectState} />
                        {stateSpecificContentMapping[projectState]}
                    </Column>
                </Row>

                {this.getProjectStateButtons()}
            </Box>
        );
    }
}



const mapStateToProps = state => {
    return {
        projectState: state.project.state,
        projectId: state.project.projectId,
        clients: state.client.externalClients,
        currentIterationNumber: state.project.currentIterationNumber,
        projectDocuments: state.project.listOfFiles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getExternalClients: () => dispatch(getExternalClients()),
        startTraining: (projectId) => dispatch(startTraining(projectId)),
        validateTrainingSampleCounts: (labelsHandler, projectId) =>
            dispatch(validateTrainingSampleCounts(labelsHandler, projectId)),
        launchProjectModel: (projectId) => dispatch(launchProjectModel(projectId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHome);