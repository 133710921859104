import React from 'react';
import { RELATIONSHIP_TERM_ACTIONS } from '../../../constants/RelationshipTermActions'
import Tag from "@meridian/components/tag"

export function AnnotationRelationshipActionTag(props) {
    const NO_STATUS_TYPE = 'none'
    const getStatusType = (action) => {
        if (action === RELATIONSHIP_TERM_ACTIONS.ADDED) {
            return 'theme'
        } else if (action === RELATIONSHIP_TERM_ACTIONS.UPDATED) {
            return 'theme'
        } else if (action === RELATIONSHIP_TERM_ACTIONS.DELETED) {
            return 'error'
        } else {
            return NO_STATUS_TYPE
        }
    }

    let statusType = getStatusType(props.relationshipTermAction)
    if (statusType === NO_STATUS_TYPE) {
        return null
    }
    return (
        <Tag type={statusType}> {RELATIONSHIP_TERM_ACTIONS[props.relationshipTermAction]} </Tag>
    )
}
