import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from "@meridian/components/alert"
import * as actionTypes from '../../store/actions/actions';

class Notification extends Component {

    componentDidUpdate = (prevProps) => {
      if (this.props.message !== '' && this.props.message !== prevProps.message) {
        setTimeout(() => this.props.clearNotification(), 6000)
      }
    }

    clear = () => {
      this.props.clearNotification()
    }

    render() {
        if (this.props.message){
          var errorType = this.props.messageType

          return (
              <div className="NotificationBar">
                  <Alert
                    closeLabel = "Dismiss"
                    onClose={() => {this.clear()}}
                    type={errorType}
                    size="medium"
                    toast="true"
                  >
                   {this.props.message}
                  </Alert>
              </div>
          )
        }
        return null
    }
}

const mapStateToProps = state => {
    return {
        message: state.notification.message,
        messageType: state.notification.messageType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearNotification: () => dispatch(() => {dispatch({type: actionTypes.CLEAR_NOTIFICATION})}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);