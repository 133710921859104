/**
 * Concept taken from this site
 * https://learnwithparam.com/blog/how-to-group-by-array-of-objects-using-a-key/
 */

export const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        return result;
    }, {});
};