import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import * as actionTypes from "../../store/actions/actions"
import { MetricPageLatencyTimer } from "../../utils/MetricPageLatencyTimer"
import { CLIENT_METRICS_METHOD_NAMES } from "../../utils/Constants"
import {getProjectState, PROJECT_STATES_ORDER} from '../../constants/Project'

class ProjectDocumentPageLatency extends Component {
    metricPageLatencyTimerForDocumentPage = new MetricPageLatencyTimer()
    
    componentDidUpdate (prevProps) {
        if ((prevProps.documentName !== this.props.documentName) && (this.props.documentName !== '')) {
          this.metricPageLatencyTimerForDocumentPage = new MetricPageLatencyTimer()
          this.props.setDataLoadedForMetadata(false)
          this.props.setDataLoadedForDocumentText(false)
        }

        if ((this.props.dataLoadedForDocument) && (this.props.dataLoadedForMetadata)) {
            //TODO: This is wrong. we should do a equality check instead of >= check
          if (getProjectState(this.props.projectState) >= PROJECT_STATES_ORDER.VERIFICATION) {
            this.metricPageLatencyTimerForDocumentPage.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.DOCUMENTVERIFICATIONPAGE)
          } else {
            this.metricPageLatencyTimerForDocumentPage.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.DOCUMENTCOLLECTIONPAGE)
          }
          // To enable latency to be published for every document
          this.props.setDataLoadedForMetadata(false)
          this.props.setDataLoadedForDocumentText(false)
        }
    }

    render() {
        return null
    }

}

const mapStateToProps = state => {
    return {
        projectState: state.project.state,
        projectName: state.project.projectName,
        documentName: state.currDocument.documentName,
        dataLoadedForDocument: state.apiDataLoaded.dataLoadedForDocument,
        dataLoadedForMetadata: state.apiDataLoaded.dataLoadedForMetadata,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDataLoadedForDocumentText: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_DOCUMENT_TEXT, status: status}),
        setDataLoadedForMetadata: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_METADATA, status: status}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocumentPageLatency);    