import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../assets/css/Header.css'
import User from '../Authenticate/User'
import ProjectStatusTag from '../Project/ProjectStatusTag'
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import Heading from "@meridian/components/heading"
import Theme from "@meridian/components/theme"
import blueDarkTokens from "@meridian/tokens/theme/blue-dark"
import Button from "@meridian/components/button"
import helpIcon from '@meridian/tokens/base/icon/help'
import Menu, { MenuItem } from "@meridian/components/menu"
import { isAdminAndToggleOn } from '../../utils/AdminAuth';

class Header extends React.Component{
    state = {
        menuOpen: false,
        isAdminToggleSet: isAdminAndToggleOn(this.props.auth.signInUserSession.idToken.payload)
    }

    setIsAdminToggleSet = (currentToggleState) => {
        this.setState({isAdminToggleSet: currentToggleState})
    }

    render() {
        return (
          <header>
              <nav>
                <Row widths={["grid-3", "fill", "grid-3"]} spacing="none">
                  <Column className="DataLensLogo">
                      {this.state.isAdminToggleSet ?
                          <Link to='/'><img src= {require('../../assets/images/admin_logo.png')}  alt="Amazon DataLens"/></Link>
                          :
                          <Link to='/'><img src= {require('../../assets/images/logo.png')}  alt="Amazon DataLens"/></Link>
                      }
                  </Column>

                  <Theme tokens={blueDarkTokens}>
                    <Column className="ProjectNameColumn">
                      <Row className="ProjectNameHeader" spacing="none" alignmentHorizontal="center">
                        <Link to={'/verify/' + this.props.projectId}>
                          <Heading level={1} type="h100">
                            {this.props.projectName}
                          </Heading>
                        </Link>
                      </Row>
                    </Column>
                  </Theme>

                  <Column>
                    <Row alignmentHorizontal="right" spacing="small" spacingInset="none" className="NavActions">
                      <ProjectStatusTag projectState={this.props.projectState} />

                      {this.props.projectId !== "" ?
                        <Button className="ManageProjectButton" size="small" type="primary" disabled={false}>
                          <Link to={'/manage/' + this.props.projectId + "#permissions"}> Manage project </Link>
                        </Button>
                      : null
                      }

                      <Theme tokens={blueDarkTokens}>
                        <Button type="icon" iconTokens={helpIcon} label="FAQ" ref={node => this.buttonRef = node} onClick={() => this.setState({menuOpen: true})} > Help </Button>
                      </Theme>
                      <Menu
                        anchorNode={this.buttonRef}
                        open={this.state.menuOpen}
                        position="bottom"
                        onClose={() => this.setState({ menuOpen: false })}
                        minWidth="120px"
                      >
                        <MenuItem rel="noreferrer noopener" target="_blank" href="https://w.amazon.com/bin/view/Legal/LegalLearningSystems/DataLens/UserGuides/">User Guide</MenuItem>
                        <MenuItem rel="noreferrer noopener" target="_blank" href="https://tiny.amazon.com/76jhanl7/issuamazissucrea">File an issue</MenuItem>
                      </Menu>
                      <User auth={this.props.auth.signInUserSession} setIsAdminToggleHandler={this.setIsAdminToggleSet}/>
                    </Row>
                  </Column>
                </Row>
              </nav>
          </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectState: state.project.state,
        projectId: state.project.projectId,
        projectName: state.project.name
    };
};


export default connect(mapStateToProps, null)(Header);