import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDocumentMetaHandler } from '../../requests/FileRequests';
import { DOCUMENT_STATES } from '../../utils/Constants';
import { DOCUMENT_STILL_BEING_PARSED, DOCUMENT_FAIL_UPLOAD, DOCUMENT_FAIL_PARSE } from '../../constants/Strings';

class FileContent extends Component {
  componentDidMount = () => {
    this.props.getDocumentMetaHandler(this.props.projectId, this.props.documentId);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.documentId !== prevProps.documentId) {
      this.props.getDocumentMetaHandler(this.props.projectId, this.props.documentId);
    }
  };

  render() {
    const divStyle = {
      margin: '40px 40px 60px',
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
    };

    if (this.props.documentState === DOCUMENT_STATES.REGISTERED) {
      return <div> {DOCUMENT_STILL_BEING_PARSED} </div>;
    } else if (this.props.documentState === DOCUMENT_STATES.UPLOADED) {
      return <div> {DOCUMENT_STILL_BEING_PARSED} </div>;
    } else if (this.props.documentState === DOCUMENT_STATES.FAIL_PARSED) {
      return <div> {DOCUMENT_FAIL_PARSE} </div>;
    } else if (this.props.documentState === DOCUMENT_STATES.FAIL_UPLOAD) {
      return <div> {DOCUMENT_FAIL_UPLOAD} </div>;
    } else if (this.props.documentContent === '') {
      return <div>Loading...</div>;
    } else {
      return (
        <div id="documentContent" style={divStyle}>
          {this.props.documentContent}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    documentContent: state.currDocument.documentContent,
    documentState: state.currDocument.documentState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentMetaHandler: (projectName, documentName) => dispatch(getDocumentMetaHandler(projectName, documentName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileContent);
