import React from 'react';
import '../../assets/css/CreateProject.css';
import { connect } from 'react-redux';
import Row from "@meridian/components/row"
import Box from "@meridian/components/box"
import Text from "@meridian/components/text"
import Button from "@meridian/components/button"
import Select, { SelectOption } from "@meridian/components/select"
import Icon from "@meridian/components/icon"
import Column from "@meridian/components/column"
import Alert from "@meridian/components/alert"
import Heading from "@meridian/components/heading"
import Loader from "@meridian/components/loader"
import Tooltip from "@meridian/components/tooltip"
import helpTokens from '@meridian/tokens/base/icon/help'
import exportSmallTokens from '@meridian/tokens/base/icon/export-small'
import closeKnockoutTokens from "@meridian/tokens/base/icon/close-knockout"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import { addClientToProject, getClientsForProject } from '../../requests/ClientRequests'
import { getProjectModelToken } from '../../requests/ProjectRequests';
import { ADD_CLIENT_TO_PROJECT_MESSAGE, TOOLTIP_FOR_CONFIGURE_IN_APP } from "../../constants/Strings"
import { TT_LINK_TO_DELETE_CLIENT } from "../../constants/TicketQuickLinks"

export class ProjectExternalClients extends React.Component{
    state = {
        selectedClient: null,
        projectClientsLoaded: false,
        clientSelectedError: false
    }

    componentDidMount = () => {
        this.props.getProjectModelToken(this.props.projectId, this.getClientsForProjectHandler)
    }

    setSelectedClient = (val) => {
        this.setState({selectedClient: val})
    }

    setProjectClientsLoaded = () => {
        this.setState({projectClientsLoaded: true})
    }

    setClientSelectedError = (val) => {
        this.setState({clientSelectedError: val})
    }

    getClientsForProjectHandler = (projectToken) => {
        this.props.getClientsForProject(projectToken)
        this.setProjectClientsLoaded()
    }

    addAccessToClient = (e) => {
        e.preventDefault()
        if (this.state.selectedClient === null) {
            this.setClientSelectedError(true)
            return null
        }
        
        this.props.addClientToProject(this.state.selectedClient.id, this.props.projectModelToken)
        this.setSelectedClient(null)
    }

    filterClientsNotSharedWithAlready = () => {
        const clientIds = this.props.projectClients.map((client) => client.id)
        return this.props.clients.filter((client) => !clientIds.includes(client.id))
    }

    getClientsAvailableToShareWith = () => {
        let clients = this.filterClientsNotSharedWithAlready()

        if (clients == undefined) return null
        let clientOptions = clients.map((client) => (
                                <SelectOption key={client.id}
                                    value={client}
                                    label={client.name}/>
                            ))
        return clientOptions
    }

    render(){
        if (!this.state.projectClientsLoaded) return (
            <Column
              type="outline"
              spacingInset="small"
              spacing="large"
              minWidth="40%"
              heights={"50%"}
              minHeight="50vh"
            >
              <Loader />
            </Column>
          )

        return (
            <Column
                type="outline"
                spacingInset="small"
                spacing="medium"
                minWidth="40%"
              >
                <Box>
                    <Heading level={6} alignment='left' className='box-header'> Add application </Heading>
                    <Text> {ADD_CLIENT_TO_PROJECT_MESSAGE} </Text>
                    <form onSubmit={this.addAccessToClient}>
                        <Row
                          wrap="down"
                          alignment="top left"
                          spacing='small'
                          spacingInset='small'
                        >
                            <Box>
                                <Select
                                    prefix="Application"
                                    placeholder="Pick an application..."
                                    value={this.state.selectedClient}
                                    error={this.state.clientSelectedError}
                                    onChange={(value) => {this.setSelectedClient(value)}}
                                    onBlur={() =>
                                        this.state.selectedClient ? this.setClientSelectedError(false) : this.setClientSelectedError(true)
                                    }
                                >
                                    {this.getClientsAvailableToShareWith()}
                                </Select>
                                <div>
                                    {this.state.clientSelectedError ? (
                                        <Alert type="error" size="small">
                                            Application is required.
                                        </Alert>
                                    ) : null}
                                </div>
                            </Box>
                            <Box>
                                <Button
                                  size="small"
                                  type="primary"
                                  submit={true}
                                  disabled={this.state.clientSelectedError || this.state.selectedClient===null}
                                >
                                    Add application
                                </Button>
                            </Box>
                        </Row>
                    </form>
                </Box>
                <Box>
                    <Heading level={6} alignment='left' className='box-header'> Applications with model access </Heading>
                    {this.props.projectClients.length === 0 ? "No clients have been given access." :
                    <Table
                        spacing="small"
                        headerRows={1}
                        showStripes={true}
                        showDividers={false}
                    >
                        <TableRow>
                            <TableCell> Name </TableCell>
                            <TableCell width="220px"> 
                                <Tooltip title={TOOLTIP_FOR_CONFIGURE_IN_APP} position="top">
                                        <Text>Configure in application <Icon tabIndex="0" className="HelpTooltip" tokens={helpTokens}></Icon></Text>
                                </Tooltip>
                            </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                        {this.props.projectClients.map((client) => (
                            <TableRow key={client.name}>
                                <TableCell> {client.name} </TableCell>
                                <TableCell> <a href={client.appLink} target="_blank" rel="noopener noreferrer">Go to application <Icon tokens={exportSmallTokens}></Icon> </a></TableCell>
                                <TableCell>
                                    <Button type="icon" href={TT_LINK_TO_DELETE_CLIENT} target="_blank" rel="noopener noreferrer">
                                        <Icon tokens={closeKnockoutTokens}/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>}
                </Box>
            </Column>

        )
    }
}

const mapStateToProps = state => {
    return {
        clients: state.client.externalClients,
        projectModelToken: state.project.model_token,
        projectId: state.project.projectId,
        projectClients: state.client.projectClients,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjectModelToken: (projectId, callBackHandler) => dispatch(getProjectModelToken(projectId, callBackHandler)),
        addClientToProject: (clientId, projectToken) => dispatch(addClientToProject(clientId, projectToken)),
        getClientsForProject: (projectToken) => dispatch(getClientsForProject(projectToken))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectExternalClients);
