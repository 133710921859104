import {ADMIN, CUSTOM_USER_TYPE, LOCAL_STORAGE_ADMIN_TOGGLE_KEY, USER_TYPE} from "../constants/Strings";

const _isUserAdmin = (userType) => {
    return userType === ADMIN;
}

const _isAdminToggleOn = () => {
    return ((LOCAL_STORAGE_ADMIN_TOGGLE_KEY in localStorage) ?
        (localStorage.getItem(LOCAL_STORAGE_ADMIN_TOGGLE_KEY) === "true") : false)
}

export const isAdminAndToggleOn = (payload) => {
    if (CUSTOM_USER_TYPE in payload) {
        return _isUserAdmin(payload[CUSTOM_USER_TYPE]) && _isAdminToggleOn()
    } else if (USER_TYPE in payload) {
        return _isUserAdmin(payload[USER_TYPE]) && _isAdminToggleOn()
    } else {
        return false
    }
}

export const setAdminToggle = (state) => {
    localStorage.setItem(LOCAL_STORAGE_ADMIN_TOGGLE_KEY, (state).toString())
}