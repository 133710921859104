import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/css/DocumentListNav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { getProjectDocumentsHandler } from '../../requests/ProjectRequests';
import SideMenu, { SideMenuLink, SideMenuTitle } from "@meridian/components/side-menu"
import Theme from "@meridian/components/theme"
import blueDarkTokens from "@meridian/tokens/theme/blue-dark"
import { DOCUMENT_STATES } from '../../utils/Constants'
import DocumentUploader from './DocumentUploader'


class DocumentListNav extends Component {

    state = {
        selectedDocument: window.location.pathname,
    }

    componentDidMount = () => {
        // retrieve the list of documents every 5 seconds
        this.documentsPoller = setInterval(() => {this.refreshDocumentsListHelper()}, 5000)
    }

    componentWillUnmount = () => {
        clearInterval(this.documentsPoller)
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.documentId !== this.props.documentId) {
            this.setState({ selectedDocument: window.location.pathname });
        }
    }

    refreshDocumentsListHelper = () => {
        if ( !document.hidden ) {
            this.props.getProjectDocumentsHandler(this.props.projectId)
        }
    }

    changeDocumentHandler = (url, documentName, file) => {
        this.props.showHideStateHandler();
        this.props.history.push({
            pathname: encodeURI(url),
            state: {status: file.state},
        });
        this.setState({ selectedDocument: window.location.pathname });
    }

    render() {
        var show_hide;
        if(this.props.showFileNav) {
            show_hide = <FontAwesomeIcon
                        icon = { faChevronCircleLeft }
                        className = 'ArrowLeft'
                        size = '2x'
                        onClick = { (e) => {
                                e.stopPropagation()
                                this.props.showHideStateHandler(false)
                            }
                        }
                    />
        } else {
            show_hide = <FontAwesomeIcon
                        icon = { faChevronCircleRight }
                        className = 'ArrowRight'
                        size = '2x'
                        onClick = { (e) => {
                                e.stopPropagation()
                                this.props.showHideStateHandler(true)
                            }
                        }
                    />
        }

        let listOfFiles = this.props.listOfFiles
                            .sort((x, y) => x.documentName.localeCompare(y.documentName))
                            .map((file, index) => {
                                var url = this.props.match.url + '/' + encodeURIComponent(file.documentId);
                                return <SideMenuLink key = {file.documentId}
                                    selected = { this.state.selectedDocument === url }
                                    onClick = { () => this.changeDocumentHandler(url, file.filePath, file) }
                                    >
                                        {file.state === DOCUMENT_STATES.REGISTERED ?
                                            <img className='DocumentStateRegistered' height='24px' width='24px' src= {require('../../assets/images/document_parsing.svg')}  alt="Document Registered"/> :
                                            null
                                        }
                                        {file.state === DOCUMENT_STATES.COMPLETED ?
                                            <img className='DocumentStateCompleted' height='24px' width='24px' src= {require('../../assets/images/document_completed.svg')}  alt="Document Completed"/> :
                                            null
                                        }
                                        {file.state === DOCUMENT_STATES.PARSED ?
                                            <img className='DocumentStateParsed' height='24px' width='24px' src= {require('../../assets/images/document_parsed.svg')}  alt="Document Parsed"/> :
                                            null
                                        }
                                        {file.state === DOCUMENT_STATES.UPLOADED ?
                                            <img className='DocumentStateUploaded' height='24px' width='24px' src= {require('../../assets/images/document_parsing.svg')}  alt="Document Parsing"/> :
                                            null
                                        }
                                        {file.state === DOCUMENT_STATES.FAIL_PARSED || file.state === DOCUMENT_STATES.FAIL_UPLOAD ?
                                            <img className='DocumentStateFailedParsed' height='24px' width='24px' src= {require('../../assets/images/document_fail_parsed.svg')}  alt="Parsing Failed"/> :
                                            null
                                        }
                                        {file.documentName}
                                    </SideMenuLink>});
        const cssClasses = [
            "Labels",
            this.props.showFileNav ? "LabelsOpen" : "LabelsClose"
        ]

        return (
            <div className='documentList'>
                <Theme tokens={blueDarkTokens}>
                    <SideMenu
                      open={this.props.showFileNav}
                      closeLabel="Close"
                    >
                        <SideMenuTitle>
                            Files in project ({this.props.listOfFiles.length})
                        </SideMenuTitle>
                        {listOfFiles}
                    </SideMenu>
                    {show_hide}
                    <DocumentUploader {...this.props}/>

                </Theme>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.projectId,
        listOfFiles: state.project.listOfFiles,
        documentId: state.currDocument.documentId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjectDocumentsHandler: (projectId) => dispatch(getProjectDocumentsHandler(projectId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListNav);
