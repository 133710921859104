import {
    getIdentityPoolId,
    getUserPoolId,
    getCognitoClientId,
    getStage,
    getAppWebDomain,
    getDatalensRedirectUri
} from '../utils/EnvironmentConfig'
import { HOSTNAME } from '../constants/StageConstants'

export const AUTH_DATA = {
    AppWebDomain: getAppWebDomain(),
    ClientId: getCognitoClientId(),
    RedirectUriSignIn: getDatalensRedirectUri(),
    RedirectUriSignOut: getDatalensRedirectUri(),
    TokenScopesArray: ['profile', 'openid'],
    UserPoolId: getUserPoolId(),
    IdentityProvider: 'Amazon',
}
export const IDENTITY_POOL_ID = getIdentityPoolId()
export const COGNITO_PROVIDER = `cognito-idp.us-west-2.amazonaws.com/${AUTH_DATA.UserPoolId}`
