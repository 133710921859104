import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import project from './reducers/projectReducer'
import currDocument from './reducers/currDocumentReducer'
import user from './reducers/userReducer'
import notification from './reducers/notificationReducer'
import apiDataLoaded from './reducers/apiDataLoadedReducer'
import preTrainedLabels from "./reducers/pretrainedLabelsReducer"
import fileUpload from "./reducers/fileUploadReducer"
import sharedBundles from "./reducers/sharedBundlesReducer"
import clients from "./reducers/clientsReducer"

const reducers = combineReducers({
    project: project,
    currDocument: currDocument,
    notification: notification,
    user: user,
    apiDataLoaded: apiDataLoaded,
    preTrainedLabels: preTrainedLabels,
    fileUpload: fileUpload,
    sharedBundles: sharedBundles,
    client: clients
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
