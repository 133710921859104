import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import DocumentListNav from '../Document/DocumentListNav';
import Document from '../Document/Document';
import Metadata from '../Metadata/Metadata';
import ProjectHome from './ProjectHome';
import { getProjectDocumentsHandler, getProjectLabelsHandler, getProjectDetails, getProjectSuccessfulTrainingIterations } from '../../requests/ProjectRequests';
import Row from "@meridian/components/row"
import Box from "@meridian/components/box"
import Heading from "@meridian/components/heading"
import Column from "@meridian/components/column"
import { css } from 'emotion'
import ProjectHomeLink from './ProjectHomeLink'
import * as actionTypes from "../../store/actions/actions"
import * as sharedBundleActionTypes from '../../store/actions/sharedBundlesActions';
import { getProjectState } from '../../constants/Project'
import ProjectHomePageLatency from '../PageLatency/ProjectHomePageLatency'
import ProjectDocumentPageLatency from '../PageLatency/ProjectDocumentPageLatency'
import RelationshipsDocument from '../Document/RelationshipsDocuments'


class Project extends Component {
    state = {
        showFileNav: true,
    }

    componentDidMount = () => {
        this.props.getProjectDetails(this.props.match.params.projectId);
        this.props.getProjectDocumentsHandler(this.props.match.params.projectId);
        this.props.getProjectLabelsHandler(this.props.match.params.projectId);
        this.props.getProjectSuccessfulTrainingIterations(this.props.match.params.projectId);
    }

    componentWillUnmount = () => {
      this.props.clearProjectData()
      this.props.clearSharedBundleData()
    }

    showHideStateHandler = (showHide) => {
        this.setState({ showFileNav: showHide });
    }

    getDocumentView = (props) => {
        const documentNameStyle = css({margin: '0 !important;'})
        let document_view = <div className='project' onClick = { () => this.showHideStateHandler(false) } >
                              <DocumentListNav {...this.props} showFileNav = {this.state.showFileNav} showHideStateHandler = {this.showHideStateHandler} />
                              <Column
                                heights={["fit", "fill"]}
                                alignmentVertical="top"
                                alignmentHorizontal="center"
                                width="100vw"
                              >
                                <Row alignmentHorizontal="left" spacing="small" widths={["grid-2", "grid-7", "grid-3"]} width="100%" className={`DocumentHeader ${documentNameStyle}`}>
                                  <ProjectHomeLink projectId={this.props.match.params.projectId} />
                                  <Heading level={1} type="h200" alignment='center' className={documentNameStyle}> {this.props.documentName} </Heading>

                                  {this.props.allowRelationships ?
                                      <RelationshipsDocument documentId={props.match.params.documentId} projectId={this.props.match.params.projectId} />
                                      : null }
                                </Row>
                                <Row width="100%" maxHeight="calc(100vh - 70px)" wrap="down" widths={["grid-9","grid-3"]}  alignmentVertical="stretch"  alignmentHorizontal="left" spacing="small" spacingInset="small" className='projectRow'>
                                  <Document {...props} projectId={this.props.match.params.projectId} documentId={props.match.params.documentId} />

                                  <Metadata {...props} />
                                  <ProjectDocumentPageLatency />
                                </Row>
                              </Column>
                            </div>
        return document_view
    }

    getProjectSummaryView = (props) => {
        let view_summary = <div className='ProjectHome' onClick = { () => this.showHideStateHandler(false) }>
                              <DocumentListNav {...this.props} showFileNav = {this.state.showFileNav} showHideStateHandler = {this.showHideStateHandler} />
                              <ProjectHome />
                              <ProjectHomePageLatency />
                            </div>
        return view_summary
    }

    render() {
        return (
          <div>
            <Route
              exact path = {'/verify/:projectId/'}
              render = { (props) => this.getProjectSummaryView(props)}
            />
            <Route
              exact path = {'/verify/:projectId/:documentId'}
              render = { (props) => this.getDocumentView(props)}
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectState: state.project.state,
        projectName: state.project.projectName,
        documentName: state.currDocument.documentName,
        allowRelationships: state.project.allowRelationships
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjectDetails: (projectId) => dispatch(getProjectDetails(projectId)),
        getProjectDocumentsHandler: (projectName) => dispatch(getProjectDocumentsHandler(projectName)),
        getProjectLabelsHandler: (documentName) => dispatch(getProjectLabelsHandler(documentName)),
        getProjectSuccessfulTrainingIterations: (projectId) => dispatch(getProjectSuccessfulTrainingIterations(projectId)),
        clearProjectData: () => dispatch({type: actionTypes.CLEAR_PROJECT_DATA}),
        clearSharedBundleData: () => dispatch({type: sharedBundleActionTypes.CLEAR_SHARED_BUNDLE_DATA}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);