import { LegalMetricsPublisher } from '@amzn/legal-client-metrics';
import { MetricDomain } from '@amzn/legal-client-metrics/dist/model/metric-domain.enum';
import { Stage, getStage } from "./EnvironmentConfig";

const METRIC_SERVICE_NAME = 'DataLens'

const stage = getStage()

const getShouldPublishMetrics = () => {
    return stage !== Stage.LOCAL
};

const getMetricsDomain = () => {
	if (stage == Stage.PROD) {
		return MetricDomain.Prod
	} else {
		return MetricDomain.Test
	}
};

export const MetricsPublisher = new LegalMetricsPublisher({
    serviceName: METRIC_SERVICE_NAME,
    shouldPublishMetrics: getShouldPublishMetrics(),
    domain: getMetricsDomain()
});
