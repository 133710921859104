import React from "react"
import { DateTypeMetadata } from './DateMetadata'
import Icon from '@meridian/components/icon';
import Tooltip from '@meridian/components/tooltip';
import helpTokens from '@meridian/tokens/base/icon/help';

export function TerminationDateMetadata(props) {
    const metadata = props.metadata

    const getSupportingEntities = () => {
        let supportEntities = []
        metadata.SupportingEntities.forEach((entity) => {
            supportEntities.push(<li>
                {TERMINATION_DATE_ENTITY_TYPE_TO_UI_STRING_MAPPING[entity.EntityType] + ' - ' + entity.Text}
            </li>)
        })
        return supportEntities
    }
    
    const getPopoverContent = () => {
        if (!metadata.SupportingEntities) {
            return null
        }
        
        let msg = <div>
            Supporting Entites:
            <p>
                {getSupportingEntities()}
            </p>
        </div>  

        return (
            <Tooltip position="bottom" title={msg}>
                <Icon className="MetadataPopoverHelp" tokens={helpTokens}></Icon>
            </Tooltip>)
    }

    return (
        <span>
          <DateTypeMetadata metadata={metadata} />
            {getPopoverContent()}
        </span>
      )
}

const TERMINATION_DATE_ENTITY_TYPE_TO_UI_STRING_MAPPING = {
    'I-TerminationDate': 'Interval',
    'Effective Date': 'Effective Date',
    'RelativeTerminationDateBasis': 'Basis'
}