import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileSelector from '../FileSelector/FileSelector'
import Modal, { ModalFooter } from "@meridian/components/modal"
import Button from "@meridian/components/button"
import Row from "@meridian/components/row"
import Box from "@meridian/components/box"
import blueLightTokens from "@meridian/tokens/theme/blue-light"
import Theme from "@meridian/components/theme"
import { putDocumentHandler } from '../../requests/FileRequests'
import { DOCUMENT_STATES } from '../../utils/Constants'

class DocumentUploader extends Component {

    state = {
        showUpload: this.props.location.state && this.props.location.state.showFileUpload,
        uploadInProgress: false,
    }

    showUploadHandler = (val) => {
        this.setState({showUpload: val})
    }

    uploadFileHandler = async (documents) => {
        this.setState({uploadInProgress: true})
        for (let i = 0; i < documents.length; i++) {
            await putDocumentHandler(this.props.projectId, documents[i], DOCUMENT_STATES.REGISTERED)
        }
        this.setState({uploadInProgress: false})
    }

    render() {
        return (
            <Box>
                {this.props.showFileNav
                    ?
                        <div id='upload_files'>
                            <label>
                                <span className="UploadButton" onClick={() => this.showUploadHandler(true)}>
                                    Upload Files
                                </span>
                            </label>
                        </div>
                    : null
                }
                <Theme tokens={blueLightTokens}>
                    <Modal
                      title="Upload Files"
                      open={this.state.showUpload}
                      scrollContainer="modal"
                      closeLabel="Close"
                      width ="600px"
                    >
                        <FileSelector uploadFileHandler={this.uploadFileHandler} />
                        <ModalFooter>
                          <Row alignment="center right" widths="fit">
                            <Button type="primary" size="small" onClick={() => this.showUploadHandler(false)} disabled={this.state.uploadInProgress}>
                              Close
                            </Button>
                          </Row>
                        </ModalFooter>
                    </Modal>
                </Theme>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.projectId,
    };
};

export default connect(mapStateToProps, null)(DocumentUploader);
