import { isLabelImported, doesLabelQualifyForAutoAnnotation, isLabelTrainable } from '../../../utils/LabelsFilter';

/**
 * Auto Annotation Section displays only predictions for imported labels.
 * This filters our those metadata which belongs to Imported Labels
 */
export const getMetadataListForAutoAnnotatedSection = (labelsMapById, predictions) => {
  let filteredPredictions = [];
  if (predictions) {
    filteredPredictions = predictions.filter((pred) => {
      return labelsMapById[pred.labelId] && doesLabelQualifyForAutoAnnotation(labelsMapById[pred.labelId]);
    });
    filteredPredictions.forEach((pred) => {
      pred['isImported'] = isLabelImported(labelsMapById[pred.labelId]);
      pred['isTrainable'] = isLabelTrainable(labelsMapById[pred.labelId]);
      delete pred['annotationAction'];
    });
  }
  return filteredPredictions;
};

/**
 * User Annotation Section displays all user annotations except the DELETE annotataions
 * As of now we cannot annotate for imported labels
 */
export const getMetadataListForUserAnnotationSection = (labelsMapById, annotations) => {
  let filteredAnnotations = [];
  if (annotations) {
    filteredAnnotations = annotations.filter((ann) => {
      return labelsMapById[ann.labelId] && !isLabelImported(labelsMapById[ann.labelId]);
    });
    filteredAnnotations.forEach((pred) => (pred['isImported'] = isLabelImported(labelsMapById[pred.labelId])));
  }
  return filteredAnnotations;
};

/**
 * Model Prediction Section displays all predictions for non imported labels
 * along with newly added annotations except DELETE predictions
 */
export const getMetadataListForModelPredictionSection = (labelsMapById, annotations, predictions) => {
  const predictionsAndInsertedAnnotations = [];
  if (predictions) {
    predictions
      .filter((pred) => {
        return pred.annotationAction !== 'DELETE' && labelsMapById[pred.labelId] && !doesLabelQualifyForAutoAnnotation(labelsMapById[pred.labelId]);
      })
      .forEach((pred) => predictionsAndInsertedAnnotations.push(pred));
  }
  if (annotations) {
    annotations
      .filter((ann) => {
        return ann.annotationAction === 'INSERT' && labelsMapById[ann.labelId];
      })
      .forEach((ann) => predictionsAndInsertedAnnotations.push(ann));
  }
  predictionsAndInsertedAnnotations.forEach((pred) => (pred['isImported'] = isLabelImported(labelsMapById[pred.labelId])));
  return predictionsAndInsertedAnnotations;
};
