export const PROJECT_STATES_ORDER = {
    STARTED: 1, // for backwards compatibility. remove later
    COLLECTION: 1,
    TRAINING: 2,
    VERIFICATION: 3,
    LAUNCHING: 4,
    LAUNCHED: 5
}

export const getProjectState = (projectState) => {
    if (PROJECT_STATES_ORDER.hasOwnProperty(projectState)) {
        return PROJECT_STATES_ORDER[projectState]
    }

    if (projectState) {
        console.error('Project state is invalid. State: ', projectState)
    }
    return 0
}