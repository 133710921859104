import * as actionTypes from '../actions/actions';
import { DATA_SOURCE } from '../../constants/DataSource';

const initialState = {
  documentName: '',
  parsedFilePath: '',
  documentContent: '',
  documentId: '',
  // Annotations Metadata from backend
  annotations: [],
  // Predictions Metadata from backend
  predictions: [],
  /*
   * Metadata that is diplayed for users
   * 1. UserAnnotations + Predictions on Imported Labels
   * 2. ModelPredictions + Predictions on Imported Labels
   */
  displayedMetadata: [],
  dataSource: DATA_SOURCE.USER, // Defaults to user annotation
  documentState: '',
  relatedDocuments: []
};

const addAnnotationToState = (state, annotation) => {
  let annotations_index;
  annotation['annotationAction'] = 'INSERT';
  for (annotations_index in state.annotations) {
    if (state.annotations.hasOwnProperty(annotations_index) && state.annotations[annotations_index].annotationId === annotation.annotationId) {
      const newAnnotations = state.annotations
        .slice(0, annotations_index)
        .concat(state.annotations.slice(parseInt(annotations_index) + 1))
        .concat(annotation);
      return {
        ...state,
        annotations: newAnnotations,
      };
    }
  }
  return {
    // Add new annotation for a new element
    ...state,
    annotations: state.annotations.concat(annotation),
  };
};

const refreshAnnotationsForDocument = (state, annotations, insertAnnotations) => {
  if (insertAnnotations) {
    annotations.forEach(function(annotation) {
      annotation['annotationAction'] = 'INSERT';
    });
  }
  return {
    // Add new annotation for a new element
    ...state,
    annotations: annotations,
  };
};

const refreshPredictionsForDocument = (state, predictions) => {
  /**
   * To ensure Predictions can be saved to the DB, we are marking predictions as
   * 'annotationAction' = 'INSERT'
   * This treats these predictions as newly insert annotations and let them be saved in DB.
   */
  predictions.forEach(function(pred) {
    pred['annotationAction'] = 'INSERT';
  });
  return {
    ...state,
    predictions: predictions,
  };
};

const removeAnnotationActionAndDeletedAnnotationsForDocument = (state) => {
  const undeleted_annotations = state.annotations.filter((annotation) => annotation['annotationAction'] !== 'DELETE');
  undeleted_annotations.forEach(function(annotation) {
    delete annotation['annotationAction'];
  });
  return {
    ...state,
    annotations: undeleted_annotations,
  };
};

const deleteAnnotationFromState = (state, annotationId) => {
  if (state.dataSource === DATA_SOURCE.USER) {
    const annotationIndex = getAnnotationEntryInList(annotationId, state.annotations);
    if (annotationIndex && annotationIndex >= 0) {
      return {
        ...state,
        annotations: state.annotations
          .slice(0, annotationIndex)
          .concat(state.annotations.slice(parseInt(annotationIndex) + 1))
          .concat(state.annotations[annotationIndex]),
      };
    }
  }

  if (state.dataSource === DATA_SOURCE.MODEL) {
    const predIndex = getAnnotationEntryInList(annotationId, state.predictions);
    if (predIndex && predIndex >= 0) {
      return {
        ...state,
        predictions: state.predictions
          .slice(0, predIndex)
          .concat(state.predictions.slice(parseInt(predIndex) + 1))
          .concat(state.predictions[predIndex]),
      };
    }
  }
  return {
    ...state,
  };
};

const getAnnotationEntryInList = (annotationId, listOfAnnotations) => {
  for (const index in listOfAnnotations) {
    if (listOfAnnotations.hasOwnProperty(index) && listOfAnnotations[index].annotationId === annotationId) {
      const annotationToDelete = listOfAnnotations[index];
      annotationToDelete['annotationAction'] = 'DELETE';
      return index;
    }
  }
};

const currDocument = (state = initialState, action) => {
  let annotation;

  switch (action.type) {
    case actionTypes.GET_FILE_CONTENTS:
      return { ...state, documentName: action.documentName, documentContent: action.documentContent };
    case actionTypes.MARK_ANNOTATION_AS_HIGHLIGHTED: {
      const allAnnotations = state.annotations.concat(state.predictions);
      const annotationsForGivenId = allAnnotations.filter((annotation) => {
        return annotation.annotationId === action.annotation.annotationId;
      });
      if (annotationsForGivenId && annotationsForGivenId.length > 0) {
        annotationsForGivenId[0]['highlighted'] = true;
      }
      return state;
    }
    case actionTypes.REFRESH_ANNOTATIONS_FOR_DOCUMENT:
      return refreshAnnotationsForDocument(state, action.annotations, action.insertAnnotations);
    case actionTypes.REFRESH_PREDICTIONS_FOR_DOCUMENT:
      return refreshPredictionsForDocument(state, action.predictions);
    case actionTypes.REFRESH_DISPLAYED_METADATA_FOR_DOCUENT:
      return {
        ...state,
        displayedMetadata: action.displayedMetadata,
      };
    case actionTypes.REFRESH_DATA_SOURCE:
      return {
        ...state,
        dataSource: action.dataSource,
      };
    case actionTypes.ADD_TO_METDATA_EXTRACTED:
      annotation = {};
      annotation.text = action.metadataExtracted.value;
      annotation.question = action.metadataExtracted.tag;
      annotation.start = action.metadataExtracted.start;
      annotation.end = action.metadataExtracted.end;
      annotation.labelId = action.metadataExtracted.labelId;
      annotation.projectId = action.metadataExtracted.projectId;
      annotation.documentId = action.metadataExtracted.documentId;
      annotation.annotationId = action.metadataExtracted.annotationId;
      annotation.source = action.metadataExtracted.source;

      if (action.metadataExtracted.oldId) {
        deleteAnnotationFromState(state, action.metadataExtracted.oldId);
      }
      return addAnnotationToState(state, annotation);
    case actionTypes.DELETE_METDATA_EXTRACTED:
      return deleteAnnotationFromState(state, action.annotationId);
    case actionTypes.REMOVE_ANNOTATION_ACTION_FOR_DOCUMENT:
      return removeAnnotationActionAndDeletedAnnotationsForDocument(state);
    case actionTypes.CLEAR_DOCUMENT_DATA:
      return {
        ...initialState,
      };
    case actionTypes.UPDATE_FILE_STATUS:
      return {
        ...state,
        documentState: action.doc.state,
      };
    case actionTypes.ADD_DOCUMENT_META:
      state.documentName = action.meta.DocumentName;
      state.parsedFilePath = action.meta.ParsedFilePath;
      state.documentId = action.meta.DocumentId;
      state.documentState = action.meta.State;
      return state;
    case actionTypes.ADD_DOCUMENT_RELATIONSHIPS:
      return {
        ...state,
        relatedDocuments: action.relatedDocuments
      }
    default:
      return state;
  }
};

export default currDocument;
