import * as actionTypes from '../actions/pretrainedLabelsActions'

const initialState = {
    labelsBundles: '',
}

const preTrainedLabels = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_PRETRAINED_LABELS:
            return {
                ...state,
                labelsBundles: action.labelsBundles
            }
        default:
            return state;
    }
};

export default preTrainedLabels;