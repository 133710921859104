import React from 'react';
import { connect } from 'react-redux';
import Row from "@meridian/components/row"
import Box from "@meridian/components/box"
import Button from "@meridian/components/button"
import Heading from "@meridian/components/heading"
import Table, { TableRow, TableCell } from "@meridian/components/table"
import Icon from "@meridian/components/icon"
import Column from "@meridian/components/column"
import Loader from "@meridian/components/loader"
import Text from "@meridian/components/text"
import trashTokens from "@meridian/tokens/base/icon/trash"
import { getProjectTestMetrics } from '../../../requests/ProjectRequests'
import ShareModelTable from './ShareModelTable'
import AliasInputForm from './AliasInput'
import { createPretrainedModelsBundle, getPreTrainedModelsBundlesForProject } from '../../../requests/PreTrainedLabelsRequests'
import { SHARE_MODEL_MESSAGE, SHARE_MODEL_ALL_LABELS_SHARED, 
  SHARE_MODEL_STARTED_SUCCESSFULLY, SHARE_MODEL_FORM_HEADER, ADD_USERS_TO_SHARED_BUNDLE } from '../../../constants/Strings'

const FORM_STEPS = {
  BUNDLE_STEP: 1,
  ADD_USER_STEP: 2,
  SUCCESS_STEP: 3
}


export class ShareModelForm extends React.Component {
  state = {
    labels: null,
    formStep: FORM_STEPS.BUNDLE_STEP,
    users: [],
    sharedClicked: false,
  }

  componentDidMount = () => {
    this.props.getProjectTestMetrics(this.setProjectLabelsHandler, this.props.projectId, this.props.currentIterationNumber)
  }

  setProjectLabelsHandler = (labelsWithPerformance) => {
    let labels = labelsWithPerformance.labelsPerformanceMetrics.map(labelMetrics => {
      return {
        name: labelMetrics.labelName,
        labelId: labelMetrics.labelId,
        f1: labelMetrics.performanceMetrics.f1Percentage,
        selected: false
      }
    });
    this.setLabels(labels)
  }

  setSharedClicked = (val) => {
    this.setState({ sharedClicked: val })
  }

  setLabels = (labels) => {
    this.setState({ labels: labels })
  }

  setFormStep = (step) => {
    this.setState({ formStep: step })
  }

  setUsers = (val) => {
    this.setState({ users: val })
  }

  addAliasToUsers = (alias) => {
    this.setUsers([...this.state.users, alias])
  }

  removeAliasFromUsers = (alias) => {
    var users = [...this.state.users]
    var index = users.indexOf(alias)
    if (index !== -1) {
      users.splice(index, 1);
      this.setUsers(users)
    }
  }

  moveToNextStep = () => {
    this.setFormStep(this.state.formStep + 1)
  }

  addPermissionTobundle = (alias) => {
    alias = alias.trim()
    if (alias && this.state.users.indexOf(alias) === -1) {
      this.addAliasToUsers(alias)
    }
  }

  submitCreateSharedBundle = async () => {
    let labels = this.state.labels.filter((label) => label.selected)
    this.setSharedClicked(true)
    for (let label of labels) {
      await this.props.createPretrainedModelsBundle(this.props.projectId, this.props.projectName, label, this.state.users)
    }
    this.props.getPreTrainedModelsBundlesForProject(this.props.projectId)
    this.moveToNextStep()
    this.setSharedClicked(false)
  }

  getUsersToShareBundlesTable = () => {
    return (
      <Table headerRows={1} showDividers={true} fixHeaderRows={true}>
        <TableRow>
          <TableCell>Alias</TableCell>
          <TableCell width="10%">Remove</TableCell>
        </TableRow>
        {this.state.users.map(user => (
          <TableRow key={user}>
            <TableCell>{user}</TableCell>
            <TableCell>
              <Button type="icon" onClick={() => this.removeAliasFromUsers(user)}>
                <Icon tokens={trashTokens}>Remove</Icon>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    )
  }

  formForAddPermission = () => {
    return (
      <Column heights={["fit","fit","fill"]} spacing="small">
        <Box maxWidth="100px">
          <Button onClick={() => this.setFormStep(FORM_STEPS.BUNDLE_STEP)} type="link" size="small"> 
            &lt; Back 
          </Button>
        </Box>
        <Text>{ADD_USERS_TO_SHARED_BUNDLE}</Text>
        <AliasInputForm onAddHandler={this.addPermissionTobundle} />
        <Row>
          {this.state.users.length !== 0 ? this.getUsersToShareBundlesTable() : null}
        </Row>
        <Row alignmentHorizontal="right" spacingInset="medium none none">
          <Button size="large" onClick={this.submitCreateSharedBundle}> Share </Button>
        </Row>
      </Column>
    )
  }

  getLabelsNotPartOfABundle = () => {
    const labelIds = this.props.sharedBundles.map((bundle) => bundle.labelModels[0].labelId)
    return this.state.labels.filter((label) => !labelIds.includes(label.labelId))
  }

  getShareConfirmationMessage = () => {
    return (
      <Column heights="fit">
        <Text>{SHARE_MODEL_STARTED_SUCCESSFULLY}</Text>
        <Row alignmentHorizontal="right" spacingInset="medium none none">
          <Button onClick={() => this.setFormStep(FORM_STEPS.BUNDLE_STEP)}>
            Ok
          </Button>
        </Row>
      </Column>
    )
  }

  render() {
    if (!this.state.labels || this.state.sharedClicked) {
      return (
        <Box>
          <Heading level={6} alignment='left' className='box-header'> {SHARE_MODEL_FORM_HEADER} </Heading>
          <Loader size="medium" />
        </Box>
      )
    }

    let labels = this.getLabelsNotPartOfABundle()
    if (labels.length === 0 && this.state.formStep === 1) {
      return (
        <Box>
          <Heading level={6} alignment='left' className='box-header'> {SHARE_MODEL_FORM_HEADER} </Heading>
          <Text>{SHARE_MODEL_ALL_LABELS_SHARED}</Text>
        </Box>
      )
    }
    return (
      <Box>
        <Heading level={6} alignment='left' className='box-header'> {SHARE_MODEL_FORM_HEADER} </Heading>
        <Box maxHeight="100%" minHeight="200px" type="outline" spacingInset="medium">
          {labels.length !== 0 && this.state.formStep === FORM_STEPS.BUNDLE_STEP ?
            <Text>{SHARE_MODEL_MESSAGE}</Text>
            : null
          }

          {labels.length !== 0 && this.state.formStep === FORM_STEPS.BUNDLE_STEP ?
            <ShareModelTable
              labels={labels}
              setLabels={this.setLabels}
              nextButtonHandler={this.moveToNextStep}
            />
            : null
          }

          {this.state.formStep == FORM_STEPS.ADD_USER_STEP ?
            this.formForAddPermission()
            : null
          }

          {this.state.formStep == FORM_STEPS.SUCCESS_STEP ?
            this.getShareConfirmationMessage()
            : null
          }
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => {
  return {
    projectLabels: state.project.projectLabels,
    currentIterationNumber: state.project.currentIterationNumber,
    projectId: state.project.projectId,
    projectName: state.project.name,
    sharedBundles: state.sharedBundles.bundles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectTestMetrics: (labelsHandler, projectId, currentIterationNumber) => dispatch(getProjectTestMetrics(labelsHandler, projectId, currentIterationNumber)),
    createPretrainedModelsBundle: (projectId, projectName, bundleName, users) => dispatch(createPretrainedModelsBundle(projectId, projectName, bundleName, users)),
    getPreTrainedModelsBundlesForProject: (projectId) => dispatch(getPreTrainedModelsBundlesForProject(projectId, () => { }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareModelForm);
