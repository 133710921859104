import React, { Component } from 'react';
import '../../assets/css/Workflow.css'
import Box from "@meridian/components/box"
import { PROJECT_STATES_ORDER } from "../../constants/Project";

export default class Workflow extends Component {


    getClassNameForSystemAwaitingStates = (bubbleState) => {
        if (this.props.projectState < bubbleState) {
            return null
        } else if (this.props.projectState == bubbleState) {
            return "inprogress"
        } else {
            return "active"
        }
    }

    getClassNameForUserAwaitingStates = (bubbleState) => {
        if (this.props.projectState < bubbleState) {
            return null
        } else {
            return "active"
        }
    }

    render(){
        return (
            <Box
                spacingInset="medium"
                className="Workflow"
                minWidth="70%">
                <div className="workflow">
                    <ul className="progressbar">
                        <li className={this.getClassNameForUserAwaitingStates(PROJECT_STATES_ORDER.COLLECTION)}>Collection</li>
                        <li className={this.getClassNameForSystemAwaitingStates(PROJECT_STATES_ORDER.TRAINING)}>Training</li>
                        <li className={this.getClassNameForUserAwaitingStates(PROJECT_STATES_ORDER.VERIFICATION)}>Verification</li>
                        {this.props.projectState == PROJECT_STATES_ORDER.LAUNCHING ?
                            <li className={this.getClassNameForSystemAwaitingStates(PROJECT_STATES_ORDER.LAUNCHING)}>Launching</li>:
                            <li className={this.getClassNameForUserAwaitingStates(PROJECT_STATES_ORDER.LAUNCHED)}>Launched</li>
                        }
                    </ul>
                </div>
            </Box>
        )
    }
}

