import React, { Component } from 'react';
import {getProjectState, PROJECT_STATES_ORDER} from '../../constants/Project'
import Theme from "@meridian/components/theme"
import blueDarkTokens from "@meridian/tokens/theme/blue-dark"
import Tag from "@meridian/components/tag"

export default class ProjectStatusTag extends Component {
    NO_STATUS_TYPE = ''

    getStatusType = (projectState) => {
        if (!projectState) {
            return this.NO_STATUS_TYPE
        }
        let state = getProjectState(projectState)
        if (state === PROJECT_STATES_ORDER.COLLECTION ||
            state === PROJECT_STATES_ORDER.VERIFICATION) {
            // Yellow (warning) signifies pending user action
            return 'warning'
        } else if (state === PROJECT_STATES_ORDER.TRAINING ||
            state === PROJECT_STATES_ORDER.LAUNCHING) {
            // Gray (neutral) signifies pending system action
            return 'neutral'
        } else if (state === PROJECT_STATES_ORDER.LAUNCHED) {
            // Green (success) signifies completed
            return 'success'
        } else {
            console.error("No Status Type is configured for ProjectStatusTag. Please fix this immediately")
            return this.NO_STATUS_TYPE
        }
    }

    render() {
        let statusType = this.getStatusType(this.props.projectState)
        if (statusType === this.NO_STATUS_TYPE) {
            return null
        }

        return <Theme tokens={blueDarkTokens}>
            <Tag type={statusType}> {this.props.projectState} </Tag>
        </Theme>
    }
}
