import React, { Component } from 'react';
import { connect } from 'react-redux';
import Text from "@meridian/components/text"
import UserModal from '../../Modal/UserModal'
import { backToCollection } from '../../../requests/ProjectRequests'
import { BACK_TO_COLLECTION_TITLE, BACK_TO_COLLECTION_TEXT } from '../../../constants/Strings'


class BackToCollectionModal extends Component {

    backToCollection = () => {
      this.props.backToCollection(this.props.projectId)
    }

    getModalMessage = (id) => {
      const modalMessage =
            <div id = { id }>
              <Text>
                { BACK_TO_COLLECTION_TEXT }
              </Text>
            </div>
      
      return modalMessage
    }

    render() {
      const title = BACK_TO_COLLECTION_TITLE
      const divId = "back_to_collection_modal"
      
      return ( 
        <UserModal
          divId = { divId }
          title = { title }
          modalOpen = { this.props.modalOpen }
          modalMessage = { this.getModalMessage(title) }
          yesFunctionHandler = { this.backToCollection }
          noFunctionHandler = { this.props.changeModalState }
        />
      )
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.projectId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        backToCollection: (projectId) => dispatch(backToCollection(projectId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BackToCollectionModal);