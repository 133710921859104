import * as actionTypes from '../store/actions/actions';
import apiCall from '../utils/ApiCall';
import { GET_EXTERNAL_CLIENTS_FAILURE, ADD_CLIENT_TO_PROJECT_FAILURE, GET_CLIENTS_FOR_PROJECT_FAILURE } from '../constants/Strings'

export const getExternalClients = () => {
    let clients = []

    return (dispatch) => {
        apiCall('/client', {}, 'GET').then((items) => {
            const clients = items.map(({ ClientName, ClientId, ApplicationLink }) => ({
                name: ClientName,
                id: ClientId,
                appLink: ApplicationLink,
              }));
            dispatch(actionTypes.addClients(clients))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_EXTERNAL_CLIENTS_FAILURE, 'error'))
        })
        .finally(() => {
            dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_CLIENTS, status: true});
        })
    }

}

export const addClientToProject = (client_id, project_token) => {
    
    return (dispatch) => {
        apiCall('/client/{clientId}/projectToken/', {'clientId': client_id}, 'POST', {'projectToken': project_token}).then((res) => {
            dispatch(getClientsForProject(project_token))
        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(ADD_CLIENT_TO_PROJECT_FAILURE, 'error'))
        })
    }
}

export const getClientsForProject = (project_token) => {

    return (dispatch) => {
        apiCall('/projecttoken/{projectToken}/clients/', {'projectToken': project_token}, 'GET').then((items) => {
            const clients = items.map(({ ClientName, ClientId, ApplicationLink }) => ({
                name: ClientName,
                id: ClientId,
                appLink: ApplicationLink,
              }));
            dispatch(actionTypes.addProjectClients(clients))

        })
        .catch((err) => {
            console.log(err);
            dispatch(actionTypes.showNotification(GET_CLIENTS_FOR_PROJECT_FAILURE, 'error'))
        })
    }

}