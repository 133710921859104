import * as actionTypes from '../actions/actions'

const initialState = {
    dataLoadedForGetProjectsForUser: false,
    dataLoadedForDocument: false,
    dataLoadedForMetadata: false,
    dataLoadedForAnnotationSummary: false,
    dataLoadedForTestMetrics: false,
    dataLoadedForGetProjectLabels: false,
    dataLoadedForGetProjectUsers: false,
    dataLoadedForGetClients: false
}

const apiDataLoaded = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA_LOADED_FOR_GET_PROJECTS_FOR_USER:
            return {
                ...state,
                dataLoadedForGetProjectsForUser: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_DOCUMENT_TEXT:
            return {
                ...state,
                dataLoadedForDocument: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_METADATA:
            return {
                ...state,
                dataLoadedForMetadata: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_ANNOTATION_SUMMARY:
            return {
                ...state,
                dataLoadedForAnnotationSummary: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_TEST_METRICS:
            return {
                ...state,
                dataLoadedForTestMetrics: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_GET_PROJECT_LABELS:
            return {
                ...state,
                dataLoadedForGetProjectLabels: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_GET_PROJECT_USERS:
            return {
                ...state,
                dataLoadedForGetProjectUsers: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_GET_CLIENTS:
            return {
                ...state,
                dataLoadedForGetClients: action.status
            }
        case actionTypes.SET_DATA_LOADED_FOR_GET_PRETRAINED_MODELS_BUNDLES:
            return {
                ...state,
                dataLoadedForGetPreTrainedModelsBundles: action.status
            }
        default:
            return state;
    }
};

export default apiDataLoaded;