import AWS from 'aws-sdk'
import * as Pinpoint from 'aws-sdk/clients/pinpoint'
import { getPinpointApplicationId } from './EnvironmentConfig'

export function recordUserLogIn(userId){
    var pinpoint = new Pinpoint()
    var date = new Date().toISOString()

    var params = {
      ApplicationId: getPinpointApplicationId(),
      EndpointId: userId,
      EndpointRequest: {
        ChannelType: 'CUSTOM',
        Address: 'CUSTOM',
        EffectiveDate: date,
        User: {
            UserAttributes: {
                'alias': [userId]
            },
            UserId: userId
        }
      }
    };

    pinpoint.updateEndpoint(params, function(err, data) {
      if (err) console.log(err, err.stack);
    });
}
