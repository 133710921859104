import * as actionTypes from '../actions/actions'

const initialState = {
    externalClients: [],
    projectClients: []
}

const clients = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_CLIENTS:
            return {
                ...state,
                externalClients: action.clients
            }
        case actionTypes.ADD_PROJECT_CLIENTS:
            return {
                ...state,
                projectClients: action.projectClients
            }
        default:
            return state;
    }
};

export default clients;