import { DATA_SOURCE } from '../../constants/DataSource';

const toggleHoverState = (getSelectionTextHandler, selectedTextInfo) => {
  getSelectionTextHandler(selectedTextInfo);
};

export const popupMoveToCursor = (e) => {
  const txt = 'top:' + (e.pageY - 20) + 'px;left:' + e.pageX + 'px; display:block';
  setTimeout(function() {
    const dropdownMetadataEl = document.getElementById('dropdownMetadata');
    if (dropdownMetadataEl) {
      dropdownMetadataEl.style.cssText = txt;
    }
  }, 30);
};

const getOffsetAttributes = (offsetContainer, index) => {
  if (offsetContainer.length !== undefined) {
    if (index <= offsetContainer.length) {
      return { offsetContainer: offsetContainer, index: index };
    } else {
      return { offsetContainer: undefined, index: index - offsetContainer.length };
    }
  }
  for (let i = 0; i < offsetContainer.childNodes.length; i++) {
    const childNodeContainer = offsetContainer.childNodes[i];
    const offset2 = getOffsetAttributes(childNodeContainer, index);
    if (offset2 !== undefined) {
      if (offset2.offsetContainer !== undefined) {
        return offset2;
      } else if (offset2.offsetContainer === undefined) {
        index = offset2.index;
      }
    }
  }
  return { offsetContainer: undefined, index: index };
};

const setRangeAttributes = (range, offset, index) => {
  const offset2 = getOffsetAttributes(document.getElementById('documentContent'), index);
  if (offset2.offsetContainer === undefined) {
    return;
  }
  if (offset === 'start') {
    range.setStart(offset2.offsetContainer, offset2.index);
  } else {
    range.setEnd(offset2.offsetContainer, offset2.index);
  }
};

export const updateAnnotationClick = (spanId, getSelectionTextHandler, selectedTextInfo) => {
  const el = document.getElementById(spanId);
  if (el === null) return;
  el.addEventListener(
    'click',
    function(e) {
      e.stopPropagation();
      popupMoveToCursor(e);
      toggleHoverState(getSelectionTextHandler, selectedTextInfo);
    },
    false
  );
};

const getAnnotationsClassNameFromSource = (annotationSource) => {
  switch (annotationSource) {
    case DATA_SOURCE.USER:
      return 'UserAnnotationSelection';
    case DATA_SOURCE.MODEL:
      return 'PredictionSelection';
    default:
      return '';
  }
};

export const highlightAnnotationHandler = (annotation, getSelectionTextHandler) => {
  // Check if highlight already exists
  if (document.getElementById(annotation.start + '_' + annotation.end) !== null || annotation.annotationAction === 'DELETE') {
    return;
  }
  const selectedText = {
    annotationId: annotation.labelId + '_' + annotation.start + '_' + annotation.end,
    text: annotation.text,
  };
  const range = document.createRange();
  range.selectNodeContents(document.getElementById('documentContent'));
  setRangeAttributes(range, 'start', annotation.start);
  setRangeAttributes(range, 'end', annotation.end);
  const newNode = document.createElement('span');
  newNode.setAttribute('id', annotation.start + '_' + annotation.end);
  newNode.className = getAnnotationsClassNameFromSource(annotation.source);
  newNode.addEventListener(
    'click',
    function(e) {
      e.stopPropagation();
      popupMoveToCursor(e);
      toggleHoverState(getSelectionTextHandler, selectedText);
    },
    false
  );
  newNode.appendChild(range.extractContents());
  range.insertNode(newNode);
};
