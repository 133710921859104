export const ADD_SHARED_BUNDLES = "ADD_SHARED_BUNDLES"
export const ADD_ACTOR_TO_BUNDLE = "ADD_ACTOR_TO_BUNDLE"
export const CLEAR_SHARED_BUNDLE_DATA = "CLEAR_SHARED_BUNDLE_DATA"
export const ADD_BUNDLE_USERS = "ADD_BUNDLE_USERS"

export const addSharedBundles = (sharedBundles) => {
    return {
        type: ADD_SHARED_BUNDLES,
        sharedBundles: sharedBundles
    };
};

export const addBundleUsers = (bundleId, users) => {
    return {
        type: ADD_BUNDLE_USERS,
        bundleId: bundleId,
        users: users
    }
}

export const addActorToBundle = (bundleId, actor) => {
    return {
        type: ADD_ACTOR_TO_BUNDLE,
        bundleId: bundleId,
        actor: actor
    };
};