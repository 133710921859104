import React, { Component } from 'react';
import { connect } from 'react-redux';
import { exportProjectMetadataHandler } from '../../requests/ExportRequests';
import Popover, { PopoverHeader } from "@meridian/components/popover"
import Column from "@meridian/components/column"
import Button from "@meridian/components/button"
import Text from "@meridian/components/text"
import downloadLargeTokens from "@meridian/tokens/base/icon/download-large"
import Icon from "@meridian/components/icon"
import Row from "@meridian/components/row"
import {getProjectState, PROJECT_STATES_ORDER} from '../../constants/Project'
import { DATA_SOURCE } from '../../utils/Constants'
import { EXPORT_ANNOTATION_SOURCE_REQUEST } from '../../constants/Strings'


class ExportProjectMetadata extends Component {

    state = {
        popOverOpen: false
    }

    checkStateForPopOver = () => {
        if (getProjectState(this.props.projState) <= PROJECT_STATES_ORDER.TRAINING) {
            this.props.exportProjectMetadataHandler(this.props.projName, DATA_SOURCE.USER_ANNOTATIONS)
        } else {
            this.setState({popOverOpen: true})
        }
    }

    exportProjectForData = (data_source) => {
        this.setState({popOverOpen: false})
        this.props.exportProjectMetadataHandler(this.props.projName, data_source)    
    }

    render() {
        return (
            <div className = "ExportMetadata" onClick={e => e.stopPropagation()}>
                  <Button type="link" ref={node => this.buttonRef = node} onClick = {() => this.checkStateForPopOver()}> 
                    <Icon tokens={downloadLargeTokens} /> 
                  </Button> 
                  <Popover
                    open={this.state.popOverOpen}
                    anchorNode={this.buttonRef}
                    onClose={() => this.setState({popOverOpen: false})}
                    position="bottom"
                  >
                    <PopoverHeader closeLabel="Close">Data Export</PopoverHeader>
                    <Column>
                      <Text>Which type of annotations would you like to export?</Text>
                      <Button type="tertiary" onClick={() => this.exportProjectForData(DATA_SOURCE.USER_ANNOTATIONS)}>User Annotations</Button>
                      <Button type="tertiary" onClick={() => this.exportProjectForData(DATA_SOURCE.MACHINE_PREDICTIONS)}>Machine Predictions</Button>
                    </Column>                    
                  </Popover>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        exportProjectMetadataHandler: (projectId, annotationSource) => dispatch(exportProjectMetadataHandler(projectId, annotationSource)),
    };
};

export default connect(null, mapDispatchToProps)(ExportProjectMetadata);
