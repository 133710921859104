import React from 'react'
import { connect } from 'react-redux'
import '../../assets/css/CreateProject.css'
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import Text from "@meridian/components/text"
import { getProjectDetails } from '../../requests/ProjectRequests'
import ProjectUsers from './ProjectUsers'
import ProjectLabels from './ProjectLabels'
import ProjectExternalClients from '../Client/ProjectExternalClient'
import ProjectHomeLink from './ProjectHomeLink'
import { MetricPageLatencyTimer } from "../../utils/MetricPageLatencyTimer"
import { CLIENT_METRICS_METHOD_NAMES } from "../../utils/Constants"
import * as actionTypes from "../../store/actions/actions"
import { Link } from 'react-router-dom'
import Button from "@meridian/components/button"
import Tab, { TabGroup } from "@meridian/components/tab"
import Box from '@meridian/components/box'
import {getProjectState, PROJECT_STATES_ORDER} from '../../constants/Project'
import ShareHome from './ShareTab/ShareHome'
import { getExternalClients } from '../../requests/ClientRequests'

const CONFIG_TAB_STATES =  {
    LABELS_TAB: "labels",
    PERMISSIONS_TAB: "permissions",
    SHARE_TAB: "share",
    CLIENTS_TAB: "applications"
}
export class ManageProject extends React.Component{

    constructor (props){
        super(props)
        this.metricPageLatencyTimer = new MetricPageLatencyTimer()
        let selectedTab = this.getSelectedTabValueFromUrlHash()
        this.state = {
            projectId : this.props.match.params.project_id ? this.props.match.params.project_id : "",
            selectedTab: selectedTab,
        }

        this.props.getProjectDetails(this.state.projectId)
        this.props.getExternalClients()
    }

    componentWillUnmount = () => {
      this.props.clearProjectData()
    }

    resetStatusForAllTabs = () => {
        this.props.setDataLoadedForGetProjectUsers(false)
        this.props.setDataLoadedForGetProjectLabels(false)
        this.props.setDataLoadedForGetClients(false)
        this.props.setDataLoadedForGetPreTrainedModelsBundles(false)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.location.hash !== prevProps.location.hash) {
            let selectedTab = this.getSelectedTabValueFromUrlHash()
            this.setSelectedTabState(selectedTab)
            this.metricPageLatencyTimer = new MetricPageLatencyTimer()
        }

        switch (this.state.selectedTab) {
            case CONFIG_TAB_STATES.LABELS_TAB:
                if (this.props.dataLoadedForGetProjectLabels) {
                    this.metricPageLatencyTimer.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.MANAGEPROJECTPAGE_LABELS)
                    this.resetStatusForAllTabs()
                }
                break;
            case CONFIG_TAB_STATES.PERMISSIONS_TAB:
                if (this.props.dataLoadedForGetProjectUsers) {
                    this.metricPageLatencyTimer.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.MANAGEPROJECTPAGE_PERMISSIONS)
                    this.resetStatusForAllTabs()
                }
                break;
            case CONFIG_TAB_STATES.CLIENTS_TAB:
                if (this.props.dataLoadedForGetClients) {
                    this.metricPageLatencyTimer.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.MANAGEPROJECTPAGE_CLIENTS)
                    this.resetStatusForAllTabs()
                }
                break;
            case CONFIG_TAB_STATES.SHARE_TAB:
                if (this.props.dataLoadedForGetPreTrainedModelsBundles) {
                    this.metricPageLatencyTimer.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.MANAGEPROJECTPAGE_SHARE)
                    this.resetStatusForAllTabs()
                }
                break;
            default:
                console.error("Unknown tab on the page: ", this.state.selectedTab)
        }

    }

    getNextLabelButton = () => {
        return (
            <Button size="small" className="NavigationButton"
                    onClick={() => {this.setSelectedTab('labels')}}>
                <div className="NavigationButtonText">Next: Labels</div>
            </Button>
        )
    }

    getNextUploadDocumentButton = () => {
        return (
            <Button size="small" className="NavigationButton">
                <Link to={{pathname: '/verify/' + this.state.projectId, state: {showFileUpload: true}}}
                      className='NavigationButtonText'>
                    Next: Upload Documents
                </Link>
            </Button>
        )
    }

    getNavigationButton = () => {
        switch (this.state.selectedTab) {
            case CONFIG_TAB_STATES.LABELS_TAB:
                return this.getNextUploadDocumentButton()
            case CONFIG_TAB_STATES.PERMISSIONS_TAB:
                return this.getNextLabelButton()
        }
    }

    setSelectedTabState(tabState) {
        this.setState({selectedTab: tabState})
    }

    setSelectedTab (tabValue) {
        this.setSelectedTabState(tabValue)
        this.addSelectedTabValueToHash(tabValue)
    }

    addSelectedTabValueToHash(selectedTab) {
        this.props.history.push("#" + selectedTab, this.props.location.state)
    }


    getSelectedTabValueFromUrlHash = () => {
        let urlHashes = this.props.location.hash
        let hashValues = urlHashes.split("#")
        hashValues = hashValues.filter(val => this.isValidTabState(val))
        if (hashValues && hashValues.length > 0) {
            return hashValues[0]
        } else {
            //No Selected Tab Value in the URL Hash. Fallback to permissions tab by default
            return CONFIG_TAB_STATES.PERMISSIONS_TAB;
        }
    }

    isValidTabState(tabState) {
        return tabState && (tabState === CONFIG_TAB_STATES.PERMISSIONS_TAB
            || tabState === CONFIG_TAB_STATES.LABELS_TAB
            || tabState === CONFIG_TAB_STATES.SHARE_TAB
            || tabState === CONFIG_TAB_STATES.CLIENTS_TAB)
    }

    render() {
        if(this.props.projectName === ""){
            return (<div> Invalid Project or Access Denied. </div>)
        }
        let configurationTabContentMapping = {
            [CONFIG_TAB_STATES.PERMISSIONS_TAB]: (<ProjectUsers projectId={this.state.projectId}/>),
            [CONFIG_TAB_STATES.LABELS_TAB]: (<ProjectLabels projectId={this.state.projectId}/>),
            [CONFIG_TAB_STATES.SHARE_TAB]: (<ShareHome projectId={this.state.projectId}/>),
            [CONFIG_TAB_STATES.CLIENTS_TAB]: (<ProjectExternalClients projectId={this.state.projectId}/>)
        };
        const showClientsTab = getProjectState(this.props.projectState) == PROJECT_STATES_ORDER.LAUNCHED && this.props.clients.length !== 0;
        const showShareTab = getProjectState(this.props.projectState) == PROJECT_STATES_ORDER.LAUNCHED

        return (
            <Box>
                <Row alignmentHorizontal="left"
                     alignmentVertical="top"
                     spacing="none"
                     widths={["grid-1", "fill", "fit"]}>
                    <ProjectHomeLink projectId={this.state.projectId} />
                    <Column  heights={"fill"} spacing="none" alignmentHorizontal="center">
                        <Text type="b300" alignment='center'> {this.props.projectDescription} </Text>
                    </Column>
                    <Column alignmentHorizontal="right">
                        {
                            this.props.location.state && this.props.location.state.fromCreateProjectPage ?
                                this.getNavigationButton()
                                : <div></div>
                        }
                    </Column>
                </Row>
                <Row spacing="none" spacingInset="medium" widths="fill">
                    <Box spacing="small">
                        <TabGroup
                            type="classic"
                            fill = "line"
                            value={this.state.selectedTab}
                            onChange={(tab) => this.setSelectedTab(tab)}>
                                <Tab value={CONFIG_TAB_STATES.PERMISSIONS_TAB}>Permissions</Tab>
                                <Tab value={CONFIG_TAB_STATES.LABELS_TAB}>Labels</Tab>
                                {showShareTab && <Tab value={CONFIG_TAB_STATES.SHARE_TAB}>Share Labels</Tab>}
                                {showClientsTab && <Tab value={CONFIG_TAB_STATES.CLIENTS_TAB}>Applications</Tab>}
                        </TabGroup>
                        {configurationTabContentMapping[this.state.selectedTab]}
                    </Box>
                </Row>
            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        projectName: state.project.name,
        projectDescription: state.project.description,
        projectState: state.project.state,
        user: state.user,
        clients: state.client.externalClients,
        dataLoadedForGetProjectLabels: state.apiDataLoaded.dataLoadedForGetProjectLabels,
        dataLoadedForGetProjectUsers: state.apiDataLoaded.dataLoadedForGetProjectUsers,
        dataLoadedForGetClients: state.apiDataLoaded.dataLoadedForGetClients,
        dataLoadedForGetPreTrainedModelsBundles: state.apiDataLoaded.dataLoadedForGetPreTrainedModelsBundles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjectDetails: (projectId) => dispatch(getProjectDetails(projectId)),
        getExternalClients: () => dispatch(getExternalClients()),
        clearProjectData: () => dispatch({type: actionTypes.CLEAR_PROJECT_DATA}),
        setDataLoadedForGetProjectLabels: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PROJECT_LABELS, status: status}),
        setDataLoadedForGetProjectUsers: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PROJECT_USERS, status: status}),
        setDataLoadedForGetClients: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_CLIENTS, status: status}),
        setDataLoadedForGetPreTrainedModelsBundles: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PRETRAINED_MODELS_BUNDLES, status: status})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProject);