import * as actionTypes from '../actions/sharedBundlesActions'

const initialState = {
    bundles: [],
}

const addActor = (state, bundleId, actor) => {
    return state.bundles.map((bundle) => {
        if (bundle.bundleId == bundleId) {

            return { ...bundle, users: bundle.users.concat([actor]) }
        }
        return bundle
    })
}

const addExistingUsers = (state, bundleId, users) => {
    return state.bundles.map((bundle) => {
        if (bundle.bundleId == bundleId) {
            return { ...bundle, users: users}
        }
        return bundle
    })
}

const sharedBundles = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SHARED_BUNDLES:
            return {
                ...state,
                bundles: action.sharedBundles
            }
        case actionTypes.ADD_ACTOR_TO_BUNDLE:
            return {
                ...state,
                bundles: addActor(state, action.bundleId, action.actor)
            }
        case actionTypes.ADD_BUNDLE_USERS:
            return {
                ...state,
                bundles: addExistingUsers(state, action.bundleId, action.users)
            }
        case actionTypes.CLEAR_SHARED_BUNDLE_DATA:
            return initialState
        default:
            return state;
    }
};

export default sharedBundles;