import * as actionTypes from '../actions/actions'

const initialState = {
    name: '',
    projectId: '',
    projectLabels: [],
    listOfFiles: [],
    users: [],
    description: '',
    state: '',
    owner: '',
    currentIterationNumber: '',
    successfulTrainingIterations: [],
    model_token: '',
    allowRegex: false,
    allowRelationships: false
}

const project = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_PROJECT_NAME:
            return {
                ...state,
                    projectName: action.projectName,
                    projectId: action.projectId,
                }
        case actionTypes.ADD_PROJECT_DETAILS:
            return {
                ...state,
                ...action.project
                }
        case actionTypes.ADD_PROJECT_DOCUMENTS:
            return {
                ...state,
                    listOfFiles: action.listOfFiles,
                }
        case actionTypes.ADD_PROJECT_LABELS:
            return {
                ...state,
                    projectLabels: action.projectLabels,
                }
        case actionTypes.ADD_PROJECT_USERS:
            return {
                ...state,
                    users: action.users,
                }
        case actionTypes.ADD_UPLOADED_FILE:
            return {
                ...state,
                    listOfFiles: state.listOfFiles.concat(action.doc),
                }
        case actionTypes.UPDATE_FILE_STATUS:
            let listOfFiles = Object.assign([], state.listOfFiles);
            listOfFiles.forEach((doc) => {
                if(doc.documentId === action.doc.documentId) {
                    doc.state = action.doc.state
                }
            })
            return {
                ...state,
                    listOfFiles: listOfFiles,
                }
        case actionTypes.ADD_NEW_LABEL:
            return {
                ...state,
                projectLabels: state.projectLabels.concat(action.label)
            }
        case actionTypes.ADD_NEW_USER:
            return {
                ...state,
                users: state.users.concat(action.user)
            }
        case actionTypes.ADD_PROJECT_SUCCESSFUL_TRAINING_ITERATIONS:
            return {
                ...state,
                successfulTrainingIterations: action.iterations
            }
        case actionTypes.ADD_PROJECT_MODEL_TOKEN:
            return {
                ...state,
                model_token: action.projectModelToken
            }
        case actionTypes.CLEAR_PROJECT_DATA:
            return initialState
        default:
            return state;
    }
};

export default project;
