import React, { useState, useCallback } from 'react';
import Row from "@meridian/components/row"
import Input from "@meridian/components/input"
import Button from "@meridian/components/button"
import { ADD_USER_TOOLTIP } from "../../../constants/Strings"
import Icon from "@meridian/components/icon"
import plusTokens from "@meridian/tokens/base/icon/plus"
import Tooltip from "@meridian/components/tooltip"

const AliasInputForm = ({ onAddHandler, bundle }) => {
  const [value, setValue] = useState("")
  const onClick = useCallback(() => {
    onAddHandler(value, bundle)
    setValue("")
  }, [value])

  return (
    <Row spacing="none" widths="fit">
      <Tooltip position="bottom" title={ADD_USER_TOOLTIP}>
        <Input
          value={value}
          onChange={setValue}
          placeholder="Add alias"
          size="medium"
          type="text"
          focus={true}
        />
      </Tooltip>
      <Button onClick={onClick}> <Icon tokens={plusTokens} size="large" /> </Button>
    </Row>
  )
}

export default AliasInputForm