import React from 'react';
import ReactDOM from 'react-dom';
import store from "./store/store";
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import "@babel/polyfill";
import './index.css';
import Home from './components/Project/Home';
import Project from './components/Project/Project';
import CreateProject from './components/Project/CreateProject';
import ManageProject from './components/Project/ManageProject';
import Notification from './components/Notification/Notification';
import Header from './components/Header/Header';
import * as serviceWorker from './serviceWorker';
import isAuthenticated from './utils/Auth';
import LogRocket from './utils/LogRocketUtils';


isAuthenticated().then(auth => {
    // preferred_username is user alias in id token
    LogRocket.identifyUser({uid:  auth.signInUserSession.idToken.payload.preferred_username})
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <div className='Home'>
                    <Route path = '/' render = {(props) => <Header {...props} auth={auth}/>} />
                    <Notification />
                    <Route exact path="/" exact component={Home} />
                    <Route exact path ="/create" component={CreateProject} />
                    <Route exact path ="/manage/" component={ManageProject} />
                    <Route path ="/manage/:project_id" component={ManageProject} />
                    <Route path = '/verify/:projectId' render = {(props) => <Project {...props} label = 'verify'/>} />
                </div>
            </BrowserRouter>
        </Provider>
    , document.getElementById('root'));
})
.catch(function(e) {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <div className='Home'>
                    <header>
                        <nav>
                            <Link to='/'><img src = {require('./assets/images/logo.png')}  alt="Amazon DataLens"/></Link>
                        </nav>
                    </header>

                    <Notification />
                </div>
            </BrowserRouter>
        </Provider>
    , document.getElementById('root'));
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();