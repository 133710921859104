import React from 'react'
import { Route, Link } from 'react-router-dom'
import Icon from "@meridian/components/icon"
import iconTokens from "@meridian/tokens/base/icon/arrow-left-small"

export default class ProjectHomeLink extends React.Component{

    render(){
        return (
            <div>
                <Link to={'/verify/' + this.props.projectId} className="ProjectHomeLink">
                    <Icon tokens={iconTokens} />Project Home
                </Link>
            </div>
        )
    }
}