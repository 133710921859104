import React, { useEffect } from "react"
import Select, { SelectOption } from "@meridian/components/select"
import { getRelatedDocumentsHandler } from '../../requests/FileRequests';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";

export default function RelationshipsDocument(props) {
    const dispatch = useDispatch()
    const projectDocuments = useSelector((state) => state.project.listOfFiles)
    const relatedDocumentsList = useSelector((state) => state.currDocument.relatedDocuments)
    const reactRouterHistory = useHistory();
    useEffect(() => { dispatch(getRelatedDocumentsHandler(props.projectId, props.documentId)) }, [dispatch, props.documentId])

    const changeDocumentOnChangeHandler = (documentId) => {
        reactRouterHistory.push('/verify/' + props.projectId + '/' + documentId)
    }

    if (relatedDocumentsList.length === 0 || projectDocuments.length === 0) {
        return null
    }

    const relatedDocumentIds = relatedDocumentsList.map(document => document.documentId)
    var documentNameToId = projectDocuments.reduce(function(filtered, document) {
        if (relatedDocumentIds.includes(document.documentId)) {
            filtered[document.documentId] = document.documentName 
        }
        return filtered;
    }, {});
    const optionsForDocuments = relatedDocumentIds.map((documentId, index) => <SelectOption value={documentId} label={(index+1) + '. ' + documentNameToId[documentId]} /> )
    
    return (
            <Select
                value={props.documentId ? props.documentId : ""}
                placeholder="Pick Option..."
                prefix="Related Documents"
                size="small"
                onChange={(value) => {changeDocumentOnChangeHandler(value)}}
            >
                {optionsForDocuments}
            </Select>
    )
}