export const MARK_ANNOTATION_AS_HIGHLIGHTED = 'MARK_ANNOTATION_AS_HIGHLIGHTED';
export const ADD_TO_METDATA_EXTRACTED = 'ADD_TO_METDATA_EXTRACTED';
export const DELETE_METDATA_EXTRACTED = 'DELETE_METDATA_EXTRACTED';
export const REFRESH_ANNOTATIONS_FOR_DOCUMENT = 'REFRESH_ANNOTATIONS_FOR_DOCUMENT';
export const REFRESH_PREDICTIONS_FOR_DOCUMENT = 'REFRESH_PREDICTIONS_FOR_DOCUMENT';
export const REFRESH_DISPLAYED_METADATA_FOR_DOCUENT = 'REFRESH_DISPLAYED_METADATA_FOR_DOCUENT';
export const REFRESH_DATA_SOURCE = 'REFRESH_DATA_SOURCE';
export const ADD_PROJECT_NAME = 'ADD_PROJECT_NAME';
export const ADD_PROJECT_DOCUMENTS = 'ADD_PROJECT_DOCUMENTS';
export const ADD_PROJECT_LABELS = 'ADD_PROJECT_LABELS';
export const ADD_PROJECT_USERS = 'ADD_PROJECT_USERS';
export const GET_FILE_CONTENTS = 'GET_FILE_CONTENTS';
export const ADD_UPLOADED_FILE = 'ADD_UPLOADED_FILE';
export const UPDATE_FILE_STATUS = 'UPDATE_FILE_STATUS';
export const ADD_DOCUMENT_META = 'ADD_DOCUMENT_META';
export const ADD_NEW_LABEL = 'ADD_NEW_LABEL';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const ADD_PROJECT_DETAILS = 'ADD_PROJECT_DETAILS';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';
export const CLEAR_DOCUMENT_DATA = 'CLEAR_DOCUMENT_DATA';
export const REMOVE_ANNOTATION_ACTION_FOR_DOCUMENT = 'REMOVE_ANNOTATION_ACTION_FOR_DOCUMENT';
export const SET_DATA_LOADED_FOR_GET_PROJECTS_FOR_USER = 'SET_DATA_LOADED_FOR_GET_PROJECTS_FOR_USER';
export const SET_DATA_LOADED_FOR_DOCUMENT_TEXT = 'SET_DATA_LOADED_FOR_DOCUMENT_TEXT';
export const SET_DATA_LOADED_FOR_METADATA = 'SET_DATA_LOADED_FOR_METADATA';
export const SET_DATA_LOADED_FOR_ANNOTATION_SUMMARY = 'SET_DATA_LOADED_FOR_ANNOTATION_SUMMARY';
export const SET_DATA_LOADED_FOR_TEST_METRICS = 'SET_DATA_LOADED_FOR_TEST_METRICS';
export const SET_DATA_LOADED_FOR_GET_PROJECT_LABELS = 'SET_DATA_LOADED_FOR_GET_PROJECT_LABELS';
export const SET_DATA_LOADED_FOR_GET_PROJECT_USERS = 'SET_DATA_LOADED_FOR_GET_PROJECT_USERS';
export const SET_DATA_LOADED_FOR_GET_CLIENTS = 'SET_DATA_LOADED_FOR_GET_CLIENTS';
export const SET_DATA_LOADED_FOR_GET_PRETRAINED_MODELS_BUNDLES = 'SET_DATA_LOADED_FOR_GET_PRETRAINED_MODELS_BUNDLES';
export const FAIL_UPLOADED_FILE = 'FAIL_UPLOADED_FILE';
export const UPLOADED_FILE = 'UPLOADED_FILE';
export const CLEAR_UPLOADED_FILES_DATA = 'CLEAR_UPLOADED_FILES_DATA';
export const ADD_PROJECT_SUCCESSFUL_TRAINING_ITERATIONS = 'ADD_PROJECT_SUCCESSFUL_TRAINING_ITERATIONS';
export const ADD_CLIENTS = 'ADD_CLIENTS';
export const ADD_PROJECT_CLIENTS = 'ADD_PROJECT_CLIENTS';
export const ADD_PROJECT_MODEL_TOKEN = 'ADD_PROJECT_MODEL_TOKEN'
export const ADD_DOCUMENT_RELATIONSHIPS = 'ADD_DOCUMENT_RELATIONSHIPS'


export const addProjectDetails = (project) => {
    return {
        type: ADD_PROJECT_DETAILS,
        project: project
    };
};

export const addProjectName = (projectId) => {
    return {
        type: ADD_PROJECT_NAME,
        projectId: projectId,
        projectName: projectId,
    };
};

export const addProjectDocuments = (listOfFiles) => {
    return {
        type: ADD_PROJECT_DOCUMENTS,
        listOfFiles: listOfFiles,
    };
};

export const addProjectLabels = (projectLabels) => {
    return {
        type: ADD_PROJECT_LABELS,
        projectLabels: projectLabels,
    };
};

export const addProjectUsers = (users) => {
    return {
        type: ADD_PROJECT_USERS,
        users: users,
    };
};

export const addClients = (clients) => {
    return {
        type: ADD_CLIENTS,
        clients: clients,
    };
};

export const addProjectClients = (clients) => {
    return {
        type: ADD_PROJECT_CLIENTS,
        projectClients: clients,
    };
};

export const getFileContents = (documentName, documentContent) => {
    return {
        type: GET_FILE_CONTENTS,
        documentName: documentName,
        documentContent: documentContent,
    };
};

export const refreshAnnotationsForDocument = (annotations, insertAnnotations) => {
    return {
        type: REFRESH_ANNOTATIONS_FOR_DOCUMENT,
        annotations: annotations,
        insertAnnotations: insertAnnotations,
    };
};

export const refreshPredictionsForDocument = (predictions) => {
    return {
        type: REFRESH_PREDICTIONS_FOR_DOCUMENT,
        predictions: predictions,
    };
};

export const refreshDisplayedMetadata = (displayedMetadata) => {
    return {
        type: REFRESH_DISPLAYED_METADATA_FOR_DOCUENT,
        displayedMetadata: displayedMetadata,
    };
};

export const refreshDataSource = (dataSource) => {
    return {
        type: REFRESH_DATA_SOURCE,
        dataSource: dataSource,
    };
};


export const addDocumentMeta = (meta) => {
    return {
        type: ADD_DOCUMENT_META,
        meta: meta,
    };
};

export const addDocumentRelationships = (relatedDocuments) => {
    return {
        type: ADD_DOCUMENT_RELATIONSHIPS,
        relatedDocuments: relatedDocuments,
    };
};

export const addNewLabel = (label) => {
    return {
        type: ADD_NEW_LABEL,
        label: label,
    };
};

export const addNewUser = (user) => {
    return {
        type: ADD_NEW_USER,
        user: user,
    };
};

export const loginUser = (user) => {
    return {
        type: LOGIN_USER,
        user: user,
    };
};

export const updateUserToken = (accessToken, tokenExpiry) => {
    return {
        type: UPDATE_USER_TOKEN,
        accessToken: accessToken,
        tokenExpiry: tokenExpiry,
    };
};

export const showNotification = (message, messageType) => {
    return {
        type: SHOW_NOTIFICATION,
        message: message,
        messageType: messageType,
    };
};

export const addProjectSuccessfulTrainingIterations = (iterations) => {
    return {
        type: ADD_PROJECT_SUCCESSFUL_TRAINING_ITERATIONS,
        iterations: iterations
    };
}