import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Row from "@meridian/components/row"
import Box from "@meridian/components/box"
import Heading from "@meridian/components/heading"
import Text from "@meridian/components/text"
import Table, { TableRow, TableCell, TableActionBar } from "@meridian/components/table"
import Tooltip from "@meridian/components/tooltip"
import helpTokens from '@meridian/tokens/base/icon/help'
import Icon from "@meridian/components/icon"
import Column from "@meridian/components/column"
import Loader from "@meridian/components/loader"
import Expander from "@meridian/components/expander"
import ShareModelForm from './ShareModelForm'
import {
  getPreTrainedModelsBundlesForProject,
  addActorToPretrainedModelsBundle,
  getPretrainedModelsBundleUsers
} from '../../../requests/PreTrainedLabelsRequests'
import Tag from "@meridian/components/tag"
import AliasInputForm from './AliasInput'
import { BUNDLE_ROLES } from '../../../constants/BundleRoles'
import '../../../assets/css/ShareTab.css'
import { SHARE_MODEL_PERFORMANCE_NOT_AVAILABLE, FAILED_TO_CREATE_SHARED_MODEL_MESSAGE,
         EXISTING_SHARED_MODELS_HEADER, NO_SHARED_MODELS_FOR_PROJECT, } from '../../../constants/Strings'
import * as actionTypes from "../../../store/actions/actions";

const BUNDLE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  ERROR: 'ERROR'
}

const BUNDLE_STATUS_TAG_DATA = {
  AVAILABLE: {type: 'success', text: 'Ready'},
  UNAVAILABLE: {type: 'warning', text: 'Creating..'},
  ERROR: {type: 'error', text: 'Failed'}
}

export class ShareHome extends React.Component {
  state = {
    projectId: this.props.projectId,
    loadedSharedBundles: false
  }

  componentDidMount = () => {
    if (this.props.sharedBundles.length === 0) {
      this.props.getPreTrainedModelsBundlesForProject(this.state.projectId, this.setLoadedSharedBundles)
    } else {
      this.setLoadedSharedBundles()
      this.props.setDataLoadedForGetPreTrainedModelsBundles(true)
    }
  }

  setLoadedSharedBundles = () => {
    this.setState({ 'loadedSharedBundles': true })
  }

  getExistingSharedBundles = () => {
    if (this.props.sharedBundles.length === 0) {
      return (
        <Box>
          <Heading level={6} alignment='left'> {EXISTING_SHARED_MODELS_HEADER} </Heading>
          <Text> {NO_SHARED_MODELS_FOR_PROJECT}</Text>
        </Box>
      )
    }
    return (
      <Box>
        <Heading level={6} alignment='left'> {EXISTING_SHARED_MODELS_HEADER} </Heading>
        {this.props.sharedBundles.map((bundle) => this.getExpanderForSharedBundle(bundle))}
      </Box>
    )
  }

  getExpanderForSharedBundle = (bundle) => {
    return (
      <CustomExpander title={this.getExpanderHeaderTitle(bundle)}
        type="section"
        openHandler={this.props.getPretrainedModelsBundleUsers }
        bundleId={bundle.bundleId}
        openDisabled={bundle.status === BUNDLE_STATUS.ERROR}>
          <Box>{this.getExpanderBodyForBundle(bundle)}</Box>
      </CustomExpander>
    )
  }

  getBundleStatusTag = (bundle) => {
    if (bundle.status === BUNDLE_STATUS.ERROR){
      return (
        <Tooltip position="bottom" title={FAILED_TO_CREATE_SHARED_MODEL_MESSAGE}>
          <Tag type={BUNDLE_STATUS_TAG_DATA[bundle.status].type}>
            {BUNDLE_STATUS_TAG_DATA[bundle.status].text}
          </Tag>
        </Tooltip>
      )
    }
    return (
      <Tag type={BUNDLE_STATUS_TAG_DATA[bundle.status].type}>
        {BUNDLE_STATUS_TAG_DATA[bundle.status].text}
      </Tag>
    )
  }

  getExpanderHeaderTitle = (bundle) => {
    return (
      <Row spacing="small">
        <Text className="ShareModelExpanderTitle" color={bundle.status === BUNDLE_STATUS.ERROR ? "secondary" : "primary"}>
          {bundle.labelModels[0].name}
        </Text>
        {this.getBundleStatusTag(bundle)}
      </Row>
    )
  }

  getLabelPerformanceNotAvailableTooltip = () => {
    return (
      <Tooltip position="bottom" title={SHARE_MODEL_PERFORMANCE_NOT_AVAILABLE}>
        <span> -- </span>
      </Tooltip>
    )
  }
  addUserToBundle = (user, bundle) => {
    this.props.addActorToPretrainedModelsBundle(this.state.projectId, bundle.bundleId, user, BUNDLE_ROLES.IMPORTER)
  }

  getExpanderBodyForBundle = (bundle) => {
    if (bundle.users === undefined) {
      return (
        <Row spacing="xlarge" wrap="down" alignmentVertical="top">
        <Column maxWidth="95%">
          <Loader size="medium" />
        </Column>
        </Row>
      )
    }
    return (
      <Row spacing="xlarge" wrap="down" alignmentVertical="top">
        <Column maxWidth="95%">
          <Table
            spacing="small"
            headerRows={1}
            showStripes={true}
            showDividers={false}
            layout="fixed"
          >
            <TableRow>
              <TableCell> Name </TableCell>
              <TableCell> Label performance </TableCell>
            </TableRow>
            {bundle.labelModels.map((model) => (
              <TableRow key={model.name}>
                <TableCell> {model.name} </TableCell>
                <TableCell> {model.performance ? model.performance : this.getLabelPerformanceNotAvailableTooltip()} </TableCell>
              </TableRow>
            ))}
          </Table>
        </Column>
        <Column>
          <Text> Shared to </Text>
          <Table
            spacing="small"
            headerRows={1}
            showStripes={true}
            showDividers={false}
          >
            <TableActionBar alignmentHorizontal='right'>
              <AliasInputForm onAddHandler={this.addUserToBundle} bundle={bundle} />
            </TableActionBar>
            <TableRow>
              <TableCell width="70%"> Alias </TableCell>
            </TableRow>
            {bundle.users.map((user) => (
              <TableRow key={user.alias}>
                <TableCell> {user.alias} </TableCell>
              </TableRow>
            ))}
          </Table>
        </Column>
      </Row>
    )
  }

  render() {
    if (!this.state.loadedSharedBundles) return (
      <Column
        type="outline"
        spacingInset="small"
        spacing="large"
        minWidth="40%"
        heights={"50%"}
        minHeight="50vh"
      >
        <Loader />
      </Column>
    )
    return (
      <Row
        type="outline"
        spacingInset="small"
        spacing="large"
        width="100%"
        alignmentVertical="top"
        widths={["grid-6", "grid-6"]}
        minHeight="50vh"
      >

        <ShareModelForm />
        {this.getExistingSharedBundles()}
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    sharedBundles: state.sharedBundles.bundles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPreTrainedModelsBundlesForProject: (projectId, onSuccessHandler) => dispatch(getPreTrainedModelsBundlesForProject(projectId, onSuccessHandler)),
    addActorToPretrainedModelsBundle: (projectId, bundleId, alias, role) => dispatch(addActorToPretrainedModelsBundle(projectId, bundleId, alias, role)),
    getPretrainedModelsBundleUsers: (bundleId) => dispatch(getPretrainedModelsBundleUsers(bundleId)),
    setDataLoadedForGetPreTrainedModelsBundles: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_GET_PRETRAINED_MODELS_BUNDLES, status: status})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareHome);

const CustomExpander = props => {
  const [open, setOpen] = useState()

  const callBack = open => {
    if (props.openDisabled) return;

    setOpen(open)
    if (open) {
      props.openHandler(props.bundleId)
    }

  }
  return <Expander open={open} onChange={callBack} {...props} />
}
