export const HOSTNAME = {
    LOCAL: "",
    BETA: "beta.datalens.a2z.com",
    GAMMA: "gamma.datalens.a2z.com",
    PROD: "datalens.a2z.com"
}

export const API_URL_LOCAL = ""
export const API_URL_BETA  = "https://zoz3jezshj.execute-api.us-west-2.amazonaws.com/beta"
export const API_URL_GAMMA = "https://qru2eqiwa3.execute-api.us-west-2.amazonaws.com/gamma"
export const API_URL_PROD  = "https://4hheviih7i.execute-api.us-west-2.amazonaws.com/prod"

export const API_KEY_LOCAL = ""
export const API_KEY_BETA  = "GH8tR951AQ9YNY9eEyjUs6jvgs6yHOCB8LNxV3fQ"
export const API_KEY_GAMMA = "zc7LvMAKhk1tc2K6Q14JF6rl17BpEP3Y2dD3Hu8D"
export const API_KEY_PROD  = "Q3ypuUJnIi5CzOs7gfJvU7A45lNlz7An7YTy8DpW"

export const IAM_ROLE_ARN_LOCAL = ""
export const IAM_ROLE_ARN_BETA  = "arn:aws:iam::720358671522:role/beta-DataLensClientRole"
export const IAM_ROLE_ARN_GAMMA = "arn:aws:iam::538217082803:role/gamma-DataLensClientRole"
export const IAM_ROLE_ARN_PROD  = "arn:aws:iam::002431567666:role/prod-DataLensClientRole"

export const PINPOINT_APPLICATION_ID_LOCAL = ""
export const PINPOINT_APPLICATION_ID_BETA = "81d7a667d5f646e382c3709a61fbebcc"
export const PINPOINT_APPLICATION_ID_GAMMA = "0387aef31f944e41967c8ef07910a2a3"
export const PINPOINT_APPLICATION_ID_PROD = "05737d0eac2b48069c6b5c7c3f949875"

export const IDENTITY_POOL_ID_LOCAL = ""
export const IDENTITY_POOL_ID_BETA  = "us-west-2:733c4e48-4fcd-4619-a78c-c87a71b573fa"
export const IDENTITY_POOL_ID_GAMMA = "us-west-2:b7df8873-40a7-4733-a07f-15389088ea72"
export const IDENTITY_POOL_ID_PROD  = "us-west-2:136b58bf-3e77-433d-829f-017625dc3407"

export const USER_POOL_ID_LOCAL = ""
export const USER_POOL_ID_BETA  = "us-west-2_Vst3MqVpv"
export const USER_POOL_ID_GAMMA = "us-west-2_QBBmiu1Vi"
export const USER_POOL_ID_PROD  = "us-west-2_yXPFTv3Hf"

export const COGNITO_CLIENT_ID_LOCAL = ""
export const COGNITO_CLIENT_ID_BETA  = "1gm5vec7jjc580t0fuei625kpi"
export const COGNITO_CLIENT_ID_GAMMA = "52jbmnd9arvi9d511uvdddu4oi"
export const COGNITO_CLIENT_ID_PROD  = "6lsmjrdhj8gmpsh0lcp5g0lpgh"

export const APP_WEB_DOMAIN_LOCAL = "datalens-beta.auth.us-west-2.amazoncognito.com"
export const APP_WEB_DOMAIN_BETA  = "datalens-beta.auth.us-west-2.amazoncognito.com"
export const APP_WEB_DOMAIN_GAMMA = "datalens-gamma.auth.us-west-2.amazoncognito.com"
export const APP_WEB_DOMAIN_PROD  = "datalens-prod.auth.us-west-2.amazoncognito.com"

