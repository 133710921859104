import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { highlightAnnotationHandler } from '../Document/HighlightAnnotation';
import '../../assets/css/DropdownMetadataFields.css';
import * as actionTypes from '../../store/actions/actions';
import { getTrainableLabels } from '../../utils/LabelsFilter'
import Box from "@meridian/components/box"
import Text from "@meridian/components/text"

const ANNOTATION_DELIMITER = '_'
const LABEL_EMPTY = ''

class DropdownMetadataFields extends Component {

    constructor(props){
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    state = {
        selectedTag: '',
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            var selectedText = {annotationId: LABEL_EMPTY + ANNOTATION_DELIMITER + '0' + ANNOTATION_DELIMITER + '0', text: ''};
            this.props.getSelectionTextHandler(selectedText);
        }
    }

    componentDidMount = () => {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.escFunction, false);
        var annotation = this.props.annotations.filter(annotation => annotation.annotationId === this.props.selectedTextId)[0]
        if (annotation !== undefined && !annotation.highlighted) {
            highlightAnnotationHandler(annotation, this.props.getSelectionTextHandler);
            this.props.markAnnotationAsHighlighted(annotation);
        }
    }

    onChangeHandler = (event) => {
        if(event !== null) {
            this.setState({selectedTag: event.value})
            this.props.selectedTag(event.value);
        } else {
            this.setState({selectedTag: ''})
            this.props.selectedTag(null);
        }
    }

    render() {
        const currentTag = this.props.projectLabels
                                .filter(option => (option.labelId === this.props.currentTag))
                                .map((label) => ({value: label.labelId, label: label.name}));
        let userCreatedLabels = getTrainableLabels(this.props.projectLabels)
        let options = userCreatedLabels.map((label) => ({value: label.labelId, label: label.name}))

        return (
            <Box id='dropdownMetadata' className ='MetadataFields'>
                <p> { this.props.text } </p>
                { this.props.isDisabled ? 
                    <Box type="fill" spacingInset="small">
                        {currentTag[0].label}
                    </Box>
                    : 
                    <Select
                        value = { currentTag[0] }
                        onChange = { this.props.selectedTag }
                        options = { options }
                        placeholder = "Pick a label.."
                        isClearable = {true}
                        menuPlacement= "auto"
                        menuShouldBlockScroll="true"
                    />
                }   
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectName: state.project.projectName,
        projectLabels: state.project.projectLabels,
        projectState: state.project.state,
        annotations: state.currDocument.displayedMetadata
    };
};

const mapDispatchToProps = dispatch => {
    return {
        markAnnotationAsHighlighted: (annotation) => dispatch({type: actionTypes.MARK_ANNOTATION_AS_HIGHLIGHTED, annotation: annotation}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMetadataFields);