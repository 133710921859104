import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal, { ModalFooter } from "@meridian/components/modal"
import Text from "@meridian/components/text"
import Row from "@meridian/components/row"
import Button from "@meridian/components/button"


export default class UserModal extends Component {
    
    state = {
      modalOpen: true,
    }
    
    componentDidMount = () => {
      this.setState({modalOpen: this.props.modalOpen})
    }

    render() {
        return (
            <Modal
              width={'fill'}
              type="dialog"
              title={ this.props.title }
              id="modal-annotations"
              closeLabel="Close"
              open={this.state.modalOpen}
              onClose={() => this.props.noFunctionHandler()}
              describedById={ this.props.divId }
            >
                { this.props.modalMessage }
                <ModalFooter>
                  <Text>
                  Do you still want to proceed?
                  </Text>
                  <Row widths={"fill"}
                    wrap="down"
                    alignment="center left"
                    spacingInset="medium">
                    <Button
                      type="primary"
                      onClick={() => {
                          this.props.yesFunctionHandler();
                          this.setState({modalOpen: false});
                        }
                      }>
                      Yes
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                          this.props.noFunctionHandler();
                          this.setState({modalOpen: false})
                        }
                      }>
                      No
                    </Button>
                  </Row>
                </ModalFooter>
            </Modal>
        );
    }
}