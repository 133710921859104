export const DOCUMENT_STATES = {
// Different states of document to indicate its progress
    // Has been registered in DB but not uploaded/parsed yet
    REGISTERED: 'Registered',
    // User has completed annotations for the document.
    COMPLETED: 'Completed',
    // Document has uploaded and awaiting Doc Parser.
    UPLOADED: 'UPLOADED',
    // Has been parsed and uploaded to the destination bucket.
    PARSED: 'PARSED',
    // Document failed to upload to S3
    FAIL_UPLOAD: 'FAILED_UPLOAD',
    // Failed to parse the document..
    FAIL_PARSED: 'FAILED_PARSED',
    // Back Fill. Has been uploaded but not completed.
    NOT_COMPLETED: 'Not Completed'
}

export const LABEL_TYPE_MAPPING = {
    'Entity Extraction' : 'Extract',
    'Classification' : 'Inference',
    'Regex' : 'Extract (Find Text)'
}


export const CLIENT_METRICS_METHOD_NAMES = {
  HOMEPAGE: "HomePage",
  DOCUMENTVERIFICATIONPAGE: "DocumentVerificationPage",
  DOCUMENTCOLLECTIONPAGE: "DocumentCollectionPage",
  PROJECTHOMEPREVERIFICATIONPAGE: "ProjectHomePreVerificationPage",
  PROJECTHOMEVERIFICATIONPAGE: "ProjectHomeVerificationPage",
  CREATEPROJECTPAGE: "CreateProjectPage",
  MANAGEPROJECTPAGE_PERMISSIONS: "ManageProjectPageProjectPermissions",
  MANAGEPROJECTPAGE_LABELS: "ManageProjectPageProjectLabels",
  MANAGEPROJECTPAGE_CLIENTS: "ManageProjectPageApplications",
  MANAGEPROJECTPAGE_SHARE: "ManageProjectPageShareLabels"
}

export const DATA_SOURCE = {
  USER_ANNOTATIONS: "User Annotations",
  MACHINE_PREDICTIONS: "Machine Predictions"
}
