
import isAuthenticated, { getAccessToken, isTokenExpired } from './Auth';
import { API_REGION } from '../config/api-gateway';
import { getAPIURL, getAPIKey } from './EnvironmentConfig'
import store from "../store/store"
import { updateUserToken } from '../store/actions/actions';
import AWS from 'aws-sdk'
import { isAdminAndToggleOn } from "./AdminAuth";

var apigClientFactory = require('aws-api-gateway-client').default
var apigClient = null

function setApigClient(){
    apigClient = apigClientFactory.newClient({
        invokeUrl: getAPIURL(),
        accessKey: AWS.config.credentials.accessKeyId,
        secretKey: AWS.config.credentials.secretAccessKey,
        sessionToken: AWS.config.credentials.sessionToken,
        region: API_REGION,
        apiKey: getAPIKey(),
        retries: 3,
        retryDelay: 'exponential',
        shouldResetTimeout: true,
        retryCondition: (err) => {
            return err.response.status >= 500 && err.response.config.method === 'get';
        }
    })
}

export default async function apiCall(pathTemplate='', params={}, method='GET', body='', headers={}, queryParams={}){
    if (!apigClient) {
        setApigClient()
    }

    if (isTokenExpired()){
        let auth = await isAuthenticated()
        let accessToken =  auth.signInUserSession.accessToken.jwtToken
        let tokenExpiry = auth.signInUserSession.accessToken.payload.exp
        store.dispatch(updateUserToken(accessToken, tokenExpiry))
        setApigClient()
    }

    headers["Cognito_authorization"] = getAccessToken()
    if (isAdminAndToggleOn(store.getState().user)) {
        headers["enable-admin"] = "true"
    }

    let additionalParams = {headers: headers}
    if (method === 'GET'){
        additionalParams['queryParams'] = queryParams
    }

    return apigClient.invokeApi(params, pathTemplate, method, additionalParams, body)
    .then(response => response.data)
    .catch(function(response){
       console.log(response)
       throw response.message
    })
}
