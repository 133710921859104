import React, {Component} from "react"
import { connect } from 'react-redux'
import * as actionTypes from "../../store/actions/actions"
import Dropzone from "react-dropzone"
import Box from "@meridian/components/box"
import Row from "@meridian/components/row"
import Text from "@meridian/components/text"
import {FILES_THAT_WERE_NOT_UPLOADED, FILES_ARE_UPLOADED, FILES_ACCEPTED_CRITERIA, FILES_UPLOAD_MESSAGE, FILES_FAILED_TO_UPLOAD,
        ALL_FILES_SUCCESSFULLY_UPLOADED, SOME_FILES_FAILED_UPLOADED} from "../../constants/Strings"
import Loader from "@meridian/components/loader"
import Icon from "@meridian/components/icon"
import checkLargeTokens from "@meridian/tokens/base/icon/check-large"
import closeSmallTokens from "@meridian/tokens/base/icon/close"


// 10MB
const MAX_FILE_SIZE_LIMIT = 10485760

export class FileSelector extends Component {
    state = {
      acceptedFiles: [],
      rejectedFiles: []
    }

    componentWillUnmount = () => {
        this.props.clearUploadedFilesData()
    }

    onDrop = (documents) => {
        if (documents.length == 0) {
            return;
        }
        this.setState({acceptedFiles: documents})
        this.props.uploadFileHandler(documents)
    }

    rejectedHandler = (documents) => {
        this.setState({rejectedFiles: documents})
    }

    getProgressPercentage = () => {
        if (this.state.acceptedFiles.length == 0) return 100

        return ~~(((this.props.uploadedFiles.length + this.props.failedFiles.length) / this.state.acceptedFiles.length) * 100)
    }

    getUploadBox = () => {
        return (
            <Dropzone onDrop={this.onDrop}
                    maxSize={MAX_FILE_SIZE_LIMIT}
                    maxFiles={5}
                    accept={["application/msword", "text/plain", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", ".doc"]}
                    multiple={true}
                    onDropRejected= {this.rejectedHandler}
            >
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Row alignmentHorizontal="center" alignmentVertical="center" type="fill" height="100px">
                  <div>
                      <Text alignment="center">{FILES_UPLOAD_MESSAGE}</Text>
                    <em>
                      <Text alignment="center">({FILES_ACCEPTED_CRITERIA})</Text>
                    </em>
                  </div>
                </Row>
              </div>
            )}
            </Dropzone>
        )
    }

    getUploadSummary = () => {
        const failedFiles = this.props.failedFiles.map(filename => (
          <li key={filename}>
            {filename}
          </li>
        ));

        const rejectedFilesItems = this.state.rejectedFiles.map(file => (
          <li key={file.name}>
            {file.name} - {(file.size / Math.pow(1024, 2)).toFixed(2)} MB
          </li>
        ));

        return (
            <aside>
                {this.state.rejectedFiles.length > 0 || this.props.failedFiles.length > 0 ?
                  <p style={{color: 'red'}}> {SOME_FILES_FAILED_UPLOADED} </p>
                  :
                  <p style={{color: 'green'}}> {ALL_FILES_SUCCESSFULLY_UPLOADED} </p>
                }
                {FILES_ARE_UPLOADED}
                {this.state.rejectedFiles.length > 0 ?
                  <div>
                    <h4>{FILES_THAT_WERE_NOT_UPLOADED} {FILES_ACCEPTED_CRITERIA}</h4>
                    <ul>
                      {rejectedFilesItems}
                    </ul>
                  </div>
                  : null
                }

                {this.props.failedFiles.length > 0 ?
                  <div>
                    <h4>{FILES_FAILED_TO_UPLOAD}</h4>
                    <ul>
                      {failedFiles}
                    </ul>
                  </div>
                  : null
            }
            </aside>
        )
    }

    getUploadProgress = () => {
        let upload_progress = this.state.acceptedFiles.map(file => (
            <Row widths="fit" spacing="small">
              {file.name}
              {this.props.uploadedFiles.includes(file.name)
                ?
                  <Icon tokens={checkLargeTokens} />
                :
                  this.props.failedFiles.includes(file.name)
                  ?
                    <Icon tokens={closeSmallTokens} />
                  :
                    <Loader size="small"/>
              }
            </Row>
        ));

    return (
      <aside>
        <Loader percentage={this.getProgressPercentage()} type="linear" size="large"/>
        {upload_progress}
      </aside>)
    }

    render() {
        return (
            <Box>
                {
                    this.state.acceptedFiles.length == 0 && this.state.rejectedFiles.length == 0
                    ?
                        this.getUploadBox()
                    :
                        this.getProgressPercentage() != 100
                        ?
                            this.getUploadProgress()
                        :
                            this.getUploadSummary()
                }
            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        uploadedFiles: state.fileUpload.uploadedFiles,
        failedFiles: state.fileUpload.failedFiles,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        clearUploadedFilesData: () => dispatch({type: actionTypes.CLEAR_UPLOADED_FILES_DATA}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileSelector);
