import * as actionTypes from '../actions/actions'

const initialState = {
    uploadedFiles: [],
    failedFiles: [],
}

const fileUpload = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOADED_FILE:
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.concat(action.documentName)
            }
        case actionTypes.FAIL_UPLOADED_FILE:
            return {
                ...state,
                failedFiles: state.failedFiles.concat(action.documentName)
            }
        case actionTypes.CLEAR_UPLOADED_FILES_DATA:
            return initialState
        default:
            return state;
    }
};

export default fileUpload;