import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import * as actionTypes from "../../store/actions/actions"
import { MetricPageLatencyTimer } from "../../utils/MetricPageLatencyTimer"
import { CLIENT_METRICS_METHOD_NAMES } from "../../utils/Constants"
import {getProjectState, PROJECT_STATES_ORDER} from '../../constants/Project'

class ProjectHomePageLatency extends Component {
    metricPageLatencyTimerForProjectHomePage = new MetricPageLatencyTimer()
    
    componentDidUpdate (prevProps) {
        if ((prevProps.documentName !== this.props.documentName) && (this.props.documentName == '')) {
          this.metricPageLatencyTimerForProjectHomePage = new MetricPageLatencyTimer()
          this.props.setDataLoadedForAnnotationSummary(false)
          this.props.setDataLoadedForTestMetrics(false)   
        }

        if (getProjectState(this.props.projectState) <= PROJECT_STATES_ORDER.TRAINING) {
          if (this.props.dataLoadedForAnnotationSummary) {
            this.metricPageLatencyTimerForProjectHomePage.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.PROJECTHOMEPREVERIFICATIONPAGE)  
            this.props.setDataLoadedForAnnotationSummary(false)            
          }
        } else {
          if ((this.props.dataLoadedForAnnotationSummary) && (this.props.dataLoadedForTestMetrics)){
            this.metricPageLatencyTimerForProjectHomePage.publishPageLatency(CLIENT_METRICS_METHOD_NAMES.PROJECTHOMEVERIFICATIONPAGE)              
            this.props.setDataLoadedForAnnotationSummary(false)
            this.props.setDataLoadedForTestMetrics(false)
          }
        }
    }

    render() {
        return null
    }

}

const mapStateToProps = state => {
    return {
        projectState: state.project.state,
        projectName: state.project.projectName,
        documentName: state.currDocument.documentName,
        dataLoadedForAnnotationSummary: state.apiDataLoaded.dataLoadedForAnnotationSummary,
        dataLoadedForTestMetrics: state.apiDataLoaded.dataLoadedForTestMetrics
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDataLoadedForAnnotationSummary: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_ANNOTATION_SUMMARY, status: status}),
        setDataLoadedForTestMetrics: (status) => dispatch({type: actionTypes.SET_DATA_LOADED_FOR_TEST_METRICS, status: status})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHomePageLatency);    