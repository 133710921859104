import React from 'react';
import '../../assets/css/CreateProject.css';
import { connect } from 'react-redux';
import Box from "@meridian/components/box"
import Row from "@meridian/components/row"
import Column from "@meridian/components/column"
import { getProjectLabelsHandler } from '../../requests/ProjectRequests';
import {getImportedLabels, getUserCreatedLabels} from '../../utils/LabelsFilter'
import ProjectUserLabels from "./ProjectUserLabels"
import ProjectImportedLabels from "./ProjectImportedLabels"
import Alert from "@meridian/components/alert";
import {IMPORT_LABELS_CONFIRMATION_MESSAGE} from "../../constants/Strings";

class ProjectLabels extends React.Component{

    state = {
        projectId: this.props.projectId,
    }

    componentDidMount = () => {
        this.props.getProjectLabelsHandler(this.state.projectId);
    }

    render() {
        let importedLabels = getImportedLabels(this.props.labels)
        let userCreatedLabels = getUserCreatedLabels(this.props.labels)
        return (
            <Column>
                <Row widths="fill">
                    <ProjectUserLabels projectId={this.state.projectId} userLabels = {userCreatedLabels}/>
                    <ProjectImportedLabels importedLabels = {importedLabels}/>
                </Row>
            </Column>
        )
    }
}

const mapStateToProps = state => {
    return {
        labels: state.project.projectLabels,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjectLabelsHandler: (projectId) => dispatch(getProjectLabelsHandler(projectId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLabels);